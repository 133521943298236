import {useState,useEffect} from 'react';
import {FaStar} from 'react-icons/fa'
import "./StarRating.css";
const StarRating = ({handleRatings,reviewSuccess,clearRatingErr}) => {
    const [rating,setRating]=useState(null);
    const [hover,setHover]=useState(null);
    useEffect(() => {
      
            handleRatings(rating);
            if(rating!=null){
                clearRatingErr();
            }
 
        
    }, [rating])
    useEffect(() => {
        if(reviewSuccess){
            setRating(null);
            setHover(null)
    
        }
        
    }, [reviewSuccess]);
   
    return (
        <div>
            {[...Array(5)].map((star,i)=>{
                const ratingValue=i+1;
                return(
                    <label >
                    <input 
                    type="radio" 
                    name="rating"
                    value={ratingValue}
                    onClick={()=>setRating(ratingValue)}
                    
                    />
                    <FaStar 
                    className='star'
                    color={ratingValue<=(hover||rating) ? "#ffc107" : "#e4e5e9"}
                    onMouseOver={()=>setHover(ratingValue)}
                    onMouseOut={()=>setHover(null)}
                    size={22}
                    />
                </label>
                )
            })}
        </div>
    );
}

export default StarRating;
