import React from 'react';
import Review from './Review';

const Popup = ({productId,userId}) => {
    return (
        <div>
            <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content rounded-20">
                    <div className="modal-header py-0 bg-yellow border-bottom-2 border-dark" style={{'borderRadius':'12px 12px 0px 0px'}}>
                        <h5 className="modal-title fw-bold" id="exampleModalLongTitle">Add Review</h5>
                        <button style={{'fontSize':'36px'}} type="button" id="closePopup" className="close bg-yellow border-0 bg-light" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-4">
                        <Review productId={productId} userId={userId}/>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;
