import "../Home/Banner.css";
import maps from "../assets/map-svg.svg";
import raft from "../assets/raft-svg.svg";
import bag from "../assets/luggage-svg.svg";
import mglass from "../assets/search.png";
import Typewriter from 'typewriter-effect';
import { useEffect,useState } from "react";
import { projectFirestore } from '../firebase/config';
import { useCollection } from "../hooks/useCollection"; 
import {Link} from 'react-router-dom';
import e from "cors";
import car from "../assets/car.svg";

const Banner = () => {
const {documents,error}=useCollection('location');
const [filteredSearch,setFilteredSearch]=useState([]);
const [search,setSearch]=useState('')
const [resultval,setResultVal]=useState('')
const [noResult,setNoResult]=useState(false)
const [selectedFilter,setSelectedFilter]=useState('location');
const [activityName,setActivityName]=useState([]);
const [stayName,setStayname]=useState(null);
new Typewriter('#typewriter', {
  autoStart: true,
});

const handleKeyPress = (event) => {
  if(event.key === 'Enter'){
    
     window.location.href = '/explore/'+event.target.value
  }
}
useEffect(() => {
    let ref=projectFirestore.collection('location');
        let resultLocation=[];
        const unsubscribeLocation=ref.onSnapshot((snapshot)=>{
            snapshot.docs.forEach((location)=>{
                resultLocation.push(location.id)     
            })
        
        let resultActs=[];
        let activityList=[];
        let stayName=[];
        //Getting all the stay names

        resultLocation.forEach((eachLocation)=>{
            let reff=projectFirestore.collection('location').doc(eachLocation).collection("stay");
            const unsubscribe=reff.onSnapshot((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    stayName.push(doc.id)

                })
                const unique = (value, index, self) => {
                    return self.indexOf(value) === index
                  }
                  const uniqueArr = stayName.filter(unique)
                  //console.log(uniqueArr,"uniq")
                  let newUniqueArr=[]
                  uniqueArr.forEach(each=>{
                   newUniqueArr.push({id:each})
                  })
                setStayname(newUniqueArr)
            })

        })
         //Getting all the activity names
         resultLocation.forEach((eachLocation)=>{
            let reff=projectFirestore.collection('location').doc(eachLocation).collection("activity");
            const unsubscribe=reff.onSnapshot((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    activityList.push(doc.id)     
                })

                const unique = (value, index, self) => {
                    return self.indexOf(value) === index
                  }
                  const uniqueArr = activityList.filter(unique)
                //   //console.log(uniqueArr,"uniq")
                let newUniqueArr=[]
               uniqueArr.forEach(each=>{
                newUniqueArr.push({id:each})
               })
                setActivityName(newUniqueArr)
            })

        })
    })
}, [selectedFilter]);


const handleSearch = (event)=>{
    let searchWord=event.target.value;
    setSearch(searchWord)
    let filteredRes=[];
    
    
if(selectedFilter==="location"){
        filteredRes=documents.filter(value=>{
            return value.id.toLowerCase().includes(searchWord.toLowerCase());
        })

    }
    else if(selectedFilter==="activity")
    {
        filteredRes=activityName.filter(value=>{
            return value.id.toLowerCase().includes(searchWord.toLowerCase());
        })

    }
    else if(selectedFilter==="stay")
    {
        filteredRes=stayName.filter(value=>{
            return value.id.toLowerCase().includes(searchWord.toLowerCase());
        })

    }
    else{
        filteredRes=documents.filter(value=>{
            return value.id.toLowerCase().includes(searchWord.toLowerCase());
        })
    }
    
    if(searchWord===""){
        setNoResult(false);
        setFilteredSearch([])
    }
    else if(filteredRes.length===0){
        setNoResult(true);
        setResultVal(event.target.value);
        
    }
    else{
        setNoResult(false);
        setFilteredSearch(filteredRes)

    }
   
    // //console.log(filteredSearch.length)

}

    return (
    <>
        <div className="bannerClass">
            <div className="searchDiv bannerSearchDiv">
                <div className="bannerTitle">
                    <h1>Adventrio</h1>
                </div>
                <div className="bannerSubtitle">
                    <h6 className="d-flex">Experience&nbsp;<Typewriter id="typewriter" options={{
                    strings: ['Nature', 'Comfort','Thrill'],
                    autoStart: true,
                    loop: true, }}/>
                    </h6>
                </div>

                <div className="text-center">
                    <div className="search__tabs bg-light">
                        <p className="text-dark border-0 " onClick={()=>{setSelectedFilter('activity')}}>Activity</p>
                        <p className="text-dark " onClick={()=>{setSelectedFilter('location')}}>Location</p>
                        <p className="text-dark " onClick={()=>{setSelectedFilter('stay')}}>Stay</p>
                    </div>
                </div>
              {/* search */}
                <div className="position-relative rounded  d-flex justify-content-center searchBar ">
                    <input type="search" className="form-control rounded" id="search" onChange={handleSearch}   placeholder={'Search by '+selectedFilter} aria-label="Search" aria-describedby="search-addon" />
                    <img className="position-absolute" src={mglass} alt="" />
                </div>
                {noResult && 
                    <div className="position-relative">
                        <div className="search__results">
                            <p className="pt-2 pb-2" style={{"background":"white"}}>No result for {resultval}  </p>
                        </div>
                    </div>
                } 
    

                {filteredSearch.length !== 0 && (
                    <div className="position-relative">
                        <div className="search__results" style={{"background":"white"}}>
                            {!noResult && filteredSearch.map(doc=>(
                                <div className="pb-1 pt-1 px-3 search_result_strip" key={doc.id} >
                                    {selectedFilter=="location" && <Link className="text-decoration-none text-capitalize text-dark"   to={'/explore/'+doc.id}>{doc.id}</Link>}
                                    {selectedFilter=="stay" && <Link className="text-decoration-none text-capitalize text-dark"   to={'/stay/'+doc.id}>{doc.id}</Link>}
                                    {selectedFilter==="activity" && <Link className="text-decoration-none text-capitalize text-dark"   to={'/activity/'+doc.id}>{doc.id}</Link>}
                                </div>
                                )
                            )}
                        </div>
                    </div>
                )}

          
                
                <div className="filterIcons">
                    <a  className="buttonBanner"  href="#travel">
                        <span className="icon">
                            <img style={{'height':'1.5em'}} src={car} alt="" />
                        </span>
                        <span className="text">
                            Travel
                        </span>
                    </a>
                    <a className="buttonBanner" href="#activities">
                        <span className="icon">
                            <img src={raft} alt="" />
                        </span>
                        <span className="text">
                            Activity
                        </span>
                    </a>
                    <a className="buttonBanner" href="#stay">
                        <span className="icon">
                            <img src={bag} alt="" />
                        </span>
                        <span className="text">
                            Stay
                        </span>
                    </a>
                </div>
                <div className="filterIcons d-none">
                    <a data-bs-toggle="tooltip" title="Stay" href="#stay">
                        <div className="serviceIcon ">
                            <img src={bag} alt="" />
                        </div>
                    </a>
                    <a data-bs-toggle="tooltip" title="Stay" href="#stay">
                        <div className="serviceIcon ">
                            <img src={bag} alt="" />
                        </div>
                    </a>
                    <a data-bs-toggle="tooltip" title="Activity" href="#active">
                        <div className="serviceIcon ">
                            <img src={raft} alt="" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        

    </>
     );
}
 

export default Banner;