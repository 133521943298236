import mas1 from "../assets/1.png";
import mas2 from "../assets/2.png";
import mas3 from "../assets/3.png";
import mas4 from "../assets/4.png";
import mas5 from "../assets/5.png";
import mas6 from "../assets/6.png";
import mas7 from "../assets/7.png";
import Slider from "react-slick";
import Tippy from '@tippyjs/react';
import userIcon from "../assets/userIcon.svg";
import activityIcon1 from "../assets/activityParachute.svg";
import {Link} from 'react-router-dom';
import cartScroll from "../assets/cartScroll.svg";
import activity1 from "../assets/activity1.png";
import "./stay.css";
import Footer from "../components/Footer";
const CorporateEvents = () => {
    return ( 


        <div>
            <div className="exploreClass d-flex justify-content-center align-items-start">
                <div className="searchDiv heroSectionSearch">
                    <div className="sectionTitle">
                        <h1 className="text-dark text-center fw-bold events__title">Corporate Events</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark " style={{'width':'65%'}} />
                </div>
            </div>

            <div className="container-fluid explore parentDiv pt-5">
                <div >
                    <div className="row pb-5 text-webkit-center mx-3">
                        <div className="col-xl-4 col-md-6 col-sm-6 mb-3">
                            <div className="card rounded-20 border-0 shadow" style={{'width': '90%','height':'95%'}}>
                                <div className="position-relative">
                                    <img src={activity1} className="card-img-top" alt="..."/>
                                    <div className="position-absolute timeBar text-start"><p className="mb-0"><small>Time: 2:00 pm to 7:30 pm</small></p></div>
                                </div>                                
                                <div className="card-body corporate__events">
                                    <div className="row">
                                        <div className="col-md-8 col-sm-12">
                                            <h5 className="card-title text-start text-dark fw-bold">Trekking, Everest</h5>
                                            <p className="mb-0 card-text text-start">₹4599 / person</p>
                                            <p className="text-start event__date">Date: <small>22-09-2022</small></p>
                                        </div>
                                        <div className="col-md-4 col-sm-12 event__pricings">
                                            <h6 className="text-end text-sm-start">Rs500 onwards</h6>
                                            <p className="mb-0 text-end"><small>11 slots left</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 col-sm-6 mb-3">
                            <div className="card rounded-20 border-0 shadow" style={{'width': '90%','height':'95%'}}>
                                <div className="position-relative">
                                    <img src={activity1} className="card-img-top" alt="..."/>
                                    <div className="position-absolute timeBar text-start"><p className="mb-0"><small>Time: 2:00 pm to 7:30 pm</small></p></div>
                                </div>
                                <div className="card-body corporate__events">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <h5 className="card-title text-start text-dark fw-bold">Trekking, Everest</h5>
                                            <p className="mb-0 card-text text-start">₹4599 / person</p>
                                            <p className="text-start event__date">Date: <small>22-09-2022</small></p>
                                        </div>
                                        <div className="col-sm-4 event__pricings">
                                            <h6 className="text-end">Rs500 onwards</h6>
                                            <p className="mb-0 text-end"><small>11 slots left</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-md-6 col-sm-6 mb-3">
                            <div className="card rounded-20 border-0 shadow" style={{'width': '90%','height':'95%'}}>
                                <div className="position-relative">
                                    <img src={activity1} className="card-img-top" alt="..."/>
                                    <div className="position-absolute timeBar text-start"><p className="mb-0"><small>Time: 2:00 pm to 7:30 pm</small></p></div>
                                </div>
                                <div className="card-body corporate__events">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <h5 className="card-title text-start text-dark fw-bold">Trekking, Everest</h5>
                                            <p className="mb-0 card-text text-start">₹4599 / person</p>
                                            <p className="text-start event__date">Date: <small>22-09-2022</small></p>
                                        </div>
                                        <div className="col-sm-4 event__pricings">
                                            <h6 className="text-end">Rs500 onwards</h6>
                                            <p className="mb-0 text-end"><small>11 slots left</small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
     );
}
 
export default CorporateEvents;