import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/Navbar"
import Login from './Login/Login';
import Signup from './signup/Signup';
import Home from "../src/Home/Home";
import {BrowserRouter,Route, Switch,useLocation} from 'react-router-dom';
import {useAuthContext} from "./hooks/useAuthContext";
import Fetch from './Fetch';
import TransactionForm from './TransactionForm';
import Explore from './pages/Explore';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import SearchPage from './pages/SearchPage';
import LoginModal from './pages/LoginModal'
import Test2 from './Test2';
import Wishlist from './pages/Wishlist';
import Stay from './pages/Stay';
import ActivityDetails from './pages/ActivityDetails';
import CheckoutActivity from './pages/CheckoutActivity';
import Activity from './pages/Activity';
import CorporateEvents from './pages/CorporateEvents';
import Review from './pages/Review';
import pageNotFound from './pages/pageNotFound'
import Share from './Share';
import LoginComponent from './Login/LoginComponent';
import SignupComponent from './signup/SignupComponent';
import Modal from './components/Modal';
import Villa from './Villa';
import {  useEffect } from 'react';
import OrderConfirmed from './OrderConfirmed';
import Popup from './pages/Popup';
import StarRating from './components/StarRating';


// import User from './user/User';
// import Banner from './Home/Banner';
function ScrollToTop() {
      const { pathname } = useLocation();
  
      useEffect(() => {
          window.scrollTo(0, 0);
      }, [pathname]);
  
      return null;
  }

function App() {
  const {authIsReady,user}=useAuthContext();
 
  return (
    <div className="App">
        
              <BrowserRouter>
              <ScrollToTop />
                <Navbar/>
                  <Switch>
                        <Route exact path='/'>
                              <Home/>
                        </Route>
                        <Route exact path='/home'>
                              <Home/>
                        </Route>
                        <Route exact path='/explore'>
                              <Explore/>
                        </Route>
                        <Route exact path='/cart'>
                              <Cart/>
                        </Route>
                        
                        <Route exact path='/checkout/activity/:locationId/:activityId/:productId/:checkIn/:checkOut/:nights/:guests/:cartId?'>
                              <CheckoutActivity/>
                        </Route>
                        <Route exact path='/checkout/stay/:locationId/:stayid/:productId/:checkIn/:checkOut/:nights/:guests/:cartId?/:popupdata?'>
                              <Checkout/>
                        </Route>
                        <Route exact path='/signup'>
                              <Signup/>
                        </Route>
                        <Route exact path='/login'>
                              <Login/>
                        </Route>
                        <Route exact path='/loginM'>
                              <LoginModal/>
                        </Route>
                        <Route exact path='/Fetch'>
                              <Fetch/>
                        </Route> 
                        <Route exact path="/tf">
                              <TransactionForm/>
                        </Route>
                        <Route exact path='/explore/:id' >
                              <Explore />
                        </Route> 
                        <Route exact path='/stay/:stayType'>
                              <Stay/>
                        </Route>
                        <Route exact path='/details/:locationId/:stayid/:id'>
                              <ProductDetails/>
                        </Route> 
                        <Route exact path='/search/:search_text'>
                              <SearchPage />
                        </Route>   
                        <Route exact path='/loginj'>
                              <LoginComponent/>
                        </Route>
                        <Route exact path='/signupj'>
                              <SignupComponent/>
                        </Route>
                        <Route exact path='/modal'>
                              <Modal/>
                        </Route>
                        <Route exact path='/m'>
                              <Test2/>
                        </Route> 
                        <Route exact path='/wishlist'>
                              <Wishlist/>
                        </Route>
                        <Route exact path='/activityDetails/:locationId/:activityId/:productId'>
                              <ActivityDetails/>
                        </Route>
                        <Route exact path='/stay'>
                              <Stay/>
                        </Route>
                       
                        <Route exact path='/activity'>
                              <Activity/>
                        </Route>
                        <Route exact path='/activity/:activityType'>
                              <Activity/>
                        </Route>
                        <Route exact path = '/corporateEvents'>
                              <CorporateEvents/>
                        </Route>
                        <Route exact path = '/review/:userId/:productId'>
                              <Review/>
                        </Route>
                        <Route exact path = '/explore/'>
                             <pageNotFound/>
                        </Route>
                        <Route exact path = '/villa'>
                             <Villa/>
                        </Route>
                        <Route exact path = '/order-confirmed'>
                             <OrderConfirmed/>
                        </Route>
                        <Route exact path = '/popup'>
                             <Popup/>
                        </Route>
                        <Route exact path = '/star'>
                             <StarRating/>
                        </Route>
                        <Route path="*" component={pageNotFound} />
                    </Switch>
              </BrowserRouter>
       
    </div>
  );
}

export default App;
