import React,{ useRef } from 'react'
import firebase from "firebase";
import { timestamp } from "../firebase/config"
// import {ShareSocial} from 'react-share-social' ;
// import { FacebookButton, FacebookCount } from "react-social";


import { useParams, Link,useHistory } from "react-router-dom";
import campImg1 from '../assets/pdp1.jpg'
import campImg2 from '../assets/pdp3.jpg'
import campImg3 from '../assets/pdp5.jpg'
import moreImg from '../assets/loc5.png'
import campImg5 from '../assets/pdp2.jpg'
import campImg4 from '../assets/pdp4.jpg'
import shareicon from '../assets/shareicon.png'
import wishlistIcon from '../assets/wishlistBlack.svg'
import activity1 from '../assets/activityParachute.svg'
import paragliding1 from '../assets/paragliding1.png'
import paragliding2 from '../assets/paragliding2.png'
import boat from '../assets/boat.svg'
import trek from '../assets/trek.svg'
import cottage from '../assets/cottage.png'
import tent from '../assets/tent.png'
import leo from '../assets/leoProfileImg.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react';
import LoginModal from './LoginModal';
import { projectFirestore } from '../firebase/config';
import { useAuthContext } from '../hooks/useAuthContext';
import { useFirestore } from '../hooks/useFirestore';
import Loading from "react-fullscreen-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import Share from '../Share';
import './details.css'
import Footer from '../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Modal from '../components/Modal';
library.add( faArrowRightLong)

function ActivityDetails() {
  const {user}=useAuthContext();
  const {locationId,activityId,productId}=useParams();
  const [activityRes,setActivityRes]=useState(null);
  const quantity_localS=localStorage.getItem('local-storage-quantity');
  const CheckIn_localS=localStorage.getItem('local-storage-checkIn');
  const CheckOut_localS=localStorage.getItem('local-storage-checkOut');
  const [detailsRes,setDetailsRes]=useState(null);
  const [quantity,setQuantity]=useState( quantity_localS ? JSON.parse(quantity_localS) : 1);
  const [units,setUnits]=useState(2);
  const [error,setError]=useState(null);
  const [checkIn,setcheckIn]=useState(CheckIn_localS ? JSON.parse(CheckIn_localS) : '');
  const [checkOut,setcheckOut]=useState(CheckOut_localS ? JSON.parse(CheckOut_localS) : '');
  const [price,setPrice]=useState(null);
  const [nights,setnights]=useState(null);
  const [dateError,setDateError]=useState(null);
  const [modalOpen,setModalOpen]=useState(false);
  const [modalShareOpen,setShareModalOpen]=useState(false);
  const [orderDetails,setOrderDetails]=useState(null)
  const [cartDetails , setCartDetails] = useState(null)
  const [scrollPosition,setScrollPosition]=useState('');
  const [addToWishlist , setAddToWishlist] = useState(false)
  const [showError,setShowError]=useState(null);
  const [prodActivity , setProdActivity] = useState(null)
  const [activityDetails , setActivityDetails] = useState({})
  const [addedActivityKey , setAddedActivityKey] = useState({})
  const [cartCheckActivityKey , setcartCheckActivityKey] = useState('');
  const [hideCheckout , setHideCheckout] = useState(false);
  const [loading , setLoading] = useState(false);
  const [socialShare , setSocialShare] = useState(false)
  const [enableCheckout , setEnableCheckout] = useState(false)
  const [showShare,setModal]=useState(false);
  

  const ref1 = useRef();
  const ref2 = useRef();

  const campImages = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll:1,
    arrows:true,
    responsive:[
      {
        breakpoint: 576,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: false,
        centerPadding: '40px',
        slidesToShow: 1
      }
    }
    ]
  }

  const reviewCards = {
    dots: true,
    infinite: true,
    autoplay:true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll:1,
    arrows:true,
    responsive: [
      {
          breakpoint: 1366,
          settings: {
            arrows: true,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1141,
          settings: {
            arrows: true,
            centerMode: false,
            centerPadding: '0%',
            slidesToShow: 3
          }
        },
      {
          breakpoint: 1025,
          settings: {
            arrows: true,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 991,
          settings: {
            arrows: true,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 2,
            dots:false
          }
        },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 2
        }
      },
      {
          breakpoint: 576,
          settings: {
            arrows: false,
            centerMode: false,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: false,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  }
      const handleShareClose=()=>{
        setShareModalOpen(false);
        //console.log(modalShareOpen)
      }
      const handleOpenShare=()=>{
        setShareModalOpen(true);
      }

      const toggleModal = () => {
        setModal({ showShare: !showShare })
      
        //console.log("called")
      }

      const style  = {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
      };
      useEffect(() => {

          let ref=projectFirestore.collection('location').doc(locationId).collection('activity').doc(activityId).collection(activityId);
          const unsubscribe=ref.onSnapshot((snapshot)=>{
            let resultActDetails=[];
            snapshot.docs.forEach((doc)=>{
              if(doc.id===productId)
              resultActDetails.push({...doc.data(),id:doc.id})

            })
            //console.log(resultActDetails,'hey result')
            //update State
            setActivityRes(resultActDetails);
            
        },(error)=>{
          // setActivityDetail('could not fetch data')
        })
    

        return()=>{unsubscribe()};
      }, []);

// checkout summary card functions 
//  // increment decreament , disable past date  functions
    const handleDecrement=(quantity)=>{ 
        let newQuantity;
        if(quantity===1){
            newQuantity=Number(quantity)-1;
        }
        else{
            newQuantity=Number(quantity)-1;
            setQuantity(newQuantity);
    
        }
        
     }
     const handleIncrement=(quantity)=>{
          let newQuantity=Number(quantity)+1;
          setQuantity(newQuantity)
      } 
      const handleNightsChange=()=>{
        let times  = checkIn 
        setnights(times);
         setDateError(null); 
    
      }
      const handleCheckIn=(e)=>{
        setcheckIn(e.target.value)
        handleNightsChange();
    
      }
      const handleCheckOut=(e)=>{
        setcheckOut(e.target.value)
        // //console.log('this is the activity time ',checkOut)
        handleNightsChange();
    
      }

      const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() ).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };
//  // scrolling functionality
    const handleScroll = (scrollPosition) => {
      const position = window.pageYOffset;
      if(position>300){
          setScrollPosition(1)
      }else{
        setScrollPosition(0)
      }
    };
//  // close the modal 
  
    const handleClose=()=>{
      setModalOpen(false);

    }


    useEffect(() => {
      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const diffDays = (date, otherDate) => {
      return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
    };
      
    const getValue=()=>{
      setEnableCheckout(true)
      if(document.getElementById('myInput').value>0){
          return document.getElementById('myInput').value;
      }
    }

    const handleChange=()=>{
      setPrice(document.getElementById('myInput').value)
      
    }

//   // add to cart functionality 
      // add to cart 
      const handleAddToCart = (siteDesc,quant,siteName,cost,) => {
        setLoading(true)
        // console.log(siteDesc+ ' = ' + quant +' = ' + siteName + ' = ' + cost + ' = ' + checkIn + ' = ' + checkOut +' = ' +price )
          firebase.firestore().collection('Cart').add({
            product_id:productId,
            user_id:user.uid,
            location:locationId,
            stay_type:activityId, 
            activityDate:checkIn,
            activityTime:checkOut,
            activityPrice:Number(cost),
            totalGuests:quant,
            addedToCart:1,
            createdAt:timestamp.fromDate(new Date()),
            // description:siteDesc,
            // activityName:siteName,
            totalPrice:Number(cost),
            cost:Number(cost),
            cartType:'activity',
          })
          setTimeout(() => { setLoading(false) }, 500);
          setCartDetails(true);
      }

//   // handle submit functionality
      const handleSubmit=async (e)=>{
        e.preventDefault();
        // setnights(diffDays(new Date(checkIn), new Date(checkOut)));
        setDateError(null); 
        let val= await getValue();
        handleChange();
        //  setPrice(val)
      }
      useEffect(()=>{
        localStorage.setItem('local-storage-quantity',JSON.stringify(quantity));
        localStorage.setItem('local-storage-checkIn',JSON.stringify(checkIn));
        localStorage.setItem('local-storage-checkOut',JSON.stringify(checkOut));

      },[quantity,checkIn,checkOut])
  return (
    <>
    <Modal/>
        { loading && <Loading loading={true} background="#000000c4" loaderColor="#ffe23f" />}
        {modalShareOpen && <div> <Share isOpen={modalShareOpen} handleClose={handleShareClose}/> </div>}
        {modalOpen &&<div> <LoginModal handleClose={handleClose}/></div>}
        {!modalOpen && <div className="container-fluid productDetails">
         
        {activityRes && (<div className='responsive-carousel mx-4'>
            {activityRes.map((doc)=>(<Carousel infiniteLoop dynamicHeight centerMode centerSlidePercentage={90} showStatus={false} showArrows={false} showIndicators={false}>
              {doc.photoGallery && doc.photoGallery.map((eachPhoto)=>(<div className="image" key={eachPhoto}>
                <img src={eachPhoto} alt="" />
              </div>))}
            </Carousel>))}
        </div>)}

        <div className='innerProductDetails'>
    {activityRes && (<div className='descriptionSection'>
      {activityRes.map(doc=>(<div>
          <div key={doc.id}>
        <div className='row'>
            <div>
          <div className='col-xl-12 col-lg-12 col-sm-12'>
              <div className='headdingNamesArea d-flex'>
                <h1 className="productName fontbold mx-4 mx-lg-0 pt-2">{doc.activityName}</h1>
                <div className="buttonSection">
                    <button type='button' className='shareButton commonButton button mx-3' data-toggle="modal" data-target="#myModal"  onClick={handleOpenShare}>
                    <span className='icons'><img src={shareicon} width="20px" alt=''/></span> <span  className='btn_text'>Share</span>
                    </button>
                </div>
              </div>
              {/* Share body */}
              {/* <div className="collapse" id="collapseExample">
                  <div className="card card-body"> */}
                  
                  {/* </div>
              </div> */}
              <div className="subHeadArea">
                <h4 className="location mx-4 mx-lg-0">{doc.activityLocation}</h4>
              </div>
              
          </div>
          </div>
        </div>
        </div>
        
        <div className='description col-lg-11 col-md-12 col-sm-12'>
          {doc.desc}
        </div>
         <div className='suppliesSection row mg-t-20'>
          
          {/* Cross selling product and activities */}

          {/* <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 underSection mg-t-20">
              <div className='col-lg-12 col-sm-12'>
                <h2 className='text-left text-bold-6'>Under the same hood</h2>
                <h5>More accommodations from the same property</h5>
                {crossSaleProds && 
                  <div className='row'>
                      {crossSaleProds.map(crossProd=>(
                        <div className='col-lg-3 col-md-3 col-sm-3 col-6 propertyHood under_the_hood'>
                          <Link className='linksFor' to = {`/details/${locationId}/${stayid}/${crossProd.id}`}>
                              <img src={cottage} alt=''></img>
                              <p className='mg-t-10'>{crossProd.siteName}</p>
                          </Link>
                        </div>
                      ))}
                  </div>
                }
              </div>
          </div>
          <div className=" col-lg-11 col-md-12 col-sm-12 nearbySection mg-t-20">
              <div className='col-lg-12 col-sm-12'>
                <h2 className='text-left text-bold-6'>Activities Nearby</h2>
                {crossSaleActivity && 
                  <div className='row'>
                    {crossSaleActivity.map(crossSaleAct=>(
                      <div className='col-lg-3 col-md-3 col-sm-3 col-6 propertyHood under_the_hood' >
                        <Link className='linksFor' to = {`/activityDetails/${locationId}/${crossSaleAct.activityType}/${crossSaleAct.id}`}>
                          <img src={paragliding1} alt=''></img>
                          <p className='mg-t-10'><small>{crossSaleAct.activityName}</small></p>
                        </Link>
                      </div>
                    ))}
                  </div>
                }
              </div>
          </div> */}
          
         
        </div>
        </div>))}
    </div>)}

    {/* detail card */}
        {/* activity detail card */}
        {  activityRes && (
        <div className={scrollPosition ? "hidee serviceCostSection col-sm-4":"serviceCostSection col-sm-4"}  >
          { activityRes.map((doc,index)=>(
            // For desktop View
            <div className='innerSectionServiceCost desktop_show'>
                <div className='costSection1'>
                  <div className='text-left text-bold-6' >
                  {!isNaN(price) && <h3>₹<span id="myH3">{(doc.price)*quantity}</span> </h3>}
                    <input type="hidden" id="myPrice" value={(doc.price)*quantity} />
                  </div>
                  <div className='text-right'>
                      {/* {!isNaN(nights) && <div className=""><small>Total Nights: {nights}</small></div>} */}
                      <small><strong>{(doc.price)}/Person</strong></small>
                  </div>
                </div>
              <hr className=''/>
                <div className="text-start d-flex align-items-center total_guest">
                  <span>Total Guest </span>
                    <div className="qty text-end text-dark">
                        <button  onClick={()=>handleDecrement(quantity,index)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                        <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={quantity} /> 
                        <button onClick={()=>handleIncrement(quantity,index)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                    </div>
                </div>
              <hr className=''/>
                {/* <form onSubmit={handleSubmit}> */}
                {/* <input type="hidden" id="myPrice"  value={(doc.price*nights)*units}/> */}
                <div className='dateSection'>
                  <div className='checkDiv position-relative'>
                      <h6>
                        <p className='text-left'>Activity Date</p>
                      </h6>
                      <input value={checkIn} onChange={handleCheckIn} min={disablePastDate()} required id='checkedInDate' name='checkedInDate' type="date" ref={ref1} onFocus={() => (ref1.current.type = "date")}
                      onBlur={() => (ref1.current.type = "date")} className='dateInput' placeholder='Select a date'></input>
                  </div>
                  <div className='checkDiv position-relative'>
                      <h6>
                        <p className='text-left'>Activity Time</p>
                      </h6>
                      {/* <input  value={checkOut} onChange={handleCheckOut} min={checkIn}  required id='checkedOutDate' name='checkedOutDate' type="date" ref={ref2} onFocus={() => (ref2.current.type = "date")}
                      onBlur={() => (ref2.current.type = "date")} className='dateInput' placeholder='Select a date'></input> */}
                      <select value={checkOut} onChange={handleCheckOut} className='dateInput'  id="checkedOutDate" name= "checkedOutDate" required>
                        <option value="" selected> Select Time </option>
                        <option value="9:00">9:00 </option>
                        <option value="10:00">10:00 </option>
                        <option value="11:00">11:00 </option>
                        <option value="12:00">12:00 </option>
                        <option value="13:00">13:00 </option>
                        <option value="14:00">14:00 </option>
                        <option value="15:00">15:00 </option>
                        <option value="16:00">16:00 </option>
                        <option value="17:00">17:00 </option>
                        <option value="18:00">18:00 </option>
                        <option value="19:00">19:00 </option>
                        <option value="20:00">20:00 </option>
                        <option value="21:00">21:00 </option>
                      </select>
                      
                  </div>
                  {/* <button className='subCheckDates'><FontAwesomeIcon icon=" fa-arrow-right-long" /></button> */}
                </div>
                {/* </form> */}
                <div className={hideCheckout? "onlyCartButton mg-t-10 ": "buttonSection mg-t-10 "}>
                { !hideCheckout && !nights && user &&<button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                  { !hideCheckout && !nights && !user  &&<button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                  {/* {nights && !user &&<button  type='button' className="fw-bold advButton mx-3 hi" id="bookbtn">Checkout </button>} */}
                  { !hideCheckout && nights && <Link to={`/checkout/activity/${locationId}/${activityId}/${productId}/${checkIn}/${checkOut}/${nights}/${quantity}`} ><button type='button' className="fw-bold advButton mx-3" id="bookbtn">Checkout </button></Link>}

                  {/* { !user && <button className="fw-bold advButton mx-3"  type="button" id="addCartBtn" onClick={()=> setModalOpen(true)}>Add to Cart</button>} */}
                  {!user &&  <button type='button'  className="fw-bold advButton mx-3" data-toggle="modal" data-target="#loginSignupModal" >Add to Cart</button>}
                  {!nights && user &&<button type='button' className="fw-bold advButtonDisabled s mx-3" id="bookbtn" disabled>Add To Cart </button>}
                  { user && nights && !cartDetails &&  <button className="fw-bold advButton mx-3" type="button" id="addCartBtn" onClick={()=> handleAddToCart(doc.siteDesc,quantity,doc.siteName,doc.price,doc.tentSize)}>Add to Cart</button>}
                  { cartDetails && user &&  <Link to={`/cart`}> <button className="fw-bold advButton mx-3" type="button" id="addCartBtn">Go to Cart</button> </Link> }
                </div>
            </div>
            ))}
        </div>)}
            {/* mobile and tab view down */}
        {  activityRes && (
          <div>
            { activityRes.map((doc,index)=>( 
              <div>
                <div className="innerSectionServiceCost mobile_show px-3 py-2">
                  <div className="row">
                    <div className="col-6">
                      <div className="costSection1">
                          <div className='text-left ' >
                            {!isNaN(price) && <h5 className='mb-0 fw-bold'>₹{(doc.price)*quantity} </h5>}
                              <input type="hidden" id="myPrice" value={(doc.price)*quantity} />
                          </div>
                          <div className='text-right'>
                              {/* {!isNaN(nights) && <div className=""><small>{nights} Nights</small></div>} */}
                              <small><strong>{(doc.price)}/Person</strong></small>
                          </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="total_guest">
                        <p className='mb-0 text-left d-none'>Total Guest </p>
                        <div className="qty text-end text-dark">
                            <button  onClick={()=>handleDecrement(quantity,index)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-2">-</button> 
                            <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={quantity} /> 
                            <button onClick={()=>handleIncrement(quantity,index)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-2">+</button>
                        </div>
                      </div>
                    </div>
                  </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="checkDiv position-relative">
                            <p className='mb-0 text-left'>Activity Date</p>
                            <input value={checkIn} onChange={handleCheckIn} min={disablePastDate()} required id='checkedInDate' name='checkedInDate' type="date" ref={ref1} onFocus={() => (ref1.current.type = "date")}
                                  onBlur={() => (ref1.current.type = "date")} className='dateInput' placeholder='Select a date'>
                            </input>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="checkDiv position-relative">
                            <p className='mb-0 text-left'>Activity Time</p>
                            {/* <input  value={checkOut} onChange={handleCheckOut} min={checkIn}  required id='checkedOutDate' name='checkedOutDate' type="date" ref={ref2} onFocus={() => (ref2.current.type = "date")}
                                  onBlur={() => (ref2.current.type = "date")} className='dateInput' placeholder='Select a date'>
                            </input> */}
                            <select value={checkOut} onChange={handleCheckOut} className='dateInput'  id="checkedOutDate" name= "checkedOutDate" required>
                              <option value="" selected> Select Time </option>
                              <option value="9:00">9:00 </option>
                              <option value="10:00">10:00 </option>
                              <option value="11:00">11:00 </option>
                              <option value="12:00">12:00 </option>
                              <option value="13:00">13:00 </option>
                              <option value="14:00">14:00 </option>
                              <option value="15:00">15:00 </option>
                              <option value="16:00">16:00 </option>
                              <option value="17:00">17:00 </option>
                              <option value="18:00">18:00 </option>
                              <option value="19:00">19:00 </option>
                              <option value="20:00">20:00 </option>
                              <option value="21:00">21:00 </option>
                            </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="">
                            <div className={hideCheckout? "onlyCartButton mt-2 flex-row": "buttonSection mt-2 flex-row"}>
                              { !hideCheckout && !nights && user &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}
                                { !hideCheckout && !nights && !user  &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}
                                {/* {nights && !user &&<button  type='button' className="fw-bold advButton mx-3 hi" id="bookbtn">Checkout </button>} */}
                                { !hideCheckout && nights && <Link to={`/checkout/activity/${locationId}/${activityId}/${productId}/${checkIn}/${checkOut}/${nights}/${quantity}`} ><button type='button' className="fw-bold advButton " id="bookbtn">Checkout </button></Link>}

                                { !user && <button className="fw-bold advButton "  type="button" id="addCartBtn" onClick={()=> setModalOpen(true)}>Add to Cart</button>}
                                {!nights && user &&<button type='button' className="fw-bold advButtonDisabled s " id="bookbtn" disabled>Add To Cart </button>}
                                { user && nights && !cartDetails &&  <button className="fw-bold advButton " type="button" id="addCartBtn" onClick={()=> handleAddToCart(doc.desc,quantity,doc.activityName,doc.price,doc.tentSize)}>Add to Cart</button>}
                                { cartDetails && user &&  <Link to={`/cart`}> <button className="fw-bold advButton" type="button" id="addCartBtn">Go to Cart</button> </Link> }
                            </div>
                        </div>
                      </div>
                    </div>

                </div>
              </div> 
            ))}
          </div>
        )}


    </div>
    
  </div>}
      <Footer/>
  </>
  )
}

export default ActivityDetails
