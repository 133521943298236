import React,{useEffect,useState} from 'react'
import { useParams,useHistory } from "react-router-dom";
import { projectFirestore } from '../firebase/config';
import "./cart.css";
import "./explore.css";
import stayImg1 from "../assets/stayImg1.png";
import { useAuthContext } from '../hooks/useAuthContext';
import { useFirestore } from '../hooks/useFirestore';
import LoginModal from './CheckoutLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import CouponComp from '../components/CouponComp';
import { useCollection } from '../hooks/useCollection'
import SignupComponent from '../signup/SignupComponent';
import LoginComponent from '../Login/LoginComponent';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const CheckoutActivity = () => {
    
    const {locationId,activityId,productId,checkIn,checkOut,nights,guests,userId}=useParams();
    const { addDocument, response } = useFirestore('orders')
    const {documents,errors}=useCollection('coupon')
    const {user}=useAuthContext()
    const [checkoutRes,setCheckoutRes]=useState();
    const [error,setError]=useState(null);
    const [productRes,setProductRes]=useState(null);
    const [activityRes,setActivityRes]=useState(null);
    const [checkInDate,setcheckInDate]=useState(checkIn);
    const [checkOutDate,setcheckOutDate]=useState(checkOut);
    const [nightss,setnights]=useState(nights);
    const [checkActOutQuantity,setcheckActOutQuantity]=useState(guests);
    const [showLoginModal,setShowLoginModal]=useState(false)
    const [activityData,setActivityData]=useState(null);
    const [price,setPrice]=useState(activityData ? activityData.totalPrice:0)
    const history=useHistory();  
    const [allCoupons,setAllCoupons]=useState(false) 
    const [couponVal,setCouponVal]=useState('');
    const [changer,setChanger]=useState();
    const [counter,setCounter]=useState(0)
    const [couponError,setCouponError]=useState(false);
    const [couponPrice,setCouponPrice]=useState(0);
    const [loginC , setLoginC] = useState(false);

    let count;
        
if(user){
    let num=user.phoneNumber;
    let str=num.toString();
    // //console.log("n",str)
   let phoneNum=parseInt(str.slice(1));
    var options = {
        "key": "rzp_test_6gcvEmraja9tMY", // Enter the Key ID generated from the Dashboard
        "amount":(price)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Adventrio Escapes",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
            // alert(response.razorpay_payment_id);
            addDocument({
                email:user.email,
                name:user.displayName,
                paymentId:response.razorpay_payment_id,
                productId:productId,
                uid:user.uid,
                numberOfNights:nights,
                activityDate:checkInDate,
                acitivityTime:checkOutDate,
                totalPeople:checkActOutQuantity,
                location:locationId,
                activityCategory:activityId,
                totalPrice:price,
              })
             
                const text = [
                    "name: "+user.displayName,
                    "paymentId: "+response.razorpay_payment_id,
                   "productId: "+productId,
                    "uid: "+user.uid,
                    "numberOfNights "+nights,
                    "checkInDate: "+checkInDate,
                    "checkOutDate: "+checkOutDate,
                    "totalPeople: "+checkActOutQuantity,
                    "location: "+locationId,
                    "stayCategory: "+activityId,
                    "price: "+price,
                  ].join('\n')

                  let recipient = phoneNum;
                    let message="Thank you "+user.displayName+", your order has been placed successfully. Our executive will get back to you shortly. %0D%0AHere are your order details. "+text+". Thanks and Regards."
                    fetch(`https://www.uat-calibrill.com/send-whatsapp?recipient=${recipient}&textmessage=${message}`).then(res=>{
                        if(!res.ok){
                        throw new Error('Oops! Unable to proccess your request.');
                        }
                        NotificationManager.success('Order Placed Successfully!', 'Success');
                        history.push("/order-confirmed");
                    
                    }).catch(err=>{
                        NotificationManager.error('Oops! Unable to proccess your request.', 'Error');
                    });
                    
            //   window.location.href = '/';
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature)

           
        },
        "prefill": {
            "name": user.displayName  ,
            "email": "admin@adventrio.com",
            "contact": user.phoneNumber
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        }
      };
      var rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
      });
    }
    useEffect(() => {
        if(response.success){            
            window.location.href = '/';
        }
       
    }, [response.sucess])
   
    useEffect(() => {
        let ref=projectFirestore.collection('orders');
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
            let results=[]
        
                let ref1=projectFirestore.collection('location').doc(locationId).collection('activity').doc(activityId).collection(activityId);
                const  unsubscribe2=ref1.onSnapshot((snapshot)=>{
                    let results=[];
                    snapshot.docs.forEach((doc)=>{
                        if(productId===doc.id){
                            //console.log('in snapshot',doc.id + ' prod id '+ productId)
                            results.push({...doc.data(),id:doc.id})
                            setActivityData({...doc.data(),id:doc.id})
                    }
                        
                    })
                    //update State   
                    
                    setActivityRes(results);
                        setError(null);

                })  

        },(error)=>{
            setError('could not fetch data')
        })
        
    }, [userId,activityId,locationId])


    const diffDays = (date, otherDate) => {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
    };

    useEffect(() => {
        handleNightsChange()  
    }, [checkInDate,checkOutDate]);

const handleNightsChange= ()=>{
     setnights(diffDays(new Date(checkInDate), new Date(checkOutDate)));  
}
    const handleCheckIn=(e)=>{
        setcheckInDate(e.target.value)
        handleNightsChange();

    }

   const handleCheckOut=(e)=>{
    setcheckOutDate(e.target.value)
    handleNightsChange();

    }

    const handleDecrement=(checkActOutQuantity)=>{
        let newQuantity;
        if(checkActOutQuantity===1){
            newQuantity=Number(checkActOutQuantity)-1;
        }
        else{
            newQuantity=Number(checkActOutQuantity)-1;
            setcheckActOutQuantity(newQuantity);
    
        }
        
     }
     const handleIncrement=(checkActOutQuantity)=>{
        let newQuantity;
        newQuantity=Number(checkActOutQuantity)+1;
        setcheckActOutQuantity(newQuantity)
    }
    // const handlePrice=()=>{
    //     setPrice(document.getElementById('myInput').value-couponPrice)
    
    //  }
    //  useEffect(() => {
    //     if(price>0){
    //     rzp1.open();
        
    // }
        
    //  }, [price])

 const handleCheckoutProcess=(e)=>{
    e.preventDefault();
    rzp1.open();
 }
const handleClose=()=>{
    setShowLoginModal(false)
}
 
const notLoggedIn=(e)=>{
    NotificationManager.warning('Please Login First!', 'Warning');
}
useEffect(() => {
    count=0;
    setCouponError(false);
    setCounter(0)
    //console.log("initial",counter)
   
   if(documents){ documents.forEach(ele => {
        if(ele.couponName===couponVal){
            count=count+1;
            setCouponError(false);
            //console.log('')
            setCounter(1);
            setCouponPrice(ele.couponPrice)    
        }
   }

   );}

   if(count==0){
    setCouponPrice(0)
    setCouponError(true)
    //console.log("Invalid");
   }
}, [changer])
const handleCoupon=(e)=>{
    count=0;
    e.preventDefault();
    setCouponError(false);
    setCounter(0)
    //console.log("initial",counter)
    documents.forEach(ele => {
        if(ele.couponName===couponVal){
            count=count+1;
            setCouponError(false);
            //console.log('')
            setCounter(1);
            setCouponPrice(ele.couponPrice)    
           
        }
   }
   );
   if(count==0){
    setCouponPrice(0)
    setCouponError(true)
    //console.log("Invalid");
   }

}
const handleCouponOfList=(couponName)=>{
    setCouponVal(couponName);
    setChanger(couponVal)
    setAllCoupons(false) // to close the coupon list box after applying the coupon
}
const handleAllCouponToggle=()=>{
    if(allCoupons){
        setAllCoupons(false)
    }
    else{
        setAllCoupons(true)
    }
}
const handleCouponChange=(e)=>{
    setCouponVal(e.target.value)

}
useEffect(()=>{
if(activityData){
    setPrice(activityData.price*checkActOutQuantity+26-couponPrice)
}
},[checkActOutQuantity,couponPrice,activityData])
//console.log(activityData,"activity data")
//  handlePrice();
    return ( 
    <>
    <NotificationContainer/>
    {!showLoginModal && <div className="checkOutParent container">
        <div className="checkOutPg pb-2 justify-content-center align-items-start">
            <div className="searchDiv">
                <div className="">
                    <h1 className="text-dark text-center fw-bold display-6">Checkout</h1>
                </div>
                <hr className="sectionHr m-auto text-center text-dark "  />
                {/* <h5 className="text-dark text-center fw-bold py-2">Item List</h5> */}
            </div>
        </div>
        
        
         <div className="row p-md-5 checkOutRow ">
             {activityRes && <>
             {activityRes.map(doc=>(
            <div className="col-md-7 " key={doc.id}>
                <div className="card mb-3 stayFeaturedImg" style={{'height':'auto'}}>
                    <div className="row">
                        <div className="col-md-4 ">
                            <img src={stayImg1} className="stayFeaturedImg img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8 col-sm-10 align-self-center">
                            <div className="card-body">
                                <h5 className="card-title"><strong>{doc.activityName}</strong></h5>
                                {<p className="card-text text-start">{doc.desc.substr(0, 250)}....</p>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* login / phone otp form */}

                {/* login / phone otp form */}

                {/* {!user && <LoginModal handleClose={handleClose}/>} */}
                {!user && !loginC && 
                    <p className='text-center pt-3 fw-bold' onClick={()=>setLoginC(true)} style={{'cursor':'pointer'}}>
                        <div>
                            <small className='fw-normal'>Have An Account</small>
                            <p>Login</p>
                        </div>
                    </p>
                }
                {!user && loginC && 
                    <p className='text-center pt-3 fw-bold' onClick={()=>setLoginC(false)} style={{'cursor':'pointer'}}>
                        <div>
                            <small className='fw-normal'>Don't Have An Account</small>
                            <p>Sign Up</p>
                        </div>
                    </p>
                }
                {!user && !loginC && <SignupComponent className="checkoutLoginSignup"/>}
                {!user && loginC &&  <LoginComponent className="checkoutLoginSignup"/>}
                {/* login / phone otp form ends */}

                {/* login / phone otp form ends */}
            </div>))}  
            </>}  
            
            {activityRes && <div className="col-md-5">
                {activityRes.map(doc=>(<div className="row">
                    <div className="card" key={doc.id}>
                        <div className="card-body">
                            <div className="row pb-3">
                                <div className="col-sm-12 ">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <strong>Total Guest</strong>
                                        </div>
                                        <div className="col-sm-9 qty text-end total_guest">
                                            <button  onClick={()=>handleDecrement(checkActOutQuantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                            <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={checkActOutQuantity} /> 
                                            <button onClick={()=>handleIncrement(checkActOutQuantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="selectedDate pb-3 d-flex flex-row justify-content-between">
                                <div className="checkInDates">
                                    <small><strong>Activity Date</strong></small>
                                    <div>
                                        <input className='dateInput' type="date" name="" id="" title="2022" onChange={handleCheckIn} value={checkInDate} />
                                    </div>
                                </div>
                                <div className="checkOutDates">
                                    <small><strong>Activity Time</strong></small>
                                    <div>
                                        {/* <input className='dateInput' type="time" name="" id="" title="2022" onChange={handleCheckOut}  value={checkOutDate} /> */}
                                        <select value={checkOutDate} onChange={handleCheckOut} className='dateInput'  id="checkedOutDate" name= "checkedOutDate" required>
                                            <option value="" selected> Select Time </option>
                                            <option value="9:00">9:00 </option>
                                            <option value="10:00">10:00 </option>
                                            <option value="11:00">11:00 </option>
                                            <option value="12:00">12:00 </option>
                                            <option value="13:00">13:00 </option>
                                            <option value="14:00">14:00 </option>
                                            <option value="15:00">15:00 </option>
                                            <option value="16:00">16:00 </option>
                                            <option value="17:00">17:00 </option>
                                            <option value="18:00">18:00 </option>
                                            <option value="19:00">19:00 </option>
                                            <option value="20:00">20:00 </option>
                                            <option value="21:00">21:00 </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="priceSection">
                                    <div className="costDetails  ">
                                        <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Total Amount</div>
                                            <div>{(doc.price)*checkActOutQuantity}</div>
                                            {/* <div>{(doc.price*nightss)*quantity}</div> */}
                                            
                                          
                                        </div>
                                        <div className="pb-2 inDetailed ">
                                            <small className="d-flex flex-row justify-content-between">
                                                <div>{doc.price} x {checkActOutQuantity}guest(s)</div>
                                                <div>{(doc.price)*checkActOutQuantity}</div>
                                            </small>
                                        </div>
                                        <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Other Taxes</div>
                                            <div>26</div>
                                        </div>
                                        {couponPrice>0 && <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Coupon</div>
                                            <div>-{couponPrice}</div>
                                        </div>}
                            
                                        <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div><strong>Total Payable</strong></div>
                                            {/* <div><strong>{(doc.price)*checkActOutQuantity+26-couponPrice}</strong></div> */}
                                            <div><strong>{price}</strong></div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="promocode pb-3">
                                    {/* <div>Have a <strong>Promo Code</strong>/ <strong>Referral Code</strong>?</div>
                                    <div className="position-relative">
                                        <input type="text" className="w-100 rounded p-2" />
                                        <button className="position-absolute  submitPromo">Submit</button>
                                    </div> */}
                                    <CouponComp handleCouponOfList={handleCouponOfList} handleCoupon={handleCoupon}  handleCouponChange={handleCouponChange} couponVal={couponVal} couponError={couponError} couponPrice={couponPrice} handleAllCouponToggle={handleAllCouponToggle} allCoupons={allCoupons}/>
                                </div>
                                
                                <div className="row pb-3">
                                    <div className="col-md-12 text-center">
                                        {!user && <button className="fw-bold w-100 advButton" onClick={()=>notLoggedIn()}>Buy Now</button>}
                                        
                                        {user && <form onSubmit={handleCheckoutProcess}>
                                        {/* <input type="hidden" id="myInput" value={((doc.price)*checkActOutQuantity)+26}/> */}
                                        <button  className="fw-bold w-100 advButton">Buy Now</button>
                                        </form>}
                                    </div>
                                </div>

                                <div className="promocode pb-3">
                                    <div><strong>Cancellation policy</strong></div>
                                    <p className="text-start">
                                        <small>If you cancel at least 1 week before your check in day and time, 
                                    you’ll receive a full refund (minus service fees). If you cancel within the 1 week before check in, there will be no refund.</small>
                                    </p>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>))}
            </div>}
            
        </div>
        </div>}

    </>

     );
}

 
export default CheckoutActivity;