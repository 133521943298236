import './villa.css'
import {useState, useEffect} from 'react';
import userIcon from "../src/assets/userIcon.svg";
import phone from "../src/assets/Phone.svg";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { projectFirestore } from './firebase/config';
import { swap, vanishIn } from "react-magic";
import { StyleSheet, css } from "aphrodite";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCoffee,faMap, faPhone, faPeopleGroup, faBaby ,faUser } from '@fortawesome/free-solid-svg-icons'
library.add( faCheckCircle, faCoffee, faMap, faPhone, faPeopleGroup, faBaby,faUser)

const Villa = () => {
    const {user}=useAuthContext()
    const [name, setName] = useState('');
    const [location,setLocation]=useState('');
    const [villaLocation, setvillaLocation] = useState(null)
    const [whatsApp,setWhatsApp]=useState('');
    const [guests,setGuests]=useState('');
    const [children,setChildren]=useState('');
    const history=useHistory();   

    const styles = StyleSheet.create({
      magic: {
        animationName: swap,
        animationDuration: "1s",
        AnimationEvent:onclick
      },
      blink:{
        animationName: vanishIn,
        animationDuration:"2s",
        AnimationEvent:onclick
      }
    });
    const handleForm =(e)=>{
        e.preventDefault()
        //console.log(location);
        const text = [
            "Name: "+name,
            "WhatsApp Number: "+whatsApp,
            "Location: "+location,
            "Guests: "+guests,
            "Chidren: "+children,
          ].join('\r\n')
          //console.log(text)
              let recipient =91+whatsApp;
              let whatsAppMsg="Thank you "+name+", your order has been placed successfully. Our executive will get back to you shortly. %0D%0AHere are your order details. "+text+". Thanks and Regards."
              // let whatsAppMsg="Thank you "+name+", your order has been placed successfully. Our executive will get back to you shortly. Here are your order details.%0D%0A"+encodeURIComponent(text)+".%0D%0AThanks and Regards.";
              fetch(`https://www.uat-calibrill.com/send-whatsapp?recipient=${recipient}&textmessage=${whatsAppMsg}`).then(res=>{
                if(!res.ok){
                  throw new Error('Oops! Unable to proccess your request.');
                }
                 NotificationManager.success('Enquiry Placed Successfully!', 'Success');
                 setName('');
                 setWhatsApp('');
                 setLocation('');
                 setGuests('');
                 setChildren('');
              }).catch(err=>{
                NotificationManager.error('Oops! Unable to proccess your request.', 'Error');
              })
    }

    useEffect(() => {
      let villaLocation=projectFirestore.collection('location')

      const  unsubscribe2= villaLocation.onSnapshot((snapshot)=>{
        let resultsActivty=[];
        snapshot.docs.forEach((doc)=>{
            resultsActivty.push({...doc.data(),id:doc.id})

        })
        //update State
        setvillaLocation(resultsActivty);
      },(error)=>{
        // setErrorFetch('could not fetch data')
      })
    
    }, [])    

    return ( 
        <div className=' villa__container__parent bg-dark mt-0'>
            <NotificationContainer/>
            {/* <form className="mt-5" onSubmit={handleForm}>
                <input type="text" placeholder="Enter Your Name" value={name} onChange={(e)=>setName(e.target.value)} required/>
                <input type="number" placeholder="Enter Your WhatsApp Number" value={whatsApp} onChange={(e)=>setWhatsApp(e.target.value)} required/>
                <input type="text" placeholder="Location" value={location} onChange={(e)=>setLocation(e.target.value)} required/>
                <input type="number" placeholder="Number of Guests" value={guests} onChange={(e)=>setGuests(e.target.value)} required/>
                <input type="number" placeholder="Number of Children" value={children} onChange={(e)=>setChildren(e.target.value)} required/>                
                <button>submit</button>
            </form> */}

        <div className="  villa__container">
        {/* <h1 className='text-center text-dark fw-bold villa__title'>Villa</h1> */}
          <div className="row">

              <div className="col-sm-7"></div>



              <div className="col-sm-5 villa_form_col ">
                <div className="d-flex justify-content-center h-100 mx-3">
                  <div className="card">
                    <div className="card-header">
                      <h4 className='text-dark fw-bold pt-2 text-center mb-0'>Fill Up Your Details </h4>
                    </div>
                    <div className="card-body">
                      <form onSubmit={handleForm}>

                        <div className="input-group form-group mb-3">
                          <input value={name} onChange={(e)=>setName(e.target.value)} required type="text" className={`form-control ${css(styles.blink)} `} placeholder="Enter Your Name"/>
                        </div>

                        <div className="input-group form-group mb-3">
                          <input type="number" value={whatsApp} onChange={(e)=>setWhatsApp(e.target.value)} required className={`form-control ${css(styles.blink)} `} placeholder="Whatsapp number"/>
                        </div>

                        <div className="input-group form-group mb-3">

                            { villaLocation &&  (
                              <select style={{'textTransform':'capitalize'}} value={location} onChange={(e)=>setLocation(e.target.value)} className={`form-control ${css(styles.blink)} shadow-none`} required name="" id="">
                                    <option value="">Select Location</option>
                                {villaLocation.map(doc=>(
                                    <option value={doc.id}>{doc.id}</option> 
                                ))}
                              </select>
                            )}
                        </div>

                        <div className="input-group form-group mb-3">
                          <input type="text"className={`form-control ${css(styles.blink)} `} value={guests} onChange={(e)=>setGuests(e.target.value)} required placeholder="Number of guests"/>
                        </div>

                        <div className="input-group form-group mb-3">
                          <input type="text" className={`form-control ${css(styles.blink)} `} value={children} onChange={(e)=>setChildren(e.target.value)}  placeholder="Number of Children"/>
                        </div>

                        <div className="form-group">
                          <input type="submit" value="Send" className={`btn float-right  login_btn ${css(styles.blink)}`}/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>


          </div>
        </div>  

      </div>

     );
}
 
export default Villa;