import loc4 from "../assets/waterSport.jpg";
import trek from "../assets/trek.svg";
import para from "../assets/para.svg";
import explore from "../assets/explore.svg";
import car from "../assets/car.svg";
import bike from "../assets/motorcycle.svg";
import bus from "../assets/minibus.svg";
import blogImg from "../assets/blogImg.png";
import mas1 from "../assets/1.png";
import mas2 from "../assets/2.png";
import mas3 from "../assets/3.png";
import mas4 from "../assets/4.png";
import mas5 from "../assets/5.png";
import mas6 from "../assets/6.png";
import mas7 from "../assets/7.png";
import mas8 from "../assets/8.png";
import newImg from "../assets/waterfall.jpg";
import newImg3 from "../assets/newImg3.png"
import newImg1 from "../assets/newImg1.png";
import newImg2 from "../assets/newImg2.png";
import premiaSoon from "../assets/villaBg12.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Home/overview.css";
import { useEffect,useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollection } from "../hooks/useCollection";
import {Link} from 'react-router-dom';
import { projectFirestore } from '../firebase/config';
import Footer from '../components/Footer';

const Overview = () => {
    const {documents,error}=useCollection('location');
    const [advSelectRes,setAdvSelectRes]=useState(null);
    const [activityName,setActivityName]=useState(null);


    
    useEffect(() => {
        let ref=projectFirestore.collection('advSelect');
        let resultAdvSelect=[];
        const unsubscribeLocation=ref.onSnapshot((snapshot)=>{
            snapshot.docs.forEach((location)=>{
                resultAdvSelect.push(location.data())     
            })
            
            //console.log("arr",resultAdvSelect)
            resultAdvSelect.forEach((select)=>{ 
                //console.log(select.location,"each");
                let ref2=projectFirestore.collection('location').doc(select.location).collection('stay').doc(select.stayType).collection(select.stayType);
                const unsubscribe=ref2.onSnapshot((snapshot)=>{ 
                    let finalResult=[ ]; 
                    snapshot.docs.forEach((doc)=>{
                        
                        if(doc.data().advSelect){
                            finalResult.push({...doc.data(),id:doc.id,stayType:select.stayType,location:select.location})  
                        }
                           
                    })
                    //console.log("final",finalResult)
                    setAdvSelectRes(finalResult);

                })

            })
           

        })
        let refLoc=projectFirestore.collection('location');
        let resultLocation=[];
        const unsubscribeLoc=refLoc.onSnapshot((snapshot)=>{
            snapshot.docs.forEach((location)=>{
                resultLocation.push(location.id)     
            })
            let resultActs=[];
        let activityList=[];
         //Getting all the Stay Types
         resultLocation.forEach((eachLocation)=>{
            let reff=projectFirestore.collection('location').doc(eachLocation).collection("activity");
            const unsubscribe=reff.onSnapshot((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    resultActs.push({...doc.data(),id:doc.id})  
                    activityList.push(doc.id)   
                })
                const uniqueActivities = resultActs.filter((activity, index, self) => 
                    self.findIndex(a => a.id === activity.id) === index);

                // //console.log("very uniqy",uniqueActivities);
                // //console.log("activity",resultActs)
                // const unique = (value, index, self) => {
                //     //console.log("val=>",value,"index=>",index,"self=>",self)
                //     return self.indexOf(value) === index
                //   }
                //   const uniqueArr = activityList.filter(unique)
                //   //console.log(uniqueArr,"uniq")
               
                setActivityName(uniqueActivities)
            })

        })
        })
        
        return () => {
            unsubscribeLocation();            
        };
    }, [ ]);

    const adventrioSelect = {
        centerMode:true,
        slidesToShow: 3,
        centerPadding:'190px',
        arrows:false,
        autoplay:true,
        dots:true,
        cssEase:'linear',
        speed: 500,
        draggable:true,
        slidesToScroll:1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: false,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
      };
      const location = {
        dots: false,
        infinite: true,
        autoplay:true,
        cssEase:'linear',
        speed: 500,
        slidesToShow: 6,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1141,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '0%',
                  slidesToShow: 4
                }
              },
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 991,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3,
                  dots:false
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
      };
      const activities = {
        dots: true,
        infinite: true,
        autoplay:true,
        cssEase:'linear',
        speed: 500,
        slidesToShow: 5,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1141,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '0%',
                  slidesToShow: 4
                }
              },
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 991,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
            {
                breakpoint: 768,
                settings: "unslick"
            }
          ]
      };
      
      const blogs = {
        dots: true,
        infinite: true,
        autoplay:false,
        speed: 300,
        slidesToShow: 4,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:false,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: false,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 1
              }
            }
          ]
      }
 
      
    return ( 

        <div className="overviewParent position-relative">
            <div className="overview bg-dark position-absolute">
            </div>
            {/* Adventrio Select */}
            {/* <div className="headingSection">
                <div className="sectionHeading">
                    <h1><span className="text-white">Adventrio</span> Select</h1>
                </div>
                <hr className="sectionHr text-white m-auto text-center" />
                <p className="text-white pt-3">Discover your ideal experience with our handpicked sites.</p>
            </div> */}
            <div className="selectedSites bg-dark">
                <div className="headingSection">
                    <div className="sectionHeading">
                        <h1><span className="text-white">Adventrio</span> Select</h1>
                    </div>
                    <hr className="sectionHr text-white m-auto text-center" />
                    <p className="text-white px-3 pt-3">Discover your ideal experience with our handpicked sites.</p>
                </div>                
                <div className="mainDiv1 text-center non_slicky">
                {advSelectRes &&  <Slider {...adventrioSelect} >
                            {advSelectRes.map((doc)=>(
                            <Link target="_blank" to = {`/details/${doc.location}/${doc.stayType}/${doc.id}`} key={doc.id}>
                                <div className = "  carousel1Cards">
                                    <img src={doc.featuredImg} alt="" />
                                    <h4 className="text-white py-2">{doc.siteName}</h4>
                                </div>  
                            </Link>))}
                    </Slider>}
                </div>
            </div>

            {/* Adventrio location */}
            <div className="parentDiv bg-light py-sm-5">
                <div className="headingSection">
                    <div className="sectionTitle">
                        <h1>Location</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark" />
                </div>
                <div className="wrapperDiv">
                    <div className="mainDiv2 non_slicky">
                        {documents &&
                        <Slider {...location}>
                                        {documents.map(doc=>(
                                            <div className = "card" key={doc.id}>
                                                 <Link style={{'textDecoration':'none'}} to={`/explore/${doc.id}`}>
                                                <img src={doc.locationImg} alt="" />
                                                <h4 className="text-dark">{doc.id}</h4>
                                                </Link>
                                            </div>
                                        ))}
                                        
                        </Slider>}
                    </div>

                    <div className="exploreMoreSeciton row align-items-baseline">
                        <div className="col-lg-9 d-none d-lg-block d-xl-block d-xxl-block">
                            <img className="exploreImg" src={explore} alt="" />
                        </div>
                        <div className="col-lg-3  text-sm-center" >
                            <Link to= {`/explore`}><button className="advButton">Explore More</button></Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Adventrio STAY */}

            <div className="parentDiv bg-dark stay" id="stay">
                <div className="headingSection">
                    <div className="sectionTitle">
                        <h1 className="text-white">Stay</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-white" />
                </div>
                <div className="wrapperDiv">
                    <div className="mainDiv6 ">
                        <div className="row">
                            <div className=" premia col-sm-12 col-xs-12 col-md-5 col-xl-5 col-xxl-5 position-relative">
                                <Link to={`villa`}>
                                    <div>
                                        <img className="w-100" src={premiaSoon} alt="" />
                                    </div>
                                </Link>
                                <Link to={`villa`}>
                                    <div className="notice position-absolute">
                                        <h1 className="fw-bold text-yellow">Adventrio</h1>
                                        <h2 className="fw-bold text-white">Villas <br /> 
                                            {/* <span style={{'color':'#FFE23F'}}>Villas</span>  */}
                                            {/* <br /><span style={{'color':'white'}}>Soon..</span> */}
                                        </h2>
                                    </div>
                                </Link>
                                
                            </div>
                            <div className="masonary__part text-webkit-center col-sm-12 col-xs-12 col-md-7 col-xl-7 col-xxl-7 position-relative">
                                <div className="grid">
                                    <Link to="stay/ground-tents">
                                    <div className="grid-item grid-item3 position-relative">
                                            {<img src={mas5} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Ground Tents
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                    <Link to="stay/cottage">
                                    <div className="grid-item grid-item1 position-relative">
                                        {<img src={mas1} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Cottages
                                            </div>
                                        </div>
                                    </div>
                                    </Link>
                                    <div className="grid-item grid-item2 position-relative">
                                        {<img src={mas4} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Vanity Cars
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item position-relative">
                                        {<img src={mas2} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Villa
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item grid-item2 position-relative">
                                        {<img src={mas6} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Campsites
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item position-relative">
                                        {<img src={mas3} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Glamps
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item position-relative">
                                        {<img src={mas7} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Mountain Tents
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item grid-item2 position-relative">
                                        {<img src={mas8} alt="" />}
                                        <div className="stay__type__overlay">
                                            <div className="fw-bold stay__type position-absolute top-50 start-50 translate-middle text-light" style={{'width':'inherit'}}>
                                                Tree House
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-end advBtnDiv" >
                                    <Link to= {`/stay`}><button className="advButton hover__class">Explore More</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Adventrio activities */}
            <div className="parentDiv activities bg-yellow pt-3 pb-sm-5" id="activities">
                <div className="headingSection">
                    <div className="sectionTitle">
                        <h1 className="text-dark">Activities</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark" />
                </div>
                <div className="wrapperDiv">
                    <div className="mainDiv3 non_slicky">
                    {activityName && <Slider {...activities}>
                            {activityName.map((eachActivity)=>(<Link to={`/activity/${eachActivity.id}`} key={eachActivity.id} className = "text-dark card homeActivityCards locationCard1">
                                <div>
                                    <h4 style={{"textTransform":"capitalize"}}>{eachActivity.id}</h4>
                                    <img src={eachActivity.activityImg} alt="" />
                                </div>
                            </Link>))}
                            
                        </Slider>}
                    </div>
                </div>
            </div>
            {/* adventrio travel */}
            <div className="travelDiv bg-light py-3 d-none" id="travel">
                <div className="headingSection">
                    <div className="sectionTitle">
                        <h1 className="text-dark">Travel</h1>
                    </div>
                    <hr className="sectionHr m-auto text-dark text-center" />
                </div>
                <div className="wrapperDiv">
                    <div className="mainDiv4 ">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <img className="card-img-top" src={bike} alt="bike rentals" />
                                    <h4>Bike Rentals</h4>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <img className="card-img-top" src={car} alt="bike rentals" />
                                    <h4>Car Rentals</h4>                                
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="card">
                                    <img className="card-img-top" src={bus} alt="bike rentals" />
                                    <h4>Vanity Vechicle</h4>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* adventrio blogs */}
            <div className="parentDiv ourblogs bg-dark py-5">
                <div className="headingSection">
                    <div className="sectionTitle">
                        <h1 className="text-white">Our Blogs</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-white" />
                </div>
                <div className="wrapperDiv">
                    <div className="mainDiv5 ">
                        <div className="row">
                            <Slider {...blogs}>
                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4">
                                    <div className="card">
                                        <img className="card-img-top" src={blogImg} alt="" />
                                        <div className="card-body">
                                            <h3 className="card-title"><strong>Blog Name</strong></h3>
                                            <h5 className="card-title">Author</h5>
                                            <p className="card-date"><small>Date:June 12 2022</small></p>
                                            <p className="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                                            <p className="card-text read-more"><small className="text-muted"><strong>Read More...</strong></small></p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
     );
}


export default Overview;