import { useAuthContext } from '../hooks/useAuthContext';
import "./explore.css";
import { timestamp } from "../firebase/config"
import maps from "../assets/map-svg.svg";
import raft from "../assets/raft-svg.svg";
import bag from "../assets/luggage-svg.svg";
import Slider from "react-slick";
import loc4 from "../assets/waterSport.jpg";
import trek from "../assets/trek.svg";
import activity1 from "../assets/nepal.png";
import activityIcon1 from "../assets/activityParachute.svg";
import userIcon from "../assets/userIcon.svg";
import cartScroll from "../assets/cartScroll.svg";
import mglass from "../assets/search.png";
import stayImg1 from "../assets/stayImg1.png";
import { useParams } from "react-router-dom";
import { useCollection } from "../hooks/useCollection";
import { useStayCollection } from "../hooks/useStayCollection";
import { useActivityCollection } from "../hooks/useActivityCollection";
import { useState, useEffect,useRef } from 'react';
import { projectFirestore } from '../firebase/config';
import {Link,useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useFirestore } from '../hooks/useFirestore'
import LoginModal from './LoginModal';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import firebase from 'firebase/app';
import Loading from "react-fullscreen-loading";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { faCheckCircle, faCoffee, faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/Footer';
import car from "../assets/car.svg";
import Modal from '../components/Modal';
library.add( faCheckCircle, faCoffee)


function Explore ()  {
    const {id}=useParams();
    const {user}=useAuthContext()
    const { addDocument, response } = useFirestore('wishlist');
    const {documents,error}=useCollection('location');
    const {documentsRes}=useStayCollection('wishlist');
    const {activityDocRes}=useActivityCollection('Cart');
    const [responsee,setResponse]=useState(null);
    const [activityRes,setActivityRes]=useState(null);
    const [activityError,setActivityError]=useState(null);
    const [errorFetch,setErrorFetch]=useState(null);
    const [selectedLocation,setSelectedLocation]=useState(null);
    const [quantity,setQuantity]=useState(1);
    const [stayid,setStayId]=useState(null);
    const [stayRes,setStayRes]=useState(null);
    const [errorStay,setErrorStay]=useState(null);
    const [showError,setShowError]=useState(null);
    const [clickedIndex,setClickedIndex]=useState({})
    const [clickedIndex2,setClickedIndex2]=useState({})
    const [idd,setIdd]=useState('')
    const [idd2,setIdd2]=useState('')
    const [activityId,setActivityId]=useState(null);
    const [wishlistedBool , setwishlistedBool] = useState(0);
    const [showLoginModal,setShowLoginModal]=useState(false);
    const [activityFinalRes,setActivityFinalRes]=useState(null)
    const [loading , setLoading] = useState(false);
    const [activityDetails ,setActivityDetails] = useState(false);
    const [modalOpen,setModalOpen]=useState(false);
    const [wishlistRes,setWishlistResult]=useState(null);
    const [userWishlist,setuserWishlist]=useState(null);
    const [wishlistedProdArr,setWishlistedProdArr]=useState([ ])
    let allLocationId=[ ];
    let wishlistProdId=[ ];
    let cartProductId=[ ];
    let cartActivityUserId;
    let wishlistUserId;
    const history=useHistory()
    const myRef=useRef(null);
    const stayRef=useRef(null);
      const location = {
        dots: true,
        infinite: true,
        autoplay:true,
        speed: 300,
        cssEase:'ease-out',
        slidesToShow: 6,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 5
                }
              },
              {
                breakpoint: 1141,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '0%',
                  slidesToShow: 4
                }
              },
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 991,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3,
                  dots:false
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
            }
          ]
      };
      const exploreActivities = {
        dots: true,    
        autoplay:true,
        slidesToShow: 4,
        infinite:false,
        cssEase:'linear',
        slidesToScroll:1,
        arrows:true,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 1141,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '0%',
                  slidesToShow: 4
                }
              },
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 991,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
      };
      const availableDates = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:true,
        centerPadding: '25%',
        centerMode:true,
        responsive : [
            {
                breakpoint: 991,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 6,
                  dots:false
                }
              },
              {
                breakpoint: 678,
                settings: {
                    arrows: true,
                    centerMode: false,
                    centerPadding: '40px',
                    slidesToShow: 4,
                    dots:false
                    }
                },
                        {
            breakpoint: 576,
            settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 4,
                dots:false
                }
            }
        ],
      }; 
      useEffect(() => {
        if(documents){
          documents.forEach((each)=>{
            allLocationId.push(each.id)
    
          })
        }
          //console.log("all ids",allLocationId)
        
      }, [allLocationId])
      useEffect(() => {
        if(allLocationId.length>0){
        if( allLocationId.includes(id) || window.location.href==="https://adventrio.com/explore" || window.location.href.split("/").pop()==="" || window.location.href.split("/").pop()==="explore"){
        }else{
          history.push('/pageNotFound')
        }
    }
        
      }, [id,allLocationId]);

useEffect(()=>{
  stayRef.current?.scrollIntoView({ behavior: 'smooth' })
},[id])
      useEffect(()=>{
        setLoading(true)
        // stayRef.current?.scrollIntoView({ behavior: 'smooth' })
        
        let ref=projectFirestore.collection('location').doc(id).collection('stay');
        let activityRef=projectFirestore.collection('location').doc(id).collection('activity');
        let wishlistRef=projectFirestore.collection('wishlist');
        const unsubscribwWish=wishlistRef.onSnapshot((snapshot)=>{
            let resultsWishlist=[];
            snapshot.docs.forEach((doc)=>{
                if(user && user.uid===doc.data().user_id){
                resultsWishlist.push({...doc.data(),id:doc.id})
            }

            })
            //update State
            setWishlistResult(resultsWishlist);
            setActivityError(null);

        },(error)=>{
            setErrorFetch('could not fetch data')
        })
       
        //Activity Fetch
        const  unsubscribe2= activityRef.onSnapshot((snapshot)=>{
            let resultsActivty=[];
            snapshot.docs.forEach((doc)=>{
                resultsActivty.push({...doc.data(),id:doc.id})

            })
            //update State
            setActivityRes(resultsActivty);
            setActivityError(null);

        },(error)=>{
            setErrorFetch('could not fetch data')
        })

        //location fetch
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
            let results=[];
            snapshot.docs.forEach((doc)=>{
                results.push({...doc.data(),id:doc.id})

            })
            //update State
            setResponse(results);
            setErrorFetch(null);        },(error)=>{
            setErrorFetch('could not fetch data')
        })  
        if(activityId !==null){
            let activityReff=projectFirestore.collection('location').doc(id).collection('activity').doc(activityId).collection(activityId);
            activityReff.onSnapshot((snapshot)=>{
                let resultsActivityFinal=[];
                snapshot.docs.forEach((doc)=>{
                    if(user && user.uid===cartActivityUserId){
                        if(cartProductId.includes(doc.id)){
                             resultsActivityFinal.push({...doc.data(),id:doc.id,addedToCart:true});
                        }
                        else{
                            resultsActivityFinal.push({...doc.data(),id:doc.id,addedToCart:false});

                        }
                }else{
                    resultsActivityFinal.push({...doc.data(),id:doc.id,addedToCart:false});
                }
    
                })
                //update State
                setActivityFinalRes(resultsActivityFinal);
                
            },(error)=>{
                setStayRes('could not fetch data')
            
            })
        }

        //stay detail Fetch
        if(stayid !== null){
            let stayRef=projectFirestore.collection('location').doc(id).collection('stay').doc(stayid).collection(stayid);
            stayRef.onSnapshot((snapshot)=>{
                let resultsStay=[];
                snapshot.docs.forEach((doc)=>{
                    resultsStay.push({...doc.data(),id:doc.id})
    
                })
                //update State
                setStayRes(resultsStay);
                //console.log(resultsStay,stayRes,'stay res')
                
            },(error)=>{
                setStayRes('could not fetch data')
            })
        }
        setTimeout(() => { setLoading(false) }, 500);
        return()=>{unsubscribe();unsubscribe2();
        };
    },[id,stayid,activityId,user,activityDocRes])

    // add to wishlist 
    const handleAddToWishlist = (idd,stayidd,index) => {
        setLoading(true);
        addDocument({
          product_id:idd,
          user_id:user.uid,
          location:id,
          stay_type:stayidd, 
          wishlisted:1,
        })

      }

      //to push wishlisted data in array of a particular user on refresh
      useEffect(() => {
        if(documentsRes!==null){
            documentsRes.forEach((each)=>{
                if(user && user.uid===each.user_id){
                    wishlistUserId=user.uid;
                    wishlistProdId.push(each.product_id);
                }   
            })
        }
      }, [documentsRes,userWishlist,wishlistProdId,wishlistUserId,user])

      //to push added to cart data in array of a particular user on refresh
      useEffect(() => {
        if(activityDocRes!==null){
            activityDocRes.forEach((each)=>{
                if(user && user.uid===each.user_id && each.cartType==="activity"){
                    cartActivityUserId=user.uid;
                    cartProductId.push(each.activitId);
                }   
            })
        }
      }, [activityDocRes,cartProductId,user])

      useEffect(() => {
       
        

    // //console.log(wishlistProdId,"prod ids hai saare")
   
        if(user && stayid){
            let stayRef=projectFirestore.collection('location').doc(id).collection('stay').doc(stayid).collection(stayid);
            stayRef.onSnapshot((snapshot)=>{
                let resultsStayUser=[];
                snapshot.docs.forEach((doc)=>{
                    if(user.uid===wishlistUserId){
                        //to push users wishlisted data in new state
                        if(wishlistProdId.includes(doc.id)){
                           
                            resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:true})
                        }
                        else{
                            resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:false})

                        }
                     }
                     else{
                        resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:false})

                     }
                    
                })
                //update State
                setuserWishlist(resultsStayUser);
                // //console.log(userWishlist,"user")
                
            },(error)=>{
                setStayRes('could not fetch data')
            })

        }
      }, [documentsRes,id,response.document,stayid,user])

      useEffect(() => {
        if (response.success) {
            setLoading(false);
            NotificationManager.success('Added To Wishlist', 'Success');
        }
      }, [response.success])


      const handleRemoveFromWishlist = (id,index)=>{
       setLoading(true)
        let  prod_idd = id
        let ref = projectFirestore.collection('wishlist')
        documentsRes.forEach((doc)=>{
            if(user.uid===doc.user_id){
                if(wishlistProdId.includes(prod_idd)  && prod_idd===doc.product_id){
                    projectFirestore.collection('wishlist').doc(doc.id).delete().then(
                        ()=>{setLoading(false);
                        NotificationManager.success('Removed From Wishlist', 'Success');
                        });

                }

            }

        })
         
      }
    //   to close login modal popup
    const handleClose=()=>{
        setShowLoginModal(false)
    }

    

    // add activity to cart
    const handleActivityToCart = (activityId, activityPrice, activityDesc ,activityName,totalGuests,totalPrice,featuredImg)=>{
        firebase.firestore().collection('Cart').add({
            user_id:user.uid,
            activitId:activityId,
            featuredImg,
            activityPrice:activityPrice, 
            activityName:activityName,
            addedToCart:1,
            totalGuests:totalGuests,
            totalPrice:totalPrice,
            createdAt:timestamp.fromDate(new Date()),
            cartType:'activity',
          })
        //   setTimeout(() => { setLoading(false) }, 500);
          setActivityDetails(true);
          NotificationManager.success('Activity Added To Cart', 'Success');
    }

    const handleStayRes=(docId)=>{
        setStayId(docId);
        myRef.current?.scrollIntoView({ behavior: 'smooth' })
       
    }   
      return ( 

    <>
    <NotificationContainer/>
    <Modal/>
    { loading && <Loading loading={true} background="#000000c4" loaderColor="#ffe23f" />}
        { showLoginModal && <LoginModal handleClose={handleClose}/>}
        { !showLoginModal && <div>
            <div className="exploreClass d-flex justify-content-center align-items-start">
                <div className="searchDiv heroSectionSearch">
                    <div className="sectionTitle">
                        <h1 className="text-dark text-center fw-bold">Location</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark " style={{'width':'65%'}} />
                </div>
            </div>
            {/* adventrio location */}
                <div className="parentDiv locationParentDiv exploreLocParent bg-light">
                    <div className="stickyDiv">
                        <a data-bs-toggle="tooltip" title="Stay" href="#stay"><div className="stickyElement staySticky"><img src={bag} alt="" /></div></a>
                        <a data-bs-toggle="tooltip" title="Travel" href="#travel"><div className="stickyElement travelSticky"><img src={car} style={{'width':'82%'}} alt="" /></div></a>
                        <a data-bs-toggle="tooltip" title="Activity" href="#activities"><div className="stickyElement activitySticky"><img src={raft} alt="" /></div></a>
                    </div>
                    <div className="wrapperDiv">
                        <div className="mainDiv2 exploreMainDiv non_slicky">
                        {window.location.href.split("/").pop()==="" && <small>Please Select a Location</small>}
                        {window.location.href.split("/").pop()==="explore" && <small>Please Select a Location</small>}
                        {window.location.href==="https://adventrio.com/explore" && <small>Please Select a Location</small>}  
                        {documents &&
                            <Slider {...location}>
                                            {documents.map(doc=>(
                                                <div className = {doc.id===id? "activeLocation position-relative card":"card"} key={doc.id}>
                                                    <Link style={{'textDecoration':'none'}} to={`/explore/${doc.id}`}> 
                                                        <img src={doc.locationImg} alt={doc.id} />
                                                        <h4 className="text-dark">{doc.id}</h4>
                                                    </Link>
                                                    <div className={doc.id===id? "sShow sSelected":"position-absolute sSelected"}><FontAwesomeIcon icon="check-circle" /></div>
                                                    
                                                </div>
                                            ))}
                                            
                            </Slider>}
                        </div>
                    </div>
                    <div id='stay'></div>
                </div>
                
                {/* Adventrio stay */}
                       
                {id && <div className="mainParentDiv container-fluid exploreStay bg-dark"  >
                {response&& <div className="headingSection" >
                        <div className="sectionTitle">
                            <h1 className="fw-bold text-white">Stay</h1>
                        </div>
                        <hr className="sectionHr m-auto text-center text-white" />
                        <div className="row py-3">
                            
                        </div>
                    </div>}
                    {responsee && responsee.length===0  && <div><h3 className="text-yellow fw-bold text-center">No Stays Are Available</h3></div>}
                    {responsee && (<div className="row py-2 text-webkit-center stay-type-cards" ref={stayRef}>
                                {responsee.map(doc=>(
                                    <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mb-4" key={doc.id}>
                                        <div className={doc.id===stayid ? "active__stay stay__category card rounded-20 shadow" : "stay__category card rounded-20 border-0 shadow"} onClick={()=>handleStayRes(doc.id)}  >
                                            <img src={doc.stayImg} className="card-img-top" alt="..."/>
                                                <div className="card-body">
                                                    <h5 className="card-title mb-0 text-center text-dark fw-bold text-capitalize" >{doc.id}</h5>
                                                </div>
                                        </div>
                                    </div>
                                )
                                )}
        
                    </div> )}
                    <div className='mt-3 pt-3'></div>
                    {responsee && responsee.length!==0 && stayRes && stayRes.length===0 && <div><h3 className="text-yellow fw-bold text-center">Oops! No {stayid} available.</h3></div>}
                    {!user && stayRes && (  
                    <div className={stayRes && stayRes.length<3 ? ' mx-3 mx-md-4 p-2 forImg': " mx-3 mx-md-4 stayProducts p-2"} ref={myRef}>
                        {stayRes.map((doc,index)=>(
                        <div className="row g-0 rounded-20 shadow main_278_h mb-4" key={doc.id}>
                            <div className="col-lg-12 col-md-12 main_278_h ">
                                <div className="card  rounded-20 all_stay_cards " id= "stayProductCard"  >
                                    {/* <div className="row g-0 rounded-20 shadow "> */}
                                        <div className="col-md-3 imgCol" >
                                            <img src={doc.featuredImg} className="stayFeaturedImg img-fluid " alt="..." />
                                        </div>
                                        <div className="col-md-9 product__specifications px-3 align-self-center ">
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col-6 details__col">
                                                    <h2 className="card-title text-dark fw-bold text-start" >{doc.siteName}</h2>
                                                    <h5 className="card-text text-dark text-start mb-4" ><img style={{'width':'4%'}} src={userIcon} alt="..." /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small></h5>
                                                </div>
                                              { doc.stayType!=='container'&& <div className="col-6 price__col">
                                                    <h2 className="fw-bold text-end text-dark" >₹{doc.price} / night</h2>
                                                </div> }

                                                </div>
                                                
                                                <p className="card-text text-dark text-start description__details">
                                                    {doc.siteDesc.substr(0, 150)}....
                                                </p>
                                                
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-12">
                                                        <div className="card-text text-dark text-start d-flex flex-row flex-wrap justify-content-space-between mb-3">
                                                            {doc.amenities !==undefined && doc.amenities.map((amenity,index) => (
                                                                <div key={index}>
                                                                    <Tippy content={amenity}><div className="activityIcon" ><img style={{'width':'65%','cursor':'pointer'}} src={activityIcon1} alt="..." /></div></Tippy>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-12 text-end action__btns">
                                                        <Link to={`/details/${id}/${stayid}/${doc.id}`} target="_blank">
                                                        <button className="fw-bold advButton mx-3" > View More </button></Link>
                                                        {/* {!user && <button className="fw-bold advButton danger "  onClick={()=>setShowLoginModal(true)}>Add To Wishlist</button>} */}
                                                        {!user &&  <button type='button'  className="fw-bold advButton danger" data-toggle="modal" data-target="#loginSignupModal" >Add To Wishlist</button>}
        
                                                    {/* <button className= "fw-bold advButton danger d-none"  id={doc.id +"-remove"} onClick={()=>handleRemoveFromWishlist(doc.id,index)} >Remove from Wishlist</button>   */}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    
                                </div> 
                                {/* date availability slider */}
                                <div className="row justify-content-center stay__date__carousel bg-light mx-1 mx-md-2 px-0">
                                    <div className="col-sm-11 align-self-center">
                                        <div className="row justify-content-center dateAvailibilitySlider">
                                            <div className="col-sm-12 text-webkit-center">
                                                <Slider {...availableDates}>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 10</h5>
                                                        <p className="mb-0 text-success">₹549</p>
                                                        <p className="mb-0">3 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 11</h5>
                                                        <p className="mb-0 text-success">₹549</p>
                                                        <p className="mb-0">3 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 12</h5>
                                                        <p className="mb-0 text-success">₹539</p>
                                                        <p className="mb-0">1 Units</p>
                                                    </div>
                                                    <div className = "py-2 availableDates">
                                                        <h5 className="mb-0">May 21</h5>
                                                        <p className="mb-0 text-danger">₹2549</p>
                                                        <p className="mb-0">0 Units</p>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>))} 
                    </div>)}
                    {/* when user is logged in */}
                    {user && userWishlist && (  
                    <div className={stayRes && stayRes.length<3 ? 'mx-3 mx-md-4 p-2 forImg': " mx-3 mx-md-4 stayProducts p-2"} ref={myRef} >
                        {userWishlist.map((doc,index)=>(
                        <div className="row g-0 rounded-20 shadow main_278_h mb-4" key={doc.id}>
                            <div className="col-lg-12 col-md-12 main_278_h ">
                                <div className="card mb-3 rounded-20 " id= "stayProductCard"  >
                                    {/* <div className="row g-0 rounded-20 shadow "> */}
                                        <div className="col-md-3 imgCol" >
                                            <img src={doc.featuredImg} className="stayFeaturedImg img-fluid " alt="..." />
                                        </div>
                                        <div className="col-md-9 product__specifications px-3 align-self-center ">
                                            <div className="card-body">
                                            <div className="row">
                                                <div className="col-6 details__col">
                                                    <h2 className="card-title text-dark fw-bold text-start" >{doc.siteName}</h2>
                                                    <h5 className="card-text text-dark text-start mb-4" ><img style={{'width':'4%'}} src={userIcon} alt="..." /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small></h5>
                                                </div>
                                                {doc.stayType!=='container'&&<div className="col-6 price__col">
                                                    <h2 className="fw-bold text-end text-dark" >₹{doc.price} / night</h2>
                                                </div>} 

                                                </div>
                                                
                                                <p className="card-text text-dark text-start description__details">
                                                    {doc.siteDesc.substr(0, 150)}....
                                                </p>
                                                
                                                <div className="row">
                                                    <div className="col-xl-6 col-lg-12">
                                                        <div className="card-text text-dark text-start d-flex flex-row flex-wrap justify-content-space-between mb-3">
                                                            {doc.amenities !==undefined && doc.amenities.map((amenity,index) => (
                                                                <div key={index}>
                                                                    <Tippy content={amenity}><div className="activityIcon" ><img style={{'width':'65%','cursor':'pointer'}} src={activityIcon1} alt="..." /></div></Tippy>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6 col-lg-12 text-end action__btns">
                                                        <Link to={`/details/${id}/${stayid}/${doc.id}`} target="_blank">
                                                        <button className="fw-bold advButton mx-3"> View More </button></Link>
                                                        {/* {!user && <button className="fw-bold advButton danger "  onClick={()=>setShowLoginModal(true)}>Add To Wishlist</button>} */}
                                                        {!user &&  <button type='button'  className="fw-bold advButton danger" data-toggle="modal" data-target="#loginSignupModal" >Add To Wishlist</button>}
                                                        {!doc.wishlisted && <button className="fw-bold advButton danger" id={doc.id} onClick={()=>handleAddToWishlist(doc.id,stayid,index)}>Add To Wishlist</button> }
                                                        {doc.wishlisted &&<button className= "fw-bold advButton"  id={doc.id} onClick={()=>handleRemoveFromWishlist(doc.id,index)} >Remove from Wishlist</button>}
                                                    {/* <button className= "fw-bold advButton danger d-none"  id={doc.id +"-remove"} onClick={()=>handleRemoveFromWishlist(doc.id,index)} >Remove from Wishlist</button>   */}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    
                                </div> 
                            </div>
                        </div>))} 
                    </div>)}
                    <div id='activities'></div>

                </div>}

                {/* Adventrio activieties */}
                {id && <div className="parentDiv container-fluid activities bg-yellow " style={{'paddingBottom':'1rem','paddingTop':'1rem'}}>
                    <div className="headingSection">
                        <div className="sectionTitle">
                            <h1 className="text-dark">Activities</h1>
                        </div>
                        <hr className="sectionHr m-auto text-center text-dark" />
                    </div>
                    <div className="wrapperDiv" style={{'cursor':'pointer','paddingBottom':'2%'}}>
                        <div className="mainDiv3 non_slicky explore_activities">
                        {responsee && responsee.length===0  && <div><h3 className="text-dark fw-bold">No Activities Are Available</h3></div>}
                        { activityRes && (<Slider {...exploreActivities}>
                            {activityRes.map(doc=>(
                            <div className = {doc.id===activityId ? "card homeActivityCards locationCard1 active__activity" : "card homeActivityCards locationCard1"} key={doc.id} onClick={()=>setActivityId(doc.id)} >
                            <h4 style={{"textTransform":"capitalize"}}>{doc.id}</h4>
                           
                            <img src={doc.activityImg} alt="" />
                            </div>
            )
            )}
                                
                            </Slider>)} 
                        </div>
                    </div>
                </div>}
                {responsee && responsee.length!==0  && activityFinalRes && activityFinalRes.length===0 && <div><h3 className="text-center text-dark fw-bold py-5">Oops! No {activityId} available.</h3></div>}
                {activityFinalRes && activityFinalRes.length!==0 && <div className="container-fluid px-3 " style={{'overflow':'hidden'}}>
                    <div >
                        {activityFinalRes && (
                         <div className="row py-5 text-webkit-center ">
                            {activityFinalRes.map((doc)=>(
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                <div className="card all_activity_cards rounded-20 border-0 shadow mb-4">
                                    <img src={doc.featuredImg} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-dark fw-bold" style={{"textTransform":"capitalize"}}>{doc.activityName}</h5>
                                        { doc.price ===0 &&  <p className="card-text">Free</p>}
                                        {doc.price !== 0 && <p className="card-text">₹{doc.price} / person</p>}
                                        
                                        <div className="row justify-content-around justify-content-md-around">
                                            <div className="col-lg-6 col-md-8 col-8"><Link to={`/activityDetails/${id}/${activityId}/${doc.id}`} target="_blank"><button className="advButton">View More</button></Link></div>
                                            {user && !doc.addedToCart &&<div className="col-lg-6 align-self-center text-md-center text-sm-start col-md-8 col-4"><button onClick={()=> handleActivityToCart(doc.id,doc.price,doc.desc,doc.activityName,doc.totalGuests,doc.totalPrice,doc.featuredImg)} className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                            {doc.addedToCart && <div className="col-lg-6 col-md-8 col-8"><Link to={`/cart/`}><button className="advButton">Go to Cart</button></Link></div>}
                                            {/* {!user && <div className="col-lg-6 align-self-center col-md-8 col-4"><button onClick={()=> setShowLoginModal(true)} className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>} */}
                                            {!user &&  <div className="col-lg-6 align-self-center col-md-8 col-4"><button type='button'  className="border-0 outline-0 bg-light" data-toggle="modal" data-target="#loginSignupModal" ><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                        </div>)}
                    </div>
                </div>}
            </div>}
            <Footer/>
    </>


     );
}
export default Explore;