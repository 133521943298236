import {React,useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useSignup } from '../hooks/useSignup';
// import { useLogout} from '../hooks/useLogout'
import google from "../assets/google.svg";
import {projectAuth , provider}  from '../firebase/config.js';
import  firebase from 'firebase';
import { useAuthContext } from '../hooks/useAuthContext';
import { timestamp } from "../firebase/config";
import { useHistory } from 'react-router-dom';
import { projectFirestore } from '../firebase/config';
import { useCollection } from "../hooks/useCollection";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const Signup = (e) => {
    const [username,setUsername]=useState('');
    const {user}=useAuthContext()
    const [email,setEmail]=useState('');
    const[password,setPassword]=useState('');
    const{signup,signupwithgoogle}=useSignup();
    const [phone,setPhone]=useState('');
    const [showOtpField,setShowOtpField]=useState(false)
    const [otp,setotp]=useState('');
    const [final, setfinal] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null);
    const [userUid , setUserUid] = useState('') ; 
    const [alreadyUser , setAlreadyUser] = useState(false);
    const [numberBool , setNumberBool] = useState(false)
    const history = useHistory();
    const [contactExists , setContactExists] = useState(false)
    const [userCreated,setUserCreated]=useState(false)
    const [otpPending,setOtpPending]=useState(false)
    const {documents}=useCollection('users');
//     const { logout }=useLogout();

        const checkUser= () =>{
                //console.log('yse phone it is ',phone)
                let number = '+91'+phone;
                //console.log('my number  is ',Number(number))    
                let thiss = projectAuth.getUserByPhoneNumber(number)
                //console.log('this is the this ',thiss)
        }

    // check phone number in firebase

    const checkPhoneNumber = () =>{
        setOtpPending(true)
        let ref=projectFirestore.collection('users');
        let userNumber = 0
        let contactList=[];
        documents.forEach(each=>{
                contactList.push(each.phoneNumber);

        })
        //console.log(contactList,"list");
        if(contactList.includes(Number(phone))){
                //console.log('exists')
                setNumberBool(true)
                setPhone('')              
                setAlreadyUser(true)
                userNumber = phone 
                setContactExists(true)
                setOtpPending(false)
        }
        else{
                // //console.log('does not exists')
                setContactExists(false);
                let number = '+91'+phone;
                handlePhoneAuth(number);
        }

    }

        //Phone Authentication
        const handlePhoneAuth=(number)=>{
                // e.preventDefault();
                let verify = new firebase.auth.RecaptchaVerifier('recaptcha');
                projectAuth.signInWithPhoneNumber(number, verify).then((result) => {
                        // signup(email,username ,number);
                        setfinal(result);
                        setShowOtpField(true);
                        NotificationManager.success('OTP Sent', 'Success');
                        
                }).catch((err) => {
                        NotificationManager.error(err, 'Error!');
                });
                                
        }
        
            //OPT validation
        const ValidateOtp=()=>{
                if (otp === null || final === null)
                return;
            final.confirm(otp).then((result) => {
                setUserCreated(true);
                setError(null)
                setIsPending(true)
                setUserUid(result.user.uid);
                handleAddUser(email, username, phone, result.user.uid)
                result.user.updateProfile({displayName : username})
                // result.user.updateEmail(email)
                               
                setIsPending(false)
                if (!isCancelled) {
                        setIsPending(false)
                        setError(null)
                }
                
                // success
            }).then(()=>{
                
                NotificationManager.success('Account Created', 'Success');
        }).catch((err) => {
                NotificationManager.error('Incorrect OTP', 'Invalid');
            })
        }

        const handleAddUser  = (email, username, phone, uid) =>{
                //console.log('inside handle')
                firebase.firestore().collection('users').doc(uid).set({ 
                        //uid:uid, // this id will be the same with id generated in firebase authenticaiton 
                        email:email,
                        name:username,
                        phoneNumber: Number(phone),
                        createdAt:timestamp.fromDate(new Date()),
                }).then(()=>{
                        window.location.href="/home";
                        // history.goBack();
                })
                
        }
        useEffect(() => {
                if(user){
                 history.push('/home');
                }
             }, [user])

    return (
<div className="signUpParent regParent">
<NotificationContainer/>
{/* <button onClick={logout}>Logout</button> */}
        <div className="row">
                <div className="col-sm-12 col-md-1"></div>
                <div className="col-md-4 col-sm-12 mt-5em" >
                    <div className="blurbg card credentialCard border-0 shadow my-5">
                            <div className="card-body px-4 py-5 p-md-5">
                                <div className='text-center' style={{'marginBottom':'1.5em'}}>
                                        <h2 className="card-title text-center text-dark fw-bold my-0">Get Started</h2>
                                        <small className='text-center'>Already Have Account ? <Link rel="stylesheet" to="/login" className="text-black" ><strong>Login</strong></Link></small>
                                </div>
                                        <form >
                                                <div className="form-floating mb-3">
                                                        <span className='text-dark fw-bold'><strong>Name</strong></span>
                                                        <input required type="text" 
                                                        onChange={(e)=>setUsername(e.target.value)}
                                                        value={username}
                                                        className="form-control text-dark " style={{"borderColor":"black"}} id="input" placeholder="Your name" />
                                                </div>
                                                <div className="form-floating mb-3">
                                                        <span className='text-dark fw-bold'><strong>Email address</strong></span>
                                                        <input required type="email" 
                                                        onChange={(e)=>setEmail(e.target.value)}
                                                        value={email}
                                                        className="form-control text-dark " style={{"borderColor":"black"}} id="floatingInput" placeholder="name@example.com" />
                                                </div>
                                                {!showOtpField &&  
                                                <div className="form-floating ">
                                                                <span className='text-dark fw-bold'><strong><small>Phone Number</small></strong></span>
                                                                <input required type="number" 
                                                                onChange={e=>setPhone(e.target.value)} value={phone}
                                                                className=" form-control text-dark " style={{"borderColor":"black"}} id="floatingInput" />
                                                </div>
                                                }
                                                {contactExists && <small className='text-danger fw-bold'>This contact already exists!</small>} 
                                                {showOtpField && 
                                                        <div>
                                                                <span className='text-dark'><strong><small>Enter your OTP</small></strong></span>
                                                                <input className="form-control" style={{"borderColor":"black"}} type="text" 
                                                                onChange={(e) => { setotp(e.target.value) }}></input>
                                                                        <br /><br />
                                                        </div>
                                                }
                                            {/* {isPending  && <div className="text-center  signUpBtn">
                                                    <button  className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type="submit">Sign up</button>
                                            </div>} */}
                                                {/* 
                                            {!isPending && user && <div className="text-center  signUpBtn">
                                                    <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type="submit" disabled>Loading...</button>
                                            </div>} */}
                                            {error && <p className="text-danger">{error}</p>}
                                        </form>
                                        {otpPending && !showOtpField && <div className="text-center  signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold disabled">Generating OTP...</button>
                                                        </div>}
                                        {!showOtpField  && <div className="text-center signUpBtn">
                                        {!otpPending && <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" onClick={checkPhoneNumber}>Generate OTP</button>}
                                                { !alreadyUser &&  <div id="recaptcha" style={{textAlign:'center'}}></div>} 
                                        </div>}
                                        {showOtpField && !isPending && <div className="text-center signUpBtn">
                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" onClick={ValidateOtp}>Verify</button>
                                            </div>}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4"></div>
        </div>
  </div>
    )
}

export default Signup;
