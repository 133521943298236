import React from 'react';
import {Link} from 'react-router-dom';
import error from "../assets/error2.svg"
const pageNotFound = () => {
    return (
        <div className="pt-5 mt-5 notice-board">
            <div className="row">

                <div className="col-sm-12 text-center h-100">
                    <img src={error} className="text-center" alt="" />
                    <div style={{'marginTop':'-50px'}}>
                        <h1 className='fw-bold'>404 Error</h1>
                        <h3 className='mb-4'>Page Not Found!</h3>
                    </div>
                    <Link to="/explore"> <button className= "fw-bold advButton">Explore More</button></Link>
                </div>

            </div>

        </div>
        
    );
}

export default pageNotFound;
