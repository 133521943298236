import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyASCiABjsB32tJVcrQShugiV110J2cAL_Q",
  authDomain: "adventrio-4b76b.firebaseapp.com",
  projectId: "adventrio-4b76b",
  storageBucket: "adventrio-4b76b.appspot.com",
  messagingSenderId: "33260569715",
  appId: "1:33260569715:web:68c0d83d3d4d5651f2d606",
  measurementId: "G-B7MT2ZV8Y2"
};

  //initialize firebase

  firebase.initializeApp(firebaseConfig);

  //initialize firestore

  const projectFirestore=firebase.firestore();
  const projectAuth=firebase.auth();
  const projectStorage=firebase.storage();
  //Timestamp
  var provider = new firebase.auth.GoogleAuthProvider(); 
  const timestamp=firebase.firestore.Timestamp;

  export { projectFirestore,projectAuth,timestamp,projectStorage,provider}