import "./cart.css";
import "./explore.css";
import Slider from "react-slick";
import cartScroll from "../assets/cartScroll.svg";
import React,{ useState,useEffect } from 'react'
import userIcon from "../assets/userIcon.svg";
import activity1 from "../assets/waterfall.jpg";
import stayImg1 from "../assets/waterfall.jpg";
import activityIcon1 from "../assets/activityParachute.svg";
import right_arrow_black from "../assets/rightArrowBlack.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import { projectFirestore } from '../firebase/config';
import Loading from "react-fullscreen-loading";
import { useAuthContext } from '../hooks/useAuthContext';
import { useFirestore } from "../hooks/useFirestore";
import { useCollection } from '../hooks/useCollection'
import {Link,useHistory} from 'react-router-dom';
import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css"; 
import { isValid , addDays} from 'date-fns';
import Footer from "../components/Footer";
import ProductPopup from './ProductPopup'
import CouponComp from "../components/CouponComp";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CartPopup from "./CartPopup"
library.add( faTrash)


const Cart = () => {
    const [quantity,setQuantity]=useState(1);
    const {documents,errors}=useCollection('coupon')
    const [scrollPosition,setScrollPosition]=useState('');
    const[activityResult,setActivityresult]=useState(null);
    const[prodRes,setProdRes]=useState(null)
    const {user}=useAuthContext()
    const [grandTotal,setgrandTotal]=useState(0)
    const [loading , setLoading] = useState(false);
    const [showError,setShowError]=useState(null);
    const [cartResult, setCartResult] = useState(null);
    const [reload,setReload]=useState(false)
    let price=0;
    const [indexState,setIndex]=useState(null)
    const {deleteDocument,response} = useFirestore('Cart')
    const [guests,setGuests]=useState(0)
    const [actResult,setActResult]=useState(null);
    const { addDocument,response:addedSuccessfullyRes } = useFirestore('orders');
    const orderDetails=[]
    const [allCoupons,setAllCoupons]=useState(false) 
    const [couponVal,setCouponVal]=useState('');
    const [changer,setChanger]=useState();
    const [counter,setCounter]=useState(0)
    const [couponError,setCouponError]=useState(false);
    const [couponPrice,setCouponPrice]=useState(0);
    const history=useHistory(); 
    const [stayLength,setStayLength]=useState(0);
    const [actLength,setActLength]=useState(0);
    let count;
    const [popupData,setPopupData]=useState(null);
    const [clickedData,setClickedData]=useState(null);
    const [clickedProductId,setClickedProductId]=useState('');
    const [productDetail,setProductDetail]=useState(null);
    let finalCost=0;
    let allOrder=[];
    const [mergedArr,setmergedArr]=useState(null);
    
    const handleClick=(data)=>{
        setClickedData(data);
        setClickedProductId(data.id);
    }
    if(user){
        ////console.log("activityres",activityResult)
        var options = {
            "key": "rzp_test_6gcvEmraja9tMY", // Enter the Key ID generated from the Dashboard
            "amount":(grandTotal+19-couponPrice)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Adventrio Escapes",
            "description": "Test Transaction",
            "image": "https://example.com/your_logo",
            // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (responseRzr){
                // alert(response.razorpay_payment_id);
                addDocument({
                    email:user.email,
                    name:user.displayName,
                    paymentId:responseRzr.razorpay_payment_id,
                    ProductOrderDetails:activityResult,
                    ActivityOrderDetails:actResult,
                    // productId:productId,
                    //  uid:user.uid,
                    // numberOfNights:nights,
                    // checkInDate:checkIn,
                    // checkOutDate:checkOut,
                    // totalPeople:checkOutQuantity,
                    // location:locationId,
                    // stayCategory:stayid,
                    grandTotal:grandTotal+19-couponPrice
                  })
                  ////console.log(response,"add response")
                 
                  
                //   window.location.href = '/';
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)
    
               
            },
            "prefill": {
                "name": user.displayName  ,
                "email": "admin@adventrio.com",
                "contact": user.phoneNumber
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
          };
          var rzp1 = new window.Razorpay(options);
              rzp1.on('payment.failed', function (response){
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
          });
        }
    useEffect(()=>{
        //console.log(addedSuccessfullyRes,'suc')
        if(addedSuccessfullyRes.success){
            let recipient =+919765729392;
            let message="Congrats! "+user.displayName+", your order has been placed successfully";
            fetch(`http://localhost:4000/send-text?recipient=${recipient}&textmessage=${message}`);
            NotificationManager.success('Order Placed!', 'Success');
            history.push("/order-confirmed");
            allOrder=[...activityResult,...actResult];
            allOrder.forEach((eachdoc)=>{
                deleteDocument(eachdoc.id)  
            })

          }
    },[addedSuccessfullyRes])

    const availableDates = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        adaptiveHeight: true,
        slidesToScroll:1,
        arrows:true,
        centerPadding: '25%',
        centerMode:true
      }; 
      const handleActivityDec=(totalGuests,price,CartId,totalPrice)=>{
        if(totalGuests>1){
            projectFirestore.collection('Cart').doc(CartId).update({totalGuests:totalGuests-1,totalPrice:totalPrice-price}); 


        }
       
           

      }
      const handleActivityInc=(totalGuests,price,CartId,totalPrice)=>{
        projectFirestore.collection('Cart').doc(CartId).update({totalGuests:totalGuests+1,totalPrice:totalPrice+price}); 
    }
      const handleCheckOut=(date,CartId,checkIn,checkOut,cost,totalGuests)=>{
        let date1=new Date(checkIn);
        let date2=date;
        let nights=parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        ////console.log("nigh",nights)
        finalCost=(cost*nights)*totalGuests;
        projectFirestore.collection('Cart').doc(CartId).update({checkOutDate:String(date2),totalNights:nights,totalPrice:finalCost});

      }
      const handleCheckIn=(date,CartId,checkIn,checkOut,cost,totalGuests)=>{
        let date1=date;
        let date2=new Date(checkOut);
        ////console.log(typeof date1)
        ////console.log(typeof date2)
        let nights=parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
        finalCost=(cost*nights)*totalGuests;
        projectFirestore.collection('Cart').doc(CartId).update({checkInDate:String(date1),totalNights:nights,totalPrice:finalCost});

      }
      const handleDecrement=(quantity,index,CartId,nights,cost,totalGuests)=>{
        let newQuantity;
        // ////console.log(quantity)
        if(quantity===1){
            newQuantity=quantity-1;
        }
        else{
            newQuantity=quantity-1;
             finalCost=(cost*nights)*(totalGuests-1);
            projectFirestore.collection('Cart').doc(CartId).update({totalGuests:quantity-1,totalPrice:finalCost});
    
        }
        
     }
     const handleIncrement=(quantity,index,CartId,nights,cost,totalGuests)=>{
        finalCost=(cost*nights)*(totalGuests+1);
        projectFirestore.collection('Cart').doc(CartId).update({totalGuests:quantity+1,totalPrice:finalCost});

        // let newQuantity=quantity+1;
        // setQuantity(newQuantity)
    }
const handleBuy=()=>{
    rzp1.open();
}
useEffect(() => {
    if(price>0){
    handleBuy();
    
}
    
 }, [grandTotal])
    useEffect(() => {
        let ref=projectFirestore.collection('Cart');
        const  unsubscribe= ref.onSnapshot((snapshot)=>{
            let resultsActivty=[ ];
            let resultsAct=[]
            let price=0;
            let actPrice=0;
            snapshot.docs.forEach((doc)=>{
                if(user && doc.data().user_id===user.uid){
                    if(doc.data().cartType==="stay"){
                    resultsActivty.push({...doc.data(),id:doc.id});
                    price=price+Number(doc.data().totalPrice);
                }else{
                    resultsAct.push({...doc.data(),id:doc.id});
                    actPrice=actPrice+Number(doc.data().totalPrice);

                }

                }
            })
            setStayLength(resultsActivty.length)
            setActivityresult(resultsActivty);
            setActLength(resultsAct.length)
            setActResult(resultsAct);
            setmergedArr([...resultsAct,...resultsActivty]);
            setgrandTotal(price+actPrice+19)
            ////console.log(price)
            
                
        })
        
        return () => {unsubscribe();
    };
    }, [user,guests,finalCost])

    // this code was to check the payment section scroll position and making it sticky
    // const handleScroll = (scrollPosition) => {
    //     const paySection = document.querySelector(".payment__process");
    //     const position = paySection.offsetTop;
    //     const scroll_positon = window.pageYOffset
    //     ////console.log('this is the position we want to check ', position)
    //     ////console.log('this is the position we want to check2 ', scroll_positon)
    //     if(scroll_positon >= position){
    //         setScrollPosition(1)
    //         ////console.log('true that')
    //     }else{
    //       ////console.log('false that!')
    //     }
    //   };
      
    //   useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
      
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);
    useEffect(() => {
        count=0;
        setCouponError(false);
        setCounter(0)
        ////console.log("initial",counter)
       
       if(documents){ documents.forEach(ele => {
            if(ele.couponName===couponVal){
                count=count+1;
                setCouponError(false);
                ////console.log('')
                setCounter(1);
                setCouponPrice(ele.couponPrice)    
            }
       }
    
       );}
    
       if(count===0){
        setCouponPrice(0)
        setCouponError(true)
        ////console.log("Invalid");
       }
    }, [changer])
    const handleCoupon=(e)=>{
        count=0;
        e.preventDefault();
        setCouponError(false);
        setCounter(0)
        ////console.log("initial",counter)
        documents.forEach(ele => {
            if(ele.couponName===couponVal){
                count=count+1;
                setCouponError(false);
                ////console.log('')
                setCounter(1);
                setCouponPrice(ele.couponPrice)    
               
            }
       }
       );
       if(count===0){
        setCouponPrice(0)
        setCouponError(true)
        ////console.log("Invalid");
       }
    
    }
    const handleCouponOfList=(couponName)=>{
        setCouponVal(couponName);
        setChanger(couponVal)
        setAllCoupons(false) // to close the coupon list box after applying the coupon
    }
    const handleAllCouponToggle=()=>{
        if(allCoupons){
            setAllCoupons(false)
        }
        else{
            setAllCoupons(true)
        }
    }
    const handleCouponChange=(e)=>{
        setCouponVal(e.target.value)
    
    } 
   
    
    return ( 
    <>
    {/* { <ProductPopup doc={clickedData} />} */}
    {clickedData && <CartPopup  doc={clickedData} />}
        <NotificationContainer/>
            { loading && <Loading loading={true} background="#000000c4" loaderColor="#ffe23f" />}
                <div>
                <div className="exploreClass d-flex justify-content-center align-items-start">
                    <div className="searchDiv heroSectionSearch mb-3">
                        <div className="sectionTitle">
                            <h1 className="text-dark text-center fw-bold">Cart <img src={cartScroll} alt="" /> </h1>
                        </div>
                        <hr className="sectionHr m-auto text-center text-dark "  />
                    </div>
                </div>
                { grandTotal===0 && !user &&
                <div className="p-5 text-center">
                    <h2 className="text-center text-yellow fw-bold pb-3"> Please Login To Access The Cart! </h2>
                    <Link to= {`/login`}><button className="advButton">Login</button></Link>
                </div>}
                { grandTotal===0 && user && actResult && actResult.length===0  &&
                    <div className="p-5 text-center">
                        <h2 className="text-center text-yellow fw-bold pb-3"> There Is Nothing In Cart Please Explore! </h2>
                        <Link to= {`/explore`}><button className="advButton">Explore</button></Link>
                    </div>  
                }
    
            <div className="row_margin_stay">
                {activityResult && (
                <div className="mainDiv container-fluid" style={{'paddingBottom':'20px'}}>
                    
                    <div className="mx-3 cartProducts" >
                        <div className="row g-0 rounded-20  main_278_h " >
                            <div className="col-lg-9 col-md-9 main_278_h ">
                            {activityResult.map((doc,index)=>(
                                <div className="card shadow mb-4  rounded-20 border-0" id= 'stayProductCard' key={doc.id}>
                                    <div className="col-md-3 imgCol" >
                                        <img src={doc.featuredImg} className="stayFeaturedImg img-fluid w-100 h-100" alt="..."/>
                                    </div>
                                    <div className="col-md-9 px-3 align-self-center product__specifications py-3">
                                        <div className="card-body p-0 cart__card">
                                            <div className="row">
                                                <div className="col-md-12 col-lg-12 col-xl-12 details__col price__col">
                                                    <h2 className="card-title text-dark fw-bold text-start position-relative">{doc.siteName}
                                                    <div onClick={()=> deleteDocument(doc.id)} >
                                                        <FontAwesomeIcon className="trash-icon cart-trash" icon="fa-trash" />
                                                    </div>
                                                    </h2> 
                                                    <h5 className="user-icon card-text text-dark text-start ">
                                                        <img className="" src={userIcon} alt="" /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small>
                                                        <p>{doc.cartId}</p>
                                                    </h5>
                                                    <div className="row mb-2">
                                                        <div className="col-sm-6 price__col">
                                                            <div className="price">
                                                                <h5 className="fw-bold text-start text-dark">Total Price: ₹{doc.totalPrice}</h5> 
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 details__col text-start text-lg-end text-xl-end text-md-end text-sm-end">
                                                            <div className="total-nights">
                                                                <div><small>({doc.cost}/night)</small></div>
                                                                <strong>Total Nights-</strong> <span>{doc.totalNights}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="card-text text-dark text-start cart-desc mb-0 d-md-none d-lg-block">
                                                        {doc.description.substr(0,100)}...
                                                    </p>
                                                    <div className="row align-items-center">
                                                        <div className="col-lg-6 text-start">
                                                            {/* <p className="text-start mx-3 my-1"><small ><strong>Quantity</strong></small></p> */}
                                                            {doc.stayCategory!=="container"&&<div className="qty text-dark">
                                                                <button onClick={()=>handleDecrement(doc.totalGuests,index,doc.id,doc.totalNights,doc.cost,doc.totalGuests)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                                                <input  className="couter-input rounded-20 outline-0 text-center" type="number" name="" id="" value={doc.totalGuests} /> 
                                                                <button onClick={()=>handleIncrement(doc.totalGuests,index,doc.id,doc.totalNights,doc.cost,doc.totalGuests)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                                            </div>}

                                                            {/* Guest Section */}
                                                            
                                                            {doc.stayCategory==="container" && <div className="row" data-target={'#productModal'+doc.id} data-toggle="modal" onClick={()=>handleClick(doc)}>
                                                                <div className="col-lg-12">
                                                                    {doc.stayCategory==="container" && 
                                                                    <p type='button'  className="fw-bold mx-2 text-start mb-0" >Guests</p>}
                                                                    <div className='mx-2'>
                                                                        {doc.containerData &&
                                                                        <small>
                                                                            <span>{doc.containerData.adults} Adult(s)</span>
                                                                            <span> {doc.containerData.children} Children</span>
                                                                            <span> {doc.containerData.rooms} Room(s) </span> 
                                                                            <span> {doc.containerData.totalMatress >=0?doc.containerData.totalMatress:0} Matress(s)</span>
                                                                        </small>
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-2 text-end" >
                                                                    <FontAwesomeIcon style={{'fontSize':'22px'}} icon="fa-angle-down fa-angle-down" />
                                                                </div>
                                                             </div>}
                                                        </div>
                                                        
                                                       



                                                        {/* <ProductPopup doc={doc.containerData} /> */}

                                                        <div className="col-lg-6 d-flex flex-lg-row flex-xl-row flex-md-row flex-sm-row flex-column justify-content-lg-end justify-content-sm-start mt-sm-3 mt-3 mt-xl-0  mt-lg-0">
                                                            <div className="checkInDates mx-2 position-relative">
                                                                <small><strong>Check In</strong></small>
                                                                <div>
                                                                <DatePicker selected={new Date(doc.checkInDate)} onKeyDown={e => e.preventDefault()} onChange={(date)=>handleCheckIn(date,doc.id,new Date(doc.checkInDate),doc.checkOutDate,doc.cost,doc.totalGuests)} dateFormat="dd/MM/yyyy"     />  
                                                                    {/* <input value={doc.checkInDate} onChange={(e)=>handleCheckIn(e,doc.id,doc.checkInDate,doc.checkOutDate,doc.cost,doc.totalGuests)} className='dateInput bg-light' type="date" name="" id="checkedInDate" title="2022" /> */}
                                                                </div>
                                                            </div>
                                                            <div className="checkOutDates mx-2 position-relative">
                                                                <small><strong>Check Out</strong></small>
                                                                <div>
                                                                <DatePicker selected={new Date(doc.checkOutDate)} onKeyDown={e => e.preventDefault()} onChange={(e)=>handleCheckOut(e,doc.id,doc.checkInDate,doc.checkOutDate,doc.cost,doc.totalGuests)} dateFormat="dd/MM/yyyy"     />  
                                                                    {/* <input value={doc.checkOutDate}  onChange={(e)=>handleCheckOut(e,doc.id,doc.checkInDate,doc.checkOutDate,doc.cost,doc.totalGuests)} className='dateInput bg-light' type="date" name="" id="checkedOutDate" title="2022" /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}
                                {actResult &&
                                <div className="row pb-2 text-webkit-center ">
                                    {actResult.map((doc)=>(
                                    <div className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-sm-12 mb-3 px-0">
                                        <div className="card mb-4 all_activity_cards  border-0 shadow">
                                            <img src={doc.featuredImg} className="card-img-top" alt="..."/>
                                            <div onClick={()=> deleteDocument(doc.id)} >
                                                <FontAwesomeIcon style={{'color':'#ffe23f'}} className="trash-icon cart-trash" icon="fa-trash" />
                                            </div>
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-dark fw-bold">{doc.activityName}</h5>
                                                {doc.totalPrice===0 && <p className="card-text">Free</p>}
                                                { doc.totalPrice>0 && <p className="card-text">₹ {doc.totalPrice} / person</p>}
                                                <div className="qty text-end text-dark text-center">
                                                    <button onClick={()=>handleActivityDec(doc.totalGuests,doc.activityPrice,doc.id,doc.totalPrice)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                                    <input className="w-25 rounded-20 outline-0 text-center" value={doc.totalGuests}  type="number" name="" id="" /> 
                                                    <button onClick={()=>handleActivityInc(doc.totalGuests,doc.activityPrice,doc.id,doc.totalPrice)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                                </div>
                                }
                            </div>
                            { mergedArr && user && mergedArr.length===0 && 
                                <div className="pb-5 px-5 text-center">
                                    <h2 className="text-center text-yellow fw-bold pb-3"> Your Cart is Empty! </h2>
                                    <Link to= {`/explore`}><button className="advButton">Explore</button></Link>
                                </div>  
                            }
                            {mergedArr && user && mergedArr.length>0 && <div className="col-lg-3 col-md-3 px-4 py-3 cartPriceCard">
                                {grandTotal!==0 && <div className="container-fluid ">
                                    <div className="row">
                                        <div className="col-sm-12 shadow py-md-3 pt-3 cart_card">
                                            <div className="row">
                                                <div className="col-6 col-lg-12 col-md-12 col-xl-12">
                                                    <div className=" mb-0 cart_card_cost d-flex justify-content-between">
                                                        <span>Total Items({stayLength+actLength}) </span>
                                                        <span>₹ {grandTotal}</span>
                                                    </div>
                                                    <div className="d-flex justify-content-between mb-0 cart_card_cost">
                                                        <span>Other Taxes</span>
                                                        <span>+₹ {19}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-lg-12 col-md-12 col-xl-12">
                                                {couponPrice!==0 &&<div className="d-flex justify-content-between mb-0 cart_card_cost">
                                                        <span>Coupon</span>
                                                        <span>-₹ {couponPrice}</span>
                                                    </div>}
                                                    <div className="d-flex justify-content-between fw-bold mb-0 cart_card_cost">
                                                        <span>Total Payable</span> 
                                                        <span>₹ {(grandTotal-couponPrice)+19}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="fw-bold mb-0 cart_card_cost">
                                                Total Payable- ₹ {grandTotal-couponPrice}
                                            </div> */}
                                            {/* <small>5 tickets</small> */}
                                            <CouponComp handleCouponOfList={handleCouponOfList} handleCoupon={handleCoupon}  handleCouponChange={handleCouponChange} couponVal={couponVal} couponError={couponError} couponPrice={couponPrice} handleAllCouponToggle={handleAllCouponToggle} allCoupons={allCoupons}/>
                                            <button onClick={handleBuy} className="my-3 mt-1 rounded-20 p-2 bg-transperant border-0 bg-yellow w-100 ">
                                                Proceed To Pay   
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                        </div>  
                    </div>
                </div>)}

                
                 <div className="container-fluid mainDiv">
                    
                    <div className=" cartProducts" >
                    
                    </div>
                </div>
            </div>
                {/* {grandTotal!==0 &&
                <div className="container-fluid payment__process explore parentDiv bg-yellow py-4">
                    
                    <div className="row mx-3">
                        <div className="col-md-6 align-self-center  text-dark text-center text-md-start text-lg-start text-xl-start text-xxl-start">
                            <h4 className="fw-bold mb-0">Total Amount - ₹ {grandTotal}</h4>
                        </div>
                      
                        <div className="col-md-6 text-center text-md-end text-lg-end text-xl-end text-xxl-end">
                            <button onClick={handleBuy} className=" bg-transperant border-0 bg-yellow "><h4 className="fw-bold mb-0 ">Proceed To Pay  <img src={right_arrow_black} alt="" /> </h4></button>
                        </div>
                    </div>
                </div>} */}
                
                </div>

                <div className="footerCart">
                    <Footer/>   
                </div>
    </>

     );
}
 
export default Cart;