import "./cart.css";
import "./explore.css";
import Slider from "react-slick";
import cartScroll from "../assets/cartScroll.svg";
import activity1 from "../assets/activity1.png";
import stayImg1 from "../assets/waterfall.jpg";
import activityIcon1 from "../assets/activityParachute.svg";
import right_arrow_black from "../assets/rightArrowBlack.svg";
import { useCollection } from "../hooks/useCollection";
import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';
import { Link, useParams } from "react-router-dom";
import { useAuthContext } from '../hooks/useAuthContext';
import UserIcon from "../assets/userIcon.svg";
import Loading from "react-fullscreen-loading";
import { useStayCollection } from "../hooks/useStayCollection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Footer from "../components/Footer";
library.add( faTrash)



const Wishlist = () => {
    const {user}=useAuthContext()
    const {id}=useParams();
    const [responsee,setResponsee]=useState(null);
    const [showError,setShowError]=useState(null);
    const {documentsRes}=useStayCollection('wishlist');
    let  prod_idd;
    const [loading , setLoading] = useState(false);
if(user){
    // //console.log(responsee.length)
    //console.log(user.uid,"hi")
}

    useEffect(() => {
        setLoading(true)
        let ref=projectFirestore.collection('wishlist');
        ref.onSnapshot((snapshot)=>{
            let resultWishlists = [ ]
            
            snapshot.docs.forEach((doc)=>{
                resultWishlists.push({...doc.data(),id:doc.id})
            })
            let finalResult=[ ];
            resultWishlists.forEach((each)=>{
                let ref2=projectFirestore.collection('location').doc(each.location).collection('stay').doc(each.stay_type).collection(each.stay_type);
                ref2.onSnapshot(snapshot=>{
                    snapshot.docs.forEach((doc)=>{
                    if(user && user.uid===each.user_id){
                        if(doc.id===each.product_id){
                            
                            finalResult.push({...doc.data(),id:doc.id,stayType:each.stay_type,location:each.location})
                     }
                    }
                    })
                    setResponsee(finalResult)

                })
            })
           
        },(error)=>{
            //console.log('could not fetch data')
            setResponsee(error)
        })
        setTimeout(() => { setLoading(false) }, 500);

      return () => {
        
      }
    }, [user])
    
    const handleRemoveFromWishlist = (id)=>{
        setLoading(true)
        let  prod_idd = id
        let ref = projectFirestore.collection('wishlist')
        documentsRes.forEach((doc)=>{
            if(user.uid===doc.user_id){
                if(prod_idd===doc.product_id){
                    projectFirestore.collection('wishlist').doc(doc.id).delete().then(()=>setLoading(false));

                }

            }

        })
      }
      //console.log('hey response ',responsee)
    return ( 
    <>
        { loading && <Loading loading={true} background="#000000c4" loaderColor="#ffe23f" />}
        <div className="exploreClass d-flex justify-content-center align-items-start mb-5">
            <div className="searchDiv heroSectionSearch">
                <div className="sectionTitle">
                    <h2 className="text-dark text-center fw-bold">My Wishlist </h2>
                </div>
                <hr className="sectionHr m-auto text-center text-dark "  />
            </div>
        </div>


        { responsee  && 
        <div className="mainDiv container-fluid pb-5">
            <div className="row" >
                {responsee.map((doc,index)=>(
                    <div className="col-sm-6" key={doc.id}>
                            <div className="mx-4 cartProducts" >
                                <div className="card mb-5 rounded-20 border-0" >
                                    <div className="row g-0 rounded-20 shadow ">
                                            <div className="col-xl-4 imgCol wishlistImgCol" >
                                                <Link className="wishlistHovering" to = {`/details/${doc.location}/${doc.stayType}/${doc.id}`}>
                                                    <img src={doc.featuredImg} className="stayFeaturedImg img-fluid h-100" alt="..."/>
                                                </Link>
                                            </div>
                                        <div className="col-xl-8 px-3 align-self-center position-relative">
                                            <FontAwesomeIcon onClick={()=>handleRemoveFromWishlist(doc.id)} className="trash-icon" icon="fa-trash" />
                                            <Link className="wishlistHovering" to = {`/details/${doc.location}/${doc.stayType}/${doc.id}`}>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-12 col-lg-12 col-xl-12 position-relative">
                                                            <h4 className="card-title text-dark fw-bold text-start">{doc.siteName}</h4> 
                                                            <h6 className="card-text text-dark text-start mb-4"><img style={{'width':'6%'}}  src={UserIcon} alt="" /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small></h6>
                                                            <p className="card-text text-dark text-start wishlist__desc">
                                                                {doc.siteDesc.substr(0,150)}...
                                                            </p>
                                                            <div className="price"><h6 className="fw-bold text-start text-dark">₹ {doc.price}/Night</h6></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                    </div>
                ))}
            </div>
        </div>}

        { !user && responsee && responsee.length===0  &&
        <div className="pb-5 px-5 text-center">
            <h2 className="text-center text-yellow fw-bold pb-3"> Please Login To Access The Wishlist! </h2>
            <Link to= {`/login`}><button className="advButton">Login</button></Link>
        </div>}
        { user && responsee && responsee.length===0  && 
            <div className="pb-5 px-5 text-center">
                <h2 className="text-center text-yellow fw-bold pb-3"> There Is Nothing In Wishlist Please Explore! </h2>
                <Link to= {`/explore`}><button className="advButton">Explore</button></Link>
            </div>  
        }

        {/* <div className="container-fluid explore parentDiv">
            <div className="row pb-5 text-webkit-center">
                <div className="col-md-3 col-sm-12">
                    <div className="card rounded-20 border-0 shadow" style={{'width': '18rem'}}>
                        <img src={activity1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title text-center text-dark fw-bold">Paragliding</h5>
                            <p className="card-text">₹549 / person</p>
                            <div className="qty text-end text-dark text-center">
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" /> 
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div className="card rounded-20 border-0 shadow" style={{'width': '18rem'}}>
                        <img src={activity1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title text-center text-dark fw-bold">Paragliding</h5>
                            <p className="card-text">₹549 / person</p>
                            <div className="qty text-end text-dark text-center">
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" /> 
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div className="card rounded-20 border-0 shadow" style={{'width': '18rem'}}>
                        <img src={activity1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title text-center text-dark fw-bold">Paragliding</h5>
                            <p className="card-text">₹549 / person</p>
                            <div className="qty text-end text-dark text-center">
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" /> 
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-sm-12">
                    <div className="card rounded-20 border-0 shadow" style={{'width': '18rem'}}>
                        <img src={activity1} className="card-img-top" alt="..."/>
                        <div className="card-body">
                            <h5 className="card-title text-center text-dark fw-bold">Paragliding</h5>
                            <p className="card-text">₹549 / person</p>
                            <div className="qty text-end text-dark text-center">
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" /> 
                                <button className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid explore parentDiv bg-yellow py-4">
            <div className="row mx-3">
                <div className="col-md-6  text-dark">
                    <h4 className="fw-bold mb-0">Total Amount - ₹ 34567</h4>
                    <small>5 tickets</small>
                </div>
                <div className="col-md-6 text-end">
                    <button className=" bg-transperant border-0 bg-yellow "><h4 className="fw-bold mb-0 ">Proceed To Pay  <img src={right_arrow_black} alt="" /> </h4></button>
                </div>
            </div>
        </div> */}
        

        <Footer/>
    </>

     );
}
 
export default Wishlist;