import React,{ useRef,useEffect,useState } from 'react'
const url= ('http://uat-calibrill.com/adventrio/twilio.json')
const header = new Headers({ "Access-Control-Allow-Origin": "*" });

 

const Test2 = () => {
    useEffect(() => {
        //console.log('useEffect function ran')
        fetch("http://uat-calibrill.com/adventrio/twilio.json",{
            mode: 'no-cors',
            method: "POST",
            headers: {
                 "Content-Type": "application/json",
                 "Access-Control-Allow-Origin" : "", 
                "Access-Control-Allow-Credentials" : true ,
                "Accept": "application/json",
                "Access-Control-Allow-Headers":"X-Requested-With"
            }
        })
          .then(response => response.json())
          .then(json => console.log("this is data",json))
      }, [url])
    return ( 
        <div style={{"marginTop":"20%"}}>
            hellooo
        </div>
     );
}
 
export default Test2;