import {React,useState} from 'react';
import LoginComponent from "../Login/LoginComponent";
import SignupComponent from "../signup/SignupComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
library.add( faTimes)


const Modal = ({handleClose}) => {
    const [isLogin, setIsLogin] = useState(true);
    return ( 
        // <div className="container">
        //     <div className="backdrop"></div>
        //     <div className=" ">
        //             <div className="row ">
        //                     <div className="col-sm-12 col-lg-4"></div>
        //                         <div className="col-lg-4 col-sm-12 " style={{'marginTop':'5em'}}>
        //                                 <div className="bg-light card  credentialCard border-0  my-5 p-3">
        //                                         <div className="card-body p-2  position-relative">
        //                                                         <div  className='closeModal position-absolute'><FontAwesomeIcon onClick={handleClose}  icon="fa-times" /></div>
        //                                                         <div className='text-center' >
        //                                                                 {!isLogin && <small className='text-center'>Have an Account ? <p onClick={()=>setIsLogin(true)} style={{"cursor":"pointer"}}><strong>Login In</strong></p></small>}
        //                                                                 {isLogin &&  <small className='text-center'>Don’t have an Account ? <p onClick={()=>setIsLogin(false)} style={{"cursor":"pointer"}}><strong>Sign Up</strong></p></small>}
        //                                                         </div>
                                                                
        //                         {/* Login  */}
        //                        { isLogin && <LoginComponent />}
        //                        { !isLogin && <SignupComponent/>}
                                      
        //                                         </div>
        //                                 </div>
        //                         </div>
        //                     <div className="col-sm-12 col-lg-4"></div>
        //             </div>
        //     </div>
        // </div>
        <div>
            <div className="modal fade" id="loginSignupModal" tabindex="-1" role="dialog" aria-labelledby="loginSignupModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content rounded-20">
                    <div className="modal-header py-0 bg-yellow border-bottom-2 border-dark" style={{'borderRadius':'12px 12px 0px 0px'}}>
                        {!isLogin && <h5 className="modal-title fw-bold" id="exampleModalLongTitle">Sign Up</h5>}
                        {isLogin && <h5 className="modal-title fw-bold" id="exampleModalLongTitle">Login</h5>}
                        <button style={{'fontSize':'36px'}} type="button" id="closePopup" className="close bg-yellow border-0 bg-light" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-4 px-4">
                    <div className='text-center' >
                     {!isLogin && <small className='text-center'>Have an Account ? <p onClick={()=>setIsLogin(true)} style={{"cursor":"pointer"}}><strong>Login In</strong></p></small>}
                     {isLogin &&  <small className='text-center'>Don’t have an Account ? <p onClick={()=>setIsLogin(false)} style={{"cursor":"pointer"}}><strong>Sign Up</strong></p></small>}
                  </div>
                        { isLogin && <LoginComponent />}
                        { !isLogin && <SignupComponent/>}
                    </div>
                    </div>
                </div>
            </div>
        </div>

     );
}
 
export default Modal;