import { FacebookButton, FacebookCount,TwitterButton } from "react-social";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import clipboard from './assets/clipboard.png'
import whatsapp from './assets/whatsApp.png'
import facebook from './assets/facebook.png';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
const Share = (props) => {
    const currentURL = window.location.href;
    let url = "https://github.com";
    let w= "hello";

    async function copyToClip() {
        await navigator.clipboard.writeText(window.location.href);
        NotificationManager.success('Copied To Clipboard!', 'Success');

        }

  return (<div>
        <NotificationContainer/>
    <div className="container modal " id="myModal" style={{'right':'0'}}>
      <div className="backdrop" onClick={props.handleClose} data-dismiss="modal" style={{'zIndex':'2'}}></div>
      <div className=" ">
        <div className="row ">
          <div className="col-sm-12 col-lg-4"></div>
          <div className="col-lg-4 col-sm-12 " style={{ 'marginTop': '5em' }}>
            <div className="bg-light card  credentialCard border-0  my-5 p-3"  style={{'zIndex':'3'}}>
              <div className="card-body p-2  position-relative">
                <div className='closeModal position-absolute close' type="button" data-dismiss="modal"><FontAwesomeIcon onClick={props.handleClose} icon="fa-times" /></div>

                <div style={{ "display": "flex" }}>
                  <FacebookButton style={{ "width": "3em", 'border': 'none', 'backgroundColor': 'white' }} url={currentURL} appId="2122811554555993">
                    <img style={{ "width": "100%" }} src={facebook} alt="" />
                  </FacebookButton>&nbsp;&nbsp;
                  <a href={`https://api.whatsapp.com/send?text=${currentURL}`} target="_blank" data-action="share/whatsapp/share"><img style={{ "width": "2.5em" }} src={whatsapp} alt="" /></a>
                </div>
                <div className="mg-t-10" style={{ "display": "flex" }}>
                  <input type="text" readOnly={true} style={{ "flex": "1" }} className="form-control" defaultValue={currentURL} />
                  <span style={{ 'cursor': 'pointer' }} onClick={copyToClip}><img style={{ "width": "2em" }} src={clipboard} alt="" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> );
}
 
export default Share;