import {Link, NavLink} from 'react-router-dom';
import logo  from "../assets/logo.png";
import cart from "../assets/cart.svg";
import wishlistSvg from "../assets/wishlistSvg.svg";
import wishlistDark from "../assets/wishlistBlack.svg";
import cartScroll from "../assets/cartScroll.svg";
import logoDark from "../assets/blackLogo.svg"
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/js/bootstrap.bundle';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import "../components/navbar.css";
import { useCollection } from "../hooks/useCollection";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUser , faSign, faSignIn } from '@fortawesome/free-solid-svg-icons'
library.add( faUser ,faSignIn);

const Navbar = () => {
    const [navbar, setNavbar] = useState(false)
    const {logout}=useLogout();
    const {user}=useAuthContext();
    const [open,setOpen]=useState(true);
    const {documents,error}=useCollection('Cart');
    const {documents:wishlistDoc}=useCollection('wishlist');
    const [cartCount,setCartCount]=useState(0);
    const [wishlistCount,setWishlistCount]=useState(0);
    let cartArr=[ ];
    let wishlistedArr=[ ];
    useEffect(()=>{
        if(user && documents){
            // setCartCount(documents.length)
        documents.forEach((eachDoc)=>{
            if(user.uid===eachDoc.user_id){
                //console.log("user",eachDoc.user_id)
                cartArr.push(eachDoc.user_id);
            }
            setCartCount(cartArr.length);
        })
    }
    },[documents,cartArr])

    useEffect(()=>{

        if(user && wishlistDoc){
            wishlistDoc.forEach((each)=>{
                if(user.uid===each.user_id){
                    wishlistedArr.push(each);
                } 
            })
        }
        setWishlistCount(wishlistedArr.length)
    },[wishlistDoc,user,wishlistedArr])

    useEffect(() => {
        if (!open && window.innerWidth <= 991){
          document.querySelector(".navbar-toggler").click()
        }
          },[open]);
    //navbar scroll changeBackground function
    const changeBackground = () => {
        if (window.scrollY >= 70 && window.innerWidth >991 ) {
            setNavbar(true)
        } 
        else if(window.scrollY >= 0 && window.innerWidth <= 991 ){
            setNavbar(true)
        }
        else {
            setNavbar(false)
        }
    }

    useEffect(() => {
        changeBackground()
        // adding the event when scroll change background
        window.addEventListener("scroll", changeBackground)
    })
    return ( 

        <div className="simpleNav">
            <div className="overlayNav"></div>
            <nav id= "mob_nav" style={ { height: navbar ? '85px' : 'auto' , transition: '0.5s ' } } className = {navbar ? "bg-light navbar navbar-expand-lg navbar-light bg-* shadowClass fixed-top": "navbar navbar-expand-lg navbar-light bg-* shadowClass fixed-top "}  >
                <div className="container p__zero">
                    <Link className="navbar-brand nav__logo"  to="/"><img src={navbar? logoDark : logo} alt="adventrio" /></Link>
                    <button className="navbar-toggler hamburger" onClick={()=> setOpen(true)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={navbar ? "bg-md-light collapse navbar-collapse pb-xxl-0 pb-xl-0 pb-lg-0 pb-3":"collapse navbar-collapse pb-xxl-0 pb-xl-0 pb-lg-0 pb-3"} id="navbarScroll">
                        <ul className="navbar-nav m-auto  navbar-nav-scroll" style={{"--bs-scroll-height":"100px"}}>
                            <li className="nav-item" onClick={()=> setOpen(false)}>
                                <NavLink className={navbar? "nav-link  text-dark":"nav-link active"} aria-current="page" exact to="/">Home</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=> setOpen(false)}>
                                <NavLink className={navbar? "nav-link  text-dark":"nav-link "} aria-current="page" exact to="/explore/lonavala">Explore</NavLink>
                            </li>
                            <li className="nav-item dropdown" onClick={()=> setOpen(false)}>
                                <NavLink className={navbar? "nav-link active text-dark":"nav-link active"} aria-current="page" exact to="/corporateEvents">Corporate Events</NavLink>
                            </li>
                            <li className="nav-item" onClick={()=> setOpen(false)}>
                                <NavLink className={navbar? "nav-link active text-dark":"nav-link active"} aria-current="page" exact to="/villa">Villa</NavLink>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center profile__section"style={{'marginTop':'10px'}}>
                           {user && <div className="navList position-relative" onClick={()=> setOpen(false)}>
                                <NavLink exact to="/wishlist">
                                {<span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>{wishlistCount}</span>}
                                        <img className="navIcons" src={navbar? wishlistDark : wishlistSvg} alt="add to wishlist" />
                                </NavLink>
                            </div>}
                            {!user && <div className="navList" onClick={()=> setOpen(false)}>
                                <NavLink exact to="/wishlist">
                                        <img className="navIcons" src={navbar? wishlistDark : wishlistSvg} alt="add to wishlist" />
                                </NavLink>
                            </div>}
                            {user && <div className="navList position-relative" onClick={()=> setOpen(false)}>
                                <NavLink exact to='/cart'>{<span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>{cartCount}</span>}<img className="navIcons" src={navbar?cartScroll : cartScroll} alt="add to cart" /></NavLink>
                            </div>}
                           { !user && <div className="navList" onClick={()=> setOpen(false)}>
                                <NavLink exact to='/cart'><img className="navIcons" src={navbar?cartScroll : cartScroll} alt="add to cart" /></NavLink>
                            </div>}
                            <div className="navList" >
                                <div className="dropdown user__dropdown">
                                    {!user && <button onClick={()=> setOpen(false)}  style={ { borderColor: navbar ? 'black' : 'white' , transition: '0.1s' } }  className="bg-transparent btn dropdown-toggle signInIcon " type="button" id="signInIcon" aria-expanded="false">
                                        {!user && <NavLink to ="/login"><Tippy content="Login/SignUp"><FontAwesomeIcon style={ { color: navbar ? 'black' : 'white', transition: '0.1s' } } className="user-icon" icon={faSignIn} /></Tippy></NavLink>}
                                    </button>}

                                    {user &&<button style={ { borderColor: navbar ? 'black' : 'white' , transition: '0.1s'  } }  className="bg-transparent btn dropdown-toggle signInIcon" type="button" id="userIcon" data-bs-toggle="dropdown" aria-expanded="false">
                                        <Tippy content={"Howdy, "+ user.displayName}><FontAwesomeIcon style={ { color: navbar ? 'black' : 'white', transition: '0.1s' } } className="user-icon" icon="fa-user" /></Tippy>
                                    </button>}
                                    {user && <ul className="dropdown-menu rounded-0 py-0" aria-labelledby="userIcon">
                                        <li className='dropdown-item'> Howdy, {user.displayName} </li>
                                        <li onClick={()=> setOpen(false)}><Link to="#" className="dropdown-item" onClick={logout}>Logout</Link></li>
                                    </ul>}
                                </div>
                            </div>
                            {/* <div className="navList  ">
                               {user && <button className='bg-yellow border-0 px-4 py-2 rounded' onClick={logout}>Logout</button>}
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>
            {/* {user && !user.emailVerified && <div className="text-center rounded-0 alert alert-danger fixed-bottom" role="alert">
                    <strong>Please Verify Your Email!</strong>
            </div>} */}
        </div>

     );
}
 
export default Navbar;