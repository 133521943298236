import React,{ useEffect,useState} from 'react'
import { useFirestore } from '../hooks/useFirestore'
import { useAuthContext } from '../hooks/useAuthContext'
import {useParams, useHistory} from 'react-router-dom';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import StarRating from '../components/StarRating';

const Review = ({productId,userId}) => {
    // const {productId}=useParams();
    const [desc,setDesc]=useState('')
    const [reviewSuccess,setReviewSuccess] = useState(false)
    const { addDocument, response } = useFirestore('reviews')
    const {user}=useAuthContext()
    const [isPending,setIsPending]=useState(false)
    const history=useHistory();
    const [stars,setStars]=useState('')
    const [rating,setRating]=useState(0);
    const [ratingErr,setRatingErr]=useState(false);

    const handleFormSubmit=(e)=>{
        e.preventDefault();
        if(rating===null){
            setRatingErr(true)

        }
        else{
            setRatingErr(false)
            //console.log(user)
            setIsPending(true)
            addDocument({
                uid:userId, 
                desc:desc,
                name:user.displayName,
                stars:Number(rating),
                productId
          })

        }
        
        }
    useEffect(() => {
        if (response.success) {
            setReviewSuccess(true)
            setDesc('')
            setIsPending(false)
            setTimeout(() => {
                document.querySelector('#closePopup').click();
                setReviewSuccess(false)
            }, 1000);
          }
        
    }, [response.success]); 
    const handleRatings=(rating)=>{
        setRating(rating)
    }
    const clearRatingErr=()=>{
        setRatingErr(false)

    }

    return ( 
        // exploreClass
        <div className='row' >
            <form onSubmit={handleFormSubmit} className="text-center ">
                <label>
                    <textarea className='p-2 round-2 review-textarea'  onChange={(e)=>{setDesc(e.target.value)}} value={desc} rows="6" cols="45" placeholder="Add Your Review Upto 300 Characters" required maxlength="300"></textarea>
                </label>
                <br/>
                <StarRating handleRatings={handleRatings} reviewSuccess={reviewSuccess} clearRatingErr={clearRatingErr}/>
                <br/>
                {!isPending && <button className='advButton mb-2'>Submit</button>}

                {isPending && <button disabled className='advButton mb-3'>Adding...</button>} 
                {ratingErr && <h6 className='text-danger fw-bold' id="review-success">Please give ratings</h6>}
                {reviewSuccess && <h6 className='text-success fw-bold' id="review-success">Thanks For Your Review</h6>}
            </form>
        </div>
     );
}
 
export default Review;