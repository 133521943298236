import { useState, useEffect } from 'react'
import { useFirestore } from './hooks/useFirestore'
import { useAuthContext } from './hooks/useAuthContext'
import { useCollection } from './hooks/useCollection'
import { projectStorage } from './firebase/config'
export default function TransactionForm() {
  const [name, setName] = useState('')
  const [amount, setAmount] = useState('')
  const [isPending,setIsPending]=useState(false)
  const [imageName,setImageName]=useState('')
  const {user}=useAuthContext()
  const { addDocument, response } = useFirestore('transactions')
  const {documents,error}=useCollection('transactions')

  const handleFileChange=(e)=>{
    let selected=e.target.files[0];
    setImageName(selected)
    //console.log(selected.name,"selected");

  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)
    const uploadPath=`assets/${imageName.name}`
    const img = await projectStorage.ref(uploadPath).put(imageName);
    const imgURL=await img.ref.getDownloadURL()
    await addDocument({
      uid:user.uid, 
      name:name, 
      amount,
      photoURL:imgURL
    })
  }
//console.log(response.success)
  // reset the form fields
  useEffect(() => {
    if (response.success) {
      setName('')
      setAmount('')
      setImageName('')
      setIsPending(false)
    }
  }, [response.success])

  return (
    <>
      <h3 style={{'marginTop':'10%'}} className="text-center">Add a Transaction</h3>
      <br /><br />
      <form onSubmit={handleSubmit} className="text-center">
        <label>
          <span></span>
          <input 
            type="text"
            required
            onChange={(e) => setName(e.target.value)} 
            value={name} 
            placeholder="Transaction Name"
          />
        </label>
        <br /><br />
        <label>
          <span></span>
          <input
            type="number"
            required
            onChange={(e) => setAmount(e.target.value)} 
            value={amount} 
            placeholder='Enter Amount'
          />
        </label>
        <br /><br />
        <div className="text-center">
        <input type="file" name="" id="" onChange={handleFileChange} /></div>
        <br /><br />
        {!isPending && <button className="fw-bold advButton mx-3">Add Transaction</button>}
        {isPending && <button className="fw-bold advButton mx-3" disabled>Adding</button>}
       
      </form>
      
      <h2 style={{'margin':'5% 0'}}>Transaction history</h2>
  
        {documents &&
        (<div className="container">
          <div className="row">
              {documents.map((doc)=> 
              (<div className='col-md-3'>
                  <div className="card mb-5" style={{"width": "18rem"}} key={doc.id}>
                        <img src={doc.photoURL} className="card-img-top" alt="..." style={{"height": "200px"}}/>
                        <div className="card-body">
                              <h5 className="card-title">{doc.name}</h5>
                              <p className="card-text text-left">₹ {doc.amount}</p>  
                        </div>
                  </div>
              </div>))}
          </div> 
        </div>)}
    </>
  )
}