import "../components/footer.css";
import logo from "../assets/logo.png";
import insta from "../assets/Instagram.svg";
import fb from "../assets/Facebook.svg";
import tweet from "../assets/Twitter.svg";
import {  Link } from "react-router-dom";
const Footer = () => {
    return ( 
        <div className="footerParent border-top border-white ">
            <div className="row footerRow container mx-auto ">
                <div className="col-sm-1"></div>
                <div className="col-lg-2 col-md-3">
                    <h5>Quick Links</h5>
                    <ul className="links">
                        <li><Link to={`/`}>Home</Link></li>
                        <li><Link to={`/explore`}>Explore</Link></li>
                        <li><Link to={`/corporateEvents`} >Corporate Events</Link></li>
                        <li><Link to={`/villa`}>Villa</Link></li>
                    </ul>
                </div>

                <div className="col-lg-3 col-md-3">
                    <h5>Call Us</h5>
                    <ul className="links">
                        <li><a href="tel:+91 8446601973">+91 8446601973</a></li>
                    </ul>

                    <h5>Email Us</h5>
                    <ul className="links">
                        <li><a href="mail:contact@adventrio.com">contact@adventrio.com</a></li>
                    </ul>
                </div>

                <div className="col-lg-3 col-md-3">
                    <h5>About Us</h5>
                    <p className="text-start text-white" style={{'width':'15em'}}>Lorem ipsum dolor sit amet consectetur 
                        adipisicing elit. Quibusdam mollitia </p>
                </div>

                <div className="col-lg-2 text-md-center ">
                    <a href="/"><img src={logo} alt="" /></a>
                    <h5 className="mb-0 pb-0" >Follow Us</h5>
                    <ul className="links list-group d-flex flex-row pt-2 justify-content-md-center">
                        <li><a href="/"><img src={fb} alt="" /></a></li>
                        <li><a href="/"><img src={insta} alt="" /></a></li>
                        <li><a href="/"><img src={tweet} alt="" /></a></li>
                    </ul>
                </div>
                <div className="col-sm-1"></div>
            </div>
            <div className="row">
                <p className="text-white">Copyright © 2022 Adventrio</p>
            </div>
        </div>
     );
}
 
export default Footer;