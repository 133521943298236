import React,{ useRef,useEffect,useState } from 'react';
import { useAuthContext } from '../hooks/useAuthContext';
import { useCollection } from "../hooks/useCollection";
import { useFirestore } from '../hooks/useFirestore';
import { useStayCollection } from "../hooks/useStayCollection";
import LoginModal from "./LoginModal"
import mas1 from "../assets/waterSport.jpg";
import mas2 from "../assets/2.png";
import mas3 from "../assets/3.png";
import mas4 from "../assets/4.png";
import mas5 from "../assets/5.png";
import mas6 from "../assets/6.png";
import mas7 from "../assets/7.png";
import Slider from "react-slick";
import Tippy from '@tippyjs/react';
import userIcon from "../assets/userIcon.svg";
import activityIcon1 from "../assets/activityParachute.svg";
import {useParams,Link} from 'react-router-dom';
import { projectFirestore } from '../firebase/config';
import stayImg1 from "../assets/stayImg1.png";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import "./stay.css";
import Footer from '../components/Footer';
import Modal from '../components/Modal';
const Stay = () => {
    const {stayType}=useParams();
    const {user}=useAuthContext();
    const [stayRes,setStayRes]=useState(null);
    const [location,setLocation]=useState(null);
    const {documents,error}=useCollection('location');
    const [clickedLocation,setClickedLocation]=useState(null);
    const [filteredArr,setfilteredArr]=useState([]);
    const [stayName,setStayname]=useState(null);
    const [showLoginModal,setShowLoginModal]=useState(false);
    const { addDocument, response } = useFirestore('wishlist');
    const {documentsRes}=useStayCollection('wishlist');
    const [userWishlist,setuserWishlist]=useState(null);
    let wishlistProdId=[ ];
    let wishlistUserId;
    const stayRef=useRef(null);

    const stay = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll:1,
        arrows:true,
        adaptiveHeight:true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4,
                  slidesToScroll:1
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
    };
    const filterLocations = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll:1,
        arrows:true,
        adaptiveHeight:true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4,
                  slidesToScroll:1
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
    };
    useEffect(()=>{
        stayRef.current?.scrollIntoView({ behavior: 'smooth' })

    },[stayType])
    useEffect(() => {
        if(documentsRes!==null){
            documentsRes.forEach((each)=>{
                if(user && user.uid===each.user_id){
                    wishlistUserId=user.uid;
                    wishlistProdId.push(each.product_id);
                }   
            })
        }
        // //console.log(wishlistProdId,"prod ids hai saare")

      }, [documentsRes,userWishlist,wishlistProdId,wishlistUserId,user])
    useEffect(() => {
       //Getting all the locations
        let ref=projectFirestore.collection('location');
        let resultLocation=[];
        const unsubscribeLocation=ref.onSnapshot((snapshot)=>{
            snapshot.docs.forEach((location)=>{
                resultLocation.push(location.id)     
            })
           
            setLocation(resultLocation)
            let resultStays=[];
            let stayName=[];
            let resultsArr;
            let filtered;

            //Getting all the Stay Types
            resultLocation.forEach((eachLocation)=>{
                let reff=projectFirestore.collection('location').doc(eachLocation).collection("stay");
                const unsubscribe=reff.onSnapshot((snapshot)=>{
                    snapshot.docs.forEach((doc)=>{
                        stayName.push({...doc.data(),id:doc.id})

                    })
                    const uniqueArr = stayName.filter((activity, index, self) => 
                    self.findIndex(a => a.id === activity.id) === index);
                    //   //console.log(uniqueArr,"uniq")
                    setStayname(uniqueArr)
                })

            })

            //Getting all the documents from the selected stay
            resultLocation.forEach((eachLocation)=>{
                let ref2=projectFirestore.collection('location').doc(eachLocation).collection("stay").doc(stayType).collection(stayType);
                const unsubscribe=ref2.onSnapshot((snapshot)=>{
                    snapshot.docs.forEach((doc)=>{
                        if(user && user.uid===wishlistUserId){

                            if(wishlistProdId.includes(doc.id)){
                                resultStays.push({...doc.data(),id:doc.id,wishlisted:true})
                            }
                            else{
                                resultStays.push({...doc.data(),id:doc.id,wishlisted:false})

                            }
                    }
                    else{
                        resultStays.push({...doc.data(),id:doc.id,wishlisted:false})
                    }
                           
                            
                    })
                    setStayRes(resultStays);
                    // //console.log(resultStays,"initial re")
                    // //console.log(wishlistProdId,"wishlisted")
                })

            })
        
        })
        
       
        return () => {
            unsubscribeLocation();
            
        };
    }, [clickedLocation,filteredArr,stayType,wishlistUserId,documentsRes]);


    const handleLocationChange=(doc)=>{  
        setClickedLocation(doc.id)
        const result=stayRes.filter((eachLoc)=>{
            return eachLoc.location===doc.id 
        })
        setfilteredArr(result)
    }
    const handleClose=()=>{
        setShowLoginModal(false)
    }

    const handleAddToWishlist = (idd,stayType,location) => {
        addDocument({
          product_id:idd,
          user_id:user.uid,
          location:location,
          stay_type:stayType, 
          wishlisted:1,
        })      
      }
      
      useEffect(() => {
        if(response.success){
            NotificationManager.success('Added To Wishlist', 'Success');
        }
      }, [response.success])
      

      const handleRemoveFromWishlist = (id)=>{
      
         let  prod_idd = id
         let ref = projectFirestore.collection('wishlist')
         documentsRes.forEach((doc)=>{
             if(user.uid===doc.user_id){
                 if(wishlistProdId.includes(prod_idd)  && prod_idd===doc.product_id){
                     projectFirestore.collection('wishlist').doc(doc.id).delete().then(
                        ()=>{
                        NotificationManager.success('Removed From Wishlist', 'Success');
                        });;
 
                 }
 
             }
 
         })
          
       }
       useEffect(() => {
       
        if(user && stayType && clickedLocation){
            let stayRef=projectFirestore.collection('location').doc(clickedLocation).collection('stay').doc(stayType).collection(stayType);
            stayRef.onSnapshot((snapshot)=>{
                let resultsStayUser=[];
                snapshot.docs.forEach((doc)=>{
                    if(user.uid===wishlistUserId){
                        //to push users wishlisted data in new state
                        if(wishlistProdId.includes(doc.id)){
                           
                            resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:true})
                        }
                        else{
                            resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:false})

                        }
                     }
                     else{
                        resultsStayUser.push({...doc.data(),id:doc.id,wishlisted:false})

                     }
                    
                })
                //update State
                setfilteredArr(resultsStayUser);
                // //console.log(stayRes,"user")
                
            },(error)=>{
                setStayRes('could not fetch data')
            })

        }
      }, [response.success,documentsRes,clickedLocation,response.document,stayType,user])
    return ( 

        <>
        <NotificationContainer/>
        <Modal/>
         { showLoginModal && <LoginModal handleClose={handleClose}/>}
        {!showLoginModal && <div>
            <div className="exploreClass d-flex justify-content-center align-items-start">
                <div className="searchDiv heroSectionSearch">
                    <div className="sectionTitle">
                        <h1 className="text-dark text-center fw-bold">Stay</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark " style={{'width':'65%'}} />
                </div>
            </div>

{/* all the stay categories */}
            <div className="wrapperDiv">
            {window.location.href.split('/').pop()==="stay" && <small>Please select a Stay</small>}
                 <div className="slider__div pt-md-5 text-webkit-center non_slicky ">
                 {stayName &&
                    <Slider {...stay} >
                       {stayName.map((doc)=>( <Link className='text-dark' to={`/stay/${doc.id}`} key={doc.id}><div className = {doc.id === stayType ? "card slider__cards active__slider__cards" :"card slider__cards"}>
                            <img className="slider__imgs" src={doc.stayImg} alt="" />
                            <h4 style={{'textTransform':'capitalize','textDecoration':'none !important','color':'black !important'}}>{doc.id}</h4>
                        </div></Link>))}

                        {/* <div className = "card slider__cards">
                            <h4><Link to={`/stay/cottage`}>Cottages</Link></h4>
                            <img className="slider__imgs" src={mas3} alt="" />
                        </div> */}

                    </Slider>}
                </div>
            </div>

{/* all the location filters */}
        {documents && <div className="wrapperDiv" ref={stayRef}>
            <div className="slider__div slider__div2 non_slicky">
               <Slider {...filterLocations}>
               { documents.map((doc)=>(<div onClick={()=>handleLocationChange(doc)} key={doc.id} className = {doc.id===clickedLocation ?"card slider__cards activeLocation__slider__cards" : "card slider__cards"}>
                        <h4 className='mb-0 ' style={{'textTransform':'capitalize'}}>{doc.id}</h4>
                    </div>))}
                    
                </Slider>
            </div>
        </div>}

{/* all the results */}
{stayRes && stayRes.length==0 && <h2 className='text-yellow fw-bold' style={{"textTransform":"capitalize"}}>No {stayType} Available</h2>}
            {stayRes && filteredArr.length===0 && <div className="mx-4 stayProducts pb-5 pt-4">
                {stayRes.map((doc)=>(
                <div className="row g-0 rounded-20  mb-4 main_278_h" >
                    <div className="col-sm-12 main_278_h ">
                        <div className=" card shadow mb-3 rounded-20 " id="stayProductCard" key={doc.id}>
                            <div className="col-md-3 imgCol" >
                                <img src={doc.featuredImg} className="stayFeaturedImg img-fluid h-100" alt="..."/>
                            </div>
                            <div className="col-md-9 product__specifications px-3 align-self-normal align-self-md-center">
                                <div className="card-body">
                                <div className="row">
                                    <div className="col-6 details__col">
                                        <h2 className="card-title text-dark fw-bold text-start" style={{'fontSize':'30px'}}>{doc.siteName}</h2>
                                        <h5 className="card-text text-dark text-start mb-4"><img style={{'width':'6%'}} src={userIcon} alt="..." /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small></h5>
                                    </div>
                                    <div className="col-6 price__col">
                                        <h2 className="fw-bold text-end text-dark" style={{'fontSize':'30px'}}>₹{doc.price} / night</h2>
                                    </div> 

                                    </div>
                                    
                                    <p className="card-text text-dark text-start description__details">
                                    {doc.siteDesc.substr(0, 150)}...
                                    </p>
                                    
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="card-text text-dark text-start d-flex flex-row justify-content-space-between mb-3">
                                                    <div>
                                                        <Tippy content="Hot Water Shower"><div className="activityIcon" ><img src={activityIcon1} alt="..." /></div></Tippy>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-end action__btns">
                                            {doc.wishlisted}
                                            <Link to={`/details/${doc.location}/${stayType}/${doc.id}`} target="_blank">
                                            <button className="fw-bold advButton mx-3"> View More </button></Link>
                                            {/* {!user && <button onClick={()=>setShowLoginModal(true)} className="fw-bold advButton danger ">Add To Wishlist</button>} */}
                                            {!user &&  <button type='button'  className="fw-bold advButton danger" data-toggle="modal" data-target="#loginSignupModal" >Add To Wishlist</button>}
                                            {user && !doc.wishlisted && <button onClick={()=>handleAddToWishlist(doc.id,stayType,doc.location)} className="fw-bold advButton danger ">Add To Wishlist</button>}
                                            {doc.wishlisted &&<button className= "fw-bold advButton"  id={doc.id} onClick={()=>handleRemoveFromWishlist(doc.id)} >Remove from Wishlist</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> ))}
                
            </div>}
            {/* all the filtered results */}
            {filteredArr.length>0 && <div className="mx-4 stayProducts pb-5 pt-4">
                {filteredArr.map((doc)=>(
                <div className=" row g-0 rounded-20">
                    <div className="col-sm-12 main_278_h ">
                        <div className="card mb-3 rounded-20 shadow "  key={doc.id} id="stayProductCard">
                            <div className="col-md-3 imgCol" >
                                <img src={doc.featuredImg} className="stayFeaturedImg img-fluid h-100" alt="..."/>
                            </div>
                            <div className="col-md-9 product__specifications px-3 align-self-normal align-self-md-center ">
                                <div className="card-body">
                                <div className="row">
                                    <div className="col-6 details__col">
                                        <h2 className="card-title text-dark fw-bold text-start" style={{'fontSize':'30px'}}>{doc.siteName}</h2>
                                        <h5 className="card-text text-dark text-start mb-4"><img style={{'width':'6%'}} src={userIcon} alt="..." /> X {doc.tentSize} <small className='accomodation_notice'>(Max Accomodation Capacity)</small></h5>
                                    </div>
                                    <div className="col-6 price__col">
                                        <h2 className="fw-bold text-end text-dark" style={{'fontSize':'30px'}}>₹{doc.price} / night</h2>
                                    </div> 

                                    </div>
                                    
                                    <p className="card-text text-dark text-start description__details">
                                    {doc.siteDesc.substr(0, 250)}...
                                    </p>
                                    
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="card-text text-dark text-start d-flex flex-row justify-content-space-between mb-3">
                                                    <div>
                                                        <Tippy content="Hot Water Shower"><div className="activityIcon" ><img src={activityIcon1} alt="..." /></div></Tippy>
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 text-end action__btns">
                                            <Link to={`/details/${doc.location}/${stayType}/${doc.id}`} target="_blank">
                                            <button className="fw-bold advButton mx-3"> View More </button></Link>
                                            {/* {!user && <button onClick={()=>setShowLoginModal(true)} className="fw-bold advButton danger ">Add To Wishlist</button>} */}
                                            {!user &&  <button type='button'  className="fw-bold advButton danger" data-toggle="modal" data-target="#loginSignupModal" >Add To Wishlist</button>}
                                            {user && !doc.wishlisted && <button onClick={()=>handleAddToWishlist(doc.id,stayType,doc.location)} className="fw-bold advButton danger ">Add To Wishlist</button>}
                                            {doc.wishlisted &&<button className= "fw-bold advButton"  id={doc.id} onClick={()=>handleRemoveFromWishlist(doc.id)} >Remove from Wishlist</button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> ))}
                
            </div>}
            <Footer/>
        </div>}
        </>
     );
}
 
export default Stay;