import {Link} from 'react-router-dom';
import {React,useState,useEffect} from 'react';
// import Signup from '../signup/Signup';
import { useLogin } from '../hooks/useLogin';
import google from "../assets/google.svg";
import {projectAuth , provider}  from '../firebase/config.js';
import { useSignup } from '../hooks/useSignup';
import { useAuthContext } from '../hooks/useAuthContext';
import  firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import { useCollection } from "../hooks/useCollection";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

export default function Login() {
    const [email,setEmail]=useState('');
    const [phone,setPhone]=useState('');
    const [showOtpField,setShowOtpField]=useState(false)
    const [otp,setotp]=useState('');
    const [final, setfinal] = useState('');
    const [password,setPassword]=useState('');
    const { login, error, isPending } = useLogin();
    const {user}=useAuthContext();
    const{signupwithgoogle}= useSignup();
    const history = useHistory();
    const [otpPending,setOtpPending]=useState(false);
    const {documents}=useCollection('users');
    const allUserPhones=[];
    const [notUser,setNotUser]=useState(false);

useEffect(()=>{
        setNotUser(false)
        if(documents){
        documents.forEach(eachPhone => {
                allUserPhones.push(eachPhone.phoneNumber)
                
        });
}
        // //console.log('phone',allUserPhones)


},[documents,phone])
    const signin = () => {
        projectAuth.signInWithPopup(provider)
        .then((res)=>{
                signupwithgoogle(res)
        })
        .catch((error)=>{
                NotificationManager.error(error, 'Error!');
        }
         
        );
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        login(email, password)

    }
        
    //Phone Authentication
    const handlePhoneAuth=(e)=>{
        e.preventDefault();
        //console.log(phone)
        if(documents && allUserPhones.includes(Number(phone))){
        setOtpPending(true)
        var number='+91'+phone;
        // //console.log(number)
       
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha');
        projectAuth.signInWithPhoneNumber(number, verify).then((result) => {
                setfinal(result);
                setShowOtpField(true);
                setOtpPending(false)
                NotificationManager.success('OTP Sent', 'Success');
            
        })
            .catch((err) => {
                NotificationManager.error(err, 'Error!');
                // window.location.reload()
            });
        }
        else{
               setNotUser(true);
        }
    }

    //OPT validation
    const ValidateOtp=()=>{
        
        if (otp === null || final === null)
        return;
    final.confirm(otp).then((result) => {
        // //console.log(result)

        NotificationManager.success('Account Verified', 'Success');
        history.push('/home');
        window.location.reload()
    }).catch((err) => {
        NotificationManager.error('Incorrect OTP', 'Invalid');
    })
    }
//     useEffect(() => {
//        if(user){
//         history.push('/home');
//        }
//     }, [user])
  return (
        <div className=" signUpParent login__screen">
                <NotificationContainer/>
                <div className="row">
                        <div className="col-sm-12 col-md-1"></div>
                        <div className="col-md-4 col-sm-12 mt-5em" >
                                <div className="blurbg card credentialCard border-0 shadow my-5">
                                        <div className="card-body px-4 py-5 p-md-5">
                                                <div className='text-center' style={{'marginBottom':'2.5em'}}>
                                                        <h2 className="card-title text-center text-dark fw-bold my-0">Get Started</h2>
                                                        <small className='text-center'>Don’t have an Account ? <Link rel="stylesheet" to="/Signup" className="text-black" ><strong>Sign Up</strong></Link></small>
                                                </div>
                                                <form onSubmit={handlePhoneAuth}>
                                                        {!showOtpField &&  <div className="form-floating mb-3">
                                                                <span className='text-dark fw-bold'><strong><small>Phone Number</small></strong></span>
                                                                <input required type="number" 
                                                                onChange={e=>setPhone(e.target.value)} value={phone}
                                                                className="form-control text-dark fw-bold" style={{"borderColor":"black"}} id="floatingInput" />
                                                        </div>}
                                                        {showOtpField && <div>
                                                                <span className='text-dark fw-bold'><strong><small>Enter your OTP</small></strong></span>
                                                                <input className="form-control text-dark fw-bold" style={{"borderColor":"black"}} type="text" 
                                                                onChange={(e) => { setotp(e.target.value) }}></input>
                                                                 <br /><br />
                                                        </div>}
                                                        { isPending && <div className="text-center  signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type="submit">Log in</button>
                                                        </div>}
                                                        {otpPending && !showOtpField &&<div className="text-center  signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold disabled">Generating OTP...</button>
                                                        </div>}

                                                        {!showOtpField && <div className="text-center signUpBtn">
                                                                {!otpPending && <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type="submit">Generate OTP</button>}
                                                                { <div id="recaptcha" style={{textAlign:'center'}}></div>} 
                                                        </div>}
                                                </form>
                                                {showOtpField && !isPending && <div className="text-center signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" onClick={ValidateOtp}>Verify</button>
                                                        </div>}

                                                        {isPending && <div className="text-center  signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold disabled">Loading...</button>
                                                        </div>}
                                                        { notUser && <p className="text-danger fw-bold">User Does Not Exist, Please Sign Up</p> }
                                        </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-7"></div>
                </div>
                {/* {user && <div className="alert alert-warning fixed-bottom" role="alert">
                    Please Register First
            </div>} */}
        </div>
  )
}
