import { useEffect, useState } from 'react';
import { projectFirestore } from '../firebase/config';

export const useStayCollection = (collection) =>{
    const [documentsRes,setDocuments]=useState(null);
    const [error,setError]=useState(null);

    useEffect(()=>{
        let ref=projectFirestore.collection(collection);
        // //console.log('inside useEffect',ref)
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
            let results=[];
            snapshot.docs.forEach((doc)=>{
                results.push({...doc.data(),id:doc.id})

            })
            //update State
            setDocuments(results);
            setError(null);

        },(error)=>{
            // //console.log(error);
            setError('could not fetch data')
        })
        //
        return()=>unsubscribe();

    },[collection])
    return{documentsRes,error}

}