import {React,useState} from 'react';
import LoginComponent from "../Login/LoginComponent";
import SignupComponent from "../signup/SignupComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
library.add( faTimes)


const Modal = ({handleClose}) => {
    const [isLogin, setIsLogin] = useState(true);
    return ( 
        <div className="container">
            <div className="backdrop"></div>
            <div className=" ">
                    <div className="row ">
                            <div className="col-sm-12 col-lg-4"></div>
                                <div className="col-lg-4 col-sm-12 " style={{'marginTop':'5em'}}>
                                        <div className="bg-light card  credentialCard border-0  my-5 p-3">
                                                <div className="card-body p-2  position-relative">
                                                                <div  className='closeModal position-absolute'><FontAwesomeIcon onClick={handleClose}  icon="fa-times" /></div>
                                                                <div className='text-center' >
                                                                        {!isLogin && <small className='text-center'>Have an Account ? <p onClick={()=>setIsLogin(true)} style={{"cursor":"pointer"}}><strong>Login In</strong></p></small>}
                                                                        {isLogin &&  <small className='text-center'>Don’t have an Account ? <p onClick={()=>setIsLogin(false)} style={{"cursor":"pointer"}}><strong>Sign Up</strong></p></small>}
                                                                </div>
                                                                
                                {/* Login  */}
                               { isLogin && <LoginComponent/>}
                               { !isLogin && <SignupComponent/>}
                                      
                                                </div>
                                        </div>
                                </div>
                            <div className="col-sm-12 col-lg-4"></div>
                    </div>
            </div>
        </div>

     );
}
 
export default Modal;