import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import { useAuthContext } from './useAuthContext'
import { useHistory } from 'react-router-dom'
import { getAuth, sendEmailVerification } from "firebase/auth";
import firebase from "firebase/app";

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  const history = useHistory()

  const signupwithgoogle =async(res) =>{
    setError(null)
    setIsPending(true)
    try {
      
      dispatch({ type: 'LOGIN', payload: res.user })
      setIsPending(false)
      if(window.location.href.split("/").pop()==="signup")
        {
          alert('Logged In successfully!')
          history.push("/home");
        }
        else{
          alert('Logged In successfully!')

        }

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
        
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }
  
  const signup = async (email, password, displayName) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
          projectAuth
            .createUserWithEmailAndPassword(email, password)
            .then(async user=>{
              // send verification mail.
            firebase.auth().currentUser.sendEmailVerification();

            if (!user) {
              throw new Error('Could not complete signup')
            }

            // add display name to user
              user.user.updateProfile({ displayName })

            // dispatch login action
            dispatch({ type: 'LOGIN', payload: user.user })
            setIsPending(false)

            if (!isCancelled) {
              setIsPending(false)
              setError(null)
            }
            history.push("/home");
        })
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending ,signupwithgoogle }
}