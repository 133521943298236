import {useState,useEffect,useRef} from 'react';
import { projectFirestore } from '../firebase/config';

const CartPopup = ({doc,addPopupData}) => {
    const [adults,setAdults]=useState((doc.containerData.adults));
    const [children,setChildren]=useState(doc.containerData.children);
    const [rooms,setRooms]=useState(doc.containerData.rooms);
    const [totalMatress,setTotalMatress]=useState(doc.containerData.totalMatress);
    const [adultMatress,setAdultMatress]=useState(doc.containerData.adultMatress);
    const[childrenMatress,setChildrenMatress]=useState(doc.containerData.childrenMatress);
    const myref=useRef(null);
    const [weeksdayCount,setWeekdaysCount]=useState(0);
    const [fridaysCount,setFridaysCount]=useState(0);
    const [saturdaysCount,setSaturdaysCount]=useState(0);
    const [sundaysCount,setSundaysCount]=useState(0);
    const [containerPrice, setContainerPrice] = useState(0);
    let weekday=0;
    let friday=0;
    let saturday=0;
    let sunday=0;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
   
    
    const [totalDaysCount,setTotalDaysCount]=useState(0);
    //console.log(doc.containerData.rooms,'checkin')

    useEffect(() => {
        if (doc){
          let startDate=new Date(doc.checkInDate);
          let endDate=new Date(doc.checkOutDate);

          while(startDate < endDate){

            var dayName = days[new Date(startDate).getDay()];
        
            if(dayName==="Friday"){
              
                friday=friday+1;
            }
            else if(dayName ==="Saturday"){
            
            saturday=saturday+1;
            }
            else if(dayName ==="Sunday"){
                
                sunday=sunday+1;
              }
              else{
                weekday=weekday+1;
              }
        
            startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1); 
          }
          setWeekdaysCount(weekday);
          setFridaysCount(friday);
          setSaturdaysCount(saturday);
          setSundaysCount(sunday);
          setTotalDaysCount(weekday+friday+saturday+sunday)
          // //console.log(weekday,friday,saturday,sunday)
        
        }

      }, []);
      useEffect(() => {
       
          var roomPrice = (((doc.weekDayPrice)*weeksdayCount)+(doc.fridayPrice*fridaysCount)+(doc.saturdayPrice*saturdaysCount)+(doc.sundayPrice*sundaysCount))*rooms
          var mattressPrice = (doc.matressChildrenPrice*(childrenMatress<0?0:childrenMatress))*totalDaysCount + (doc.matressAdultPrice*(adultMatress<0?0:adultMatress)*totalDaysCount)
          setContainerPrice(roomPrice+mattressPrice);
          //console.log(containerPrice,"this is price")
     
      }, [adults,children,childrenMatress,adultMatress,totalDaysCount,rooms]);
    
    
      useEffect(() => {
        if(adults+children>1){
            if(doc.containerData.rooms>Math.ceil((adults+children)/doc.maxPeople)){
                setRooms(doc.containerData.rooms)

            }else{
                setRooms(Math.ceil((adults+children)/doc.maxPeople))
            }
           
           //To increment the room count realtive to guests(adults+children)
            // //console.log("adult+child",adults+children)
            // //console.log('ff',doc.minPeople*rooms)  
        }
    }, [adults,children])
    //console.log('new rooms',typeof doc.maxPeople)

    useEffect(()=>{
        setTotalMatress(adults+children-(doc.minPeople*rooms))
        setAdultMatress(0)
        setChildrenMatress(0) //To set total matress wrt rooms
        // //console.log("text",adults-(rooms*doc.minPeople)<0)
        if(adults-(rooms*doc.minPeople)>=0 && totalMatress>=0){
            setAdultMatress(adults-(rooms*doc.minPeople));
            setChildrenMatress(totalMatress-adultMatress);
            
        }else if(adultMatress>=0 && totalMatress>=0){
            setChildrenMatress(totalMatress-adultMatress)
        }
        // //console.log('total extra matress=>',totalMatress,"adult matress=>",adultMatress,"Child matress=>",childrenMatress)
    },[adults,children,rooms,totalMatress])

    const handleSubmit=(e)=>{
        e.preventDefault();
        projectFirestore.collection('Cart').doc(doc.id).update({totalPrice:Number(containerPrice),containerData:{adultMatress,adults,children,childrenMatress,rooms,totalMatress}});
        myref.current.click()
    }
    
    return (
        <div>
            <div className="modal fade" id={"productModal"+doc.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered zindex-modal" role="document">
                    <div className="modal-content rounded-20">
                    <div className="modal-header py-0 bg-yellow border-bottom-2 border-dark" style={{'borderRadius':'12px 12px 0px 0px'}}>
                        <h5 className="modal-title fw-bold" id="exampleModalLongTitle">Guests</h5>
                        <button style={{'fontSize':'36px'}} type="button" ref={myref} id="closePopup" className="close bg-yellow border-0 bg-light" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-4">
                        {/* Guest */}
                        <div className="row mb-4">
                            <div className="col-sm-3">
                                <span>Adults </span>
                            </div>
                            <div className="col-sm-9">
                                <div className="qty text-end text-dark">
                                    <button onClick={()=>{setAdults(prev=>prev>1?prev-1:1)}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                    <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={adults} /> 
                                    <button onClick={()=>{setAdults(prev=>prev+1)}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                </div>
                            </div>
                        </div>

                        {/* Children */}
                        <div className="row mb-4">
                            <div className="col-sm-3">
                                <span>Children </span>
                            </div>
                            <div className="col-sm-9">
                                <div className="qty text-end text-dark">
                                    <button onClick={()=>{setChildren(prev=>prev>1?prev-1:0)}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                    <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={children} /> 
                                    <button onClick={()=>{setChildren(prev=>prev+1)}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                </div>
                            </div>
                            <small>
                                Age between {doc.childrenAgeCriteria} years
                            </small>
                        </div>

                        {/* Total Rooms */}
                        <div className="row mb-4">
                            <div className="col-sm-3">
                                <span>Rooms </span>
                            </div>
                            <div className="col-sm-9">
                                <div className="qty text-end text-dark">
                                    <button onClick={()=>{setRooms(prev=>prev>Math.ceil((adults+children)/doc.maxPeople)?prev-1:Math.ceil((adults+children)/doc.maxPeople))}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                    <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={rooms} /> 
                                    <button  onClick={()=>{setRooms(prev=>prev>0?prev+1:1)}} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                </div>
                            </div>
                        </div>
                        {/* Children Matress */}

                        {/* Matress Details */}
                        <div className="row mb-3">
                            <div className="col-sm-3 text-center">
                                <label htmlFor=""> <small>Extra Mattress(s)</small> </label>
                                <div>&nbsp;</div>
                                {totalMatress>=0 && <div className='text-center'>{totalMatress}</div>}
                                {totalMatress===-1 && <div className='text-center'>0</div>}
                            </div>

                            <div className="col-sm-3 text-center">
                                <label htmlFor=""> <small>Children Mattress(s)</small> </label>
                                <div>
                                    <small style={{'fontSize':'12px'}}>{doc.matressChildrenPrice} / night</small>
                                </div>
                                {childrenMatress>=0 && <div className='text-center'>{childrenMatress}</div>}
                                {childrenMatress===-1 && <div className='text-center'>0</div>}
                            </div>

                            <div className="col-sm-3 text-center">
                                <label htmlFor=""> <small>Adult Mattress(s)</small> </label>
                                <div>
                                    <small style={{'fontSize':'12px'}}>{doc.matressAdultPrice} / night</small>
                                </div>
                                <div className='text-center'>{adultMatress}</div>
                            </div>
                            <div className="col-sm-3 text-center">
                                <label htmlFor=""> <small>Allotted Room(s)</small> </label>
                                <div>&nbsp;</div>
                                <div className='text-center'>{rooms}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-8">
                                <small style={{'fontSize':'12px'}}>
                                    Allotted Rooms can be updated to minimize Extra Mattress Quantity.
                                    <em> <strong> Container Charges <span className='text-decoration-underline'> per night</span> are as follows,
                                    <div>
                                        Weekday: ₹{doc.weekDayPrice}&nbsp;
                                        Friday: ₹{doc.fridayPrice}&nbsp;
                                        Saturday: ₹{doc.saturdayPrice}&nbsp;
                                        Sunday: ₹{doc.sundayPrice}&nbsp;
                                    </div>
                                    </strong>
                                    </em>
                                </small>
                            </div>
            
                            <div className="col-sm-4 text-end align-self-center">
                                <button type='button' onClick={handleSubmit} className="fw-bold advButton mx-3 ">Save</button>
                            </div>
                            
                        </div>
                    
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartPopup;
