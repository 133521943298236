import React from 'react'
import { projectFirestore } from './firebase/config'
import { useEffect } from 'react';
export default function Fetch() {
    let result=[];
    useEffect(()=>{
        let db=projectFirestore.collection('location');
//         db.get()
//         .then((snapshot)=>{
            
//             snapshot.docs.forEach(doc=>{
//                 result.push(doc.id);
//             })
//             //console.log(result);
//             // //console.log(snapshot);
//         })
// 
db.doc('alibaug').collection('stay').doc('cottage').collection('cottages').get().then((snapshot)=>{
    // //console.log(snapshot.docs);
    snapshot.docs.forEach((doc)=>{
        // //console.log(doc.data().siteName)

    })
    
})
    },[])
  return (
    <div>Fetch
        
    </div>
  )
}
