import {Link} from 'react-router-dom';
import {projectAuth , provider}  from '../firebase/config.js';
import {React,useState} from 'react';
import  firebase from 'firebase';
import { projectFirestore,timestamp } from '../firebase/config';

// import Signup from '../signup/Signup';
import { useLogin } from '../hooks/useLogin';
import { useSignup } from '../hooks/useSignup';
import google from "../assets/google.svg";
import phoneI from "../assets/phone.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
library.add( faTimes)

const CheckoutLogin = ({handleClose}) => {
    const [username,setUsername]=useState('');
    const [isLogin,setIsLogin]=useState(false);
    const [isPhoneLogin,setIsPhoneLogin]=useState(false);
    const [verification,setverfication]=useState(false)
    const [phone,setPhone]=useState('');
    const [email,setEmail]=useState('');
    const [otp,setotp]=useState('');
    const [final, setfinal] = useState('');
    const [showOtpField,setShowOtpField]=useState(false)
    const [showNumberFiled,setShowNumberFiled]=useState(true)
    const [contactExists,setContactExists] = useState(false)
    const [contactError,setContactError]=useState();
    const [alreadyUser , setAlreadyUser] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false)
    const [error, setError] = useState(null);
    const [password,setPassword]=useState('');
//     const { login, error, isPending } = useLogin();
    const {signup, errorSignup, isPendingSignup,signupwithgoogle } = useSignup();
    const [numberBool , setNumberBool] = useState(false);


    const checkPhoneNumber = (e) =>{
        e.preventDefault();
        let ref=projectFirestore.collection('users');
        let userNumber = 0
        ref.onSnapshot((snapshot)=>{
                let forCounter = 0
                snapshot.docs.forEach((doc)=>{
                        forCounter = forCounter + 1
                        if(doc.data().phoneNumber === Number(phone)){
                                setNumberBool(true)
                                setPhone('')
                                setAlreadyUser(true)
                                userNumber = phone
                        }
                })
                if(userNumber>0){
                        setContactExists(true)
                        // alert('This contact already exists!')
                }else{
                        setContactExists(false)
                        handlePhoneAuth(e)
                }

        })

    }

        //Phone Authentication
        const handlePhoneAuth=(e)=>{
                e.preventDefault()
                let number = '+91'+phone
                // e.preventDefault();
                let verify = new firebase.auth.RecaptchaVerifier('recaptcha');
                projectAuth.signInWithPhoneNumber(number, verify).then((result) => {
                        // signup(email,username ,number);
                        setfinal(result);
                        setShowOtpField(true);
                        alert("code sent")
                        
                }).catch((err) => {
                        alert(err);
                });
                                
        }
        
            //OPT validation
        const ValidateOtp=(filter)=>{
                if (otp === null || final === null)
                return;
            final.confirm(otp).then((result) => {
                setError(null)
                setIsPending(true)
                //console.log(filter ,' this is the fileter')
                if(filter === "signUp"){handleAddUser(email, username, phone, result.user.uid)
                        result.user.updateProfile({displayName : username})
                        result.user.updateProfile({email : email}) 
                }
               
                setIsPending(false)
                if (!isCancelled) {
                        setIsPending(false)
                        setError(null)
                }
                
                // success
            }).then(()=>{
                alert('successfully created!')
                // history.push("/home");
                window.location.reload();
            }).catch((err) => {
                alert("Wrong code");
            })
        }

        const handleAddUser  = (email, username, phone, uid) =>{
                firebase.firestore().collection('users').doc(uid).set({ 
                        //uid:uid, // this id will be the same with id generated in firebase authenticaiton 
                        email:email,
                        name:username,
                        phoneNumber: Number(phone),
                        createdAt:timestamp.fromDate(new Date()),
                })
        }
        
        const handleLoginPhoneAuth=()=>{
                var number='+91'+phone;
               
                let verify = new firebase.auth.RecaptchaVerifier('recaptcha');
                projectAuth.signInWithPhoneNumber(number, verify).then((result) => {
                        setfinal(result);
                        setShowOtpField(true);
                    alert("code sent")
                    
                })
                    .catch((err) => {
                        alert(err);
                        // window.location.reload()
                    });
        
            }
        
    
    return (  


                                    <div className="bg-light card  credentialCard border-0  my-5">
                                            <div className="card-body p-2  position-relative">
                                                        <div className='text-center' >
                                                                {!isLogin && !isPhoneLogin && <small className='text-center'>Have an Account ? <p onClick={()=>setIsLogin(true)} style={{"cursor":"pointer"}}><strong>Login In</strong></p></small>}
                                                                {isLogin &&  !isPhoneLogin && <small className='text-center'>Don’t have an Account ? <p onClick={()=>setIsLogin(false)} style={{"cursor":"pointer"}}><strong>Sign Up</strong></p></small>}
                                                        </div>
                        {/* Login  */}
                                                    {isLogin &&  !isPhoneLogin && <form onSubmit={handlePhoneAuth}>
                                                        <div className="row">
                                                                <div className="col-sm-2"></div>
                                                                <div className="col-sm-8">
                                                                        <div className="row">
                                                                                <div className="col-sm-12">
                                                                                <div className="form-floating mb-3">
                                                                                        <span className='text-dark'><strong><small>Phone Number</small></strong></span>
                                                                                        <input required type="number"  style={{"borderColor":"black"}}
                                                                                        onChange={(e)=>setPhone(e.target.value)}
                                                                                        value={phone}
                                                                                        className="form-control" id="floatingInput" />
                                                                                </div>
                                                                                {contactExists && <small className='text-danger fw-bold'>This contact already exists!</small>} 
                                                                                </div>
                                                                                <div className="col-sm-12">                                                    
                                                                                {showOtpField && 
                                                                                        <div>
                                                                                                <span className='text-dark'><strong><small>Enter your OTP</small></strong></span>
                                                                                                <input className="form-control" style={{"borderColor":"black"}} type="text" 
                                                                                                onChange={(e) => { setotp(e.target.value) }}></input>
                                                                                                        <br /><br />
                                                                                        </div>
                                                                                }
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-2"></div>
                                                        </div>
                                                            {error && <p className="text-danger">{error}</p>}
                                                            {!showOtpField && <div className="text-center signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type="submit">Generate OTP</button>
                                                                { <div id="recaptcha" className='text-webkit-center'></div>}      
                                                        </div>}
                                                        {showOtpField && !isPending && <div className="text-center signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" onClick={ValidateOtp}>Verify</button>
                                                        </div>}
                                                    </form>}

                        {/* sign up */}
                                                    {!isLogin && !isPhoneLogin && <form onSubmit={checkPhoneNumber}>
                                                            <div className="row">
                                                                <div className="col-sm-2"></div>

                                                                <div className="col-sm-8">
                                                                        <div className="col-sm-12">
                                                                                <div className="form-floating mb-3">
                                                                                        <span className='text-dark'><strong><small>Name</small></strong></span>
                                                                                        <input required type="text" style={{"borderColor":"black"}}
                                                                                        onChange={(e)=>setUsername(e.target.value)}
                                                                                        value={username}
                                                                                        className="form-control" id="input" placeholder="Your name" />
                                                                                </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                                <div className="form-floating mb-3">
                                                                                        <span className='text-dark'><strong><small>Email address</small></strong></span>
                                                                                        <input required type="email"  style={{"borderColor":"black"}}
                                                                                        onChange={(e)=>setEmail(e.target.value)}
                                                                                        value={email}
                                                                                        className="form-control" id="floatingInput" />
                                                                                </div>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                                <div className="form-floating mb-3">
                                                                                        <span className='text-dark'><strong><small>Phone</small></strong></span>
                                                                                        <input required type="number"  style={{"borderColor":"black"}}
                                                                                        onChange={(e)=>setPhone(e.target.value)}
                                                                                        value={phone}
                                                                                        className="form-control" id="password" placeholder="" />
                                                                                </div>
                                                                                {contactExists && <small className='text-danger fw-bold'>This contact already exists!</small>} 
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                        {showOtpField && 
                                                                                <div>
                                                                                        <span className='text-dark'><strong><small>Enter your OTP</small></strong></span>
                                                                                        <input className="form-control" style={{"borderColor":"black"}} type="text" 
                                                                                        onChange={(e) => { setotp(e.target.value) }}></input>
                                                                                                <br /><br />
                                                                                </div>
                                                                        }
                                                                        </div>
                                                                </div>
                                                                <div className="col-sm-2"></div>

                                                            </div>
                                                            {error && <p className="text-danger">{error}</p>}
                                                            {!showOtpField && !isLogin  && <div className="text-center signUpBtn">
                                                        { !alreadyUser &&  <div id="recaptcha" className='text-webkit-center'></div>} 
                                                        <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" type = "submit">Generate OTP</button>
                                                        </div>}
                                                                {showOtpField && !isPending && !isLogin &&  <div className="text-center signUpBtn">
                                                                <button className="bg-dark border-0 btn btn-primary btn-login text-uppercase fw-bold" onClick={ValidateOtp('signUp')}>Verify</button>
                                                        </div>}
                                                    </form>}

                                                   
                                                </div>
                                    </div>


    );
}
 
export default CheckoutLogin;