import { useCollection } from '../hooks/useCollection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React,{ useEffect,useState } from 'react'


const CouponComp = ({handleCouponOfList,handleCoupon,handleCouponChange,couponVal,couponError,couponPrice,handleAllCouponToggle,allCoupons}) => {
    const {documents,errors}=useCollection('coupon')
    
    return (
        <><div className="mt-2">Have a <strong>Promo Code</strong>/ <strong>Referral Code</strong>?</div>
        <div className="">
                                        <form onSubmit={handleCoupon} className="position-relative">
                                            <input type="text" value={couponVal} className="w-100 rounded p-md-2 p-1" onChange={(e)=>handleCouponChange(e)}/>
                                            <button className="position-absolute  submitPromo" >Submit</button>
                                        </form>
                                        {couponError && couponVal.length>0 && <small className="text-danger text-left">Oops,Invalid Coupon!</small>}
                                        {!couponError && couponPrice>0 && <small className="text-success text-left">Coupon Applied!</small>}
                                        <div className="row mt-2 justify-content-center">
                                            <div className="col-sm-12">
                                                {!allCoupons && <button className="text-dark text-right w-100 bg-transparent border-0" onClick={handleAllCouponToggle} >View Coupons <FontAwesomeIcon icon="angle-down" /></button>}
                                                {allCoupons && <button className="text-dark text-right w-100 bg-transparent border-0" onClick={handleAllCouponToggle}>Hide Coupons <FontAwesomeIcon icon="angle-up" /></button>}
                                            </div>

                                            {documents  && allCoupons && (
                                            <div className='row position-relative justify-content-center'>
                                                <div className="col-sm-12 p-1 all_promocodes_list shadow rounded-20 position-absolute">
                                                    {documents.map(doc=>(
                                                            <div key={doc.id} className="">
                                                                {!doc.promotional ?(
                                                                <div className='d-flex justify-content-between align-items-center border-1 py-2  single_promoCode rounded-20'>
                                                                    <p className="text-left mb-0 w-50">{doc.couponName}</p>
                                                                    <button className='submitPromo mt-0' onClick={()=>handleCouponOfList(doc.couponName)}>Apply Coupon</button>
                                                                </div>):null}
                                                            </div>
                                                    ))}
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                    </>                   
                                
     );
}
 
export default CouponComp;