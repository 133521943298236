import React,{ useRef,useEffect,useState } from 'react';
import { useCollection } from "../hooks/useCollection";
import { useAuthContext } from '../hooks/useAuthContext';
import {useParams,Link} from 'react-router-dom';
import { projectFirestore, timestamp } from '../firebase/config';
import { useActivityCollection } from "../hooks/useActivityCollection";
import mas1 from "../assets/waterfall.jpg";
import mas2 from "../assets/2.png";
import mas3 from "../assets/3.png";
import mas4 from "../assets/4.png";
import mas5 from "../assets/5.png";
import mas6 from "../assets/6.png";
import mas7 from "../assets/7.png";
import Slider from "react-slick";
import Tippy from '@tippyjs/react';
import userIcon from "../assets/userIcon.svg";
import activityIcon1 from "../assets/activityParachute.svg";
import cartScroll from "../assets/cartScroll.svg";
import activity1 from "../assets/activity1.png";
import "./stay.css";
import Footer from '../components/Footer';
import firebase from 'firebase/app';
import { useFirestore } from '../hooks/useFirestore';
import LoginModal from './LoginModal';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Modal from '../components/Modal';

const Activity = () => {
    const {activityType}=useParams();
    const {activityDocRes} = useActivityCollection('Cart');
    const {user}=useAuthContext()
    const [finalRes,setFinalRes]=useState(null)
    const {documents,error}=useCollection('location');
    const [filtered,setfiltered]=useState([]);
    const [activityName,setActivityName]=useState(null);
    const [clickedLocation,setClickedLocation]=useState(null);
    const [showLoginModal,setShowLoginModal]=useState(false);
    const { addDocument, response } = useFirestore('Cart');
    let cartActivityId=[ ];
    let cartActivityUserId;
    const activityRef=useRef(null)
    const stay = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll:1,
        arrows:true,
        adaptiveHeight:true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4,
                  slidesToScroll:1
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
    };
    const filterLocations = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll:1,
        arrows:true,
        adaptiveHeight:true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                  arrows: true,
                  centerMode: false,
                  centerPadding: '40px',
                  slidesToShow: 4,
                  slidesToScroll:1
                }
              },
              {
                breakpoint: 768,
                settings: "unslick"
                }
          ]
    };
    useEffect(()=>{
        activityRef.current?.scrollIntoView({ behavior: 'smooth' })

    },[activityType,clickedLocation])

        //to push wishlisted data in array of a particular user on refresh
        useEffect(() => {
            if(activityDocRes!==null){
                activityDocRes.forEach((each)=>{
                    if(user && user.uid===each.user_id && each.cartType === "activity"){
                        cartActivityUserId=user.uid;
                        cartActivityId.push(each.activitId);
                    }   
                })
            }
            // //console.log('this is the cart scene', cartActivityId)
          }, [activityDocRes,cartActivityId,cartActivityUserId,user,activityType])

    useEffect(() => {
        let ref=projectFirestore.collection('location');
        let resultLocation=[];
        const unsubscribeLocation=ref.onSnapshot((snapshot)=>{
            snapshot.docs.forEach((location)=>{
                resultLocation.push(location.id)     
            })
        
        let resultActs=[];
        let activityList=[];
         //Getting all the Stay Types
         resultLocation.forEach((eachLocation)=>{
            let reff=projectFirestore.collection('location').doc(eachLocation).collection("activity");
            const unsubscribe=reff.onSnapshot((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    activityList.push({...doc.data(),id:doc.id})     
                })
                // //console.log("activity",activityList)
                const uniqueArr = activityList.filter((activity, index, self) => 
                self.findIndex(a => a.id === activity.id) === index);
                //   //console.log(uniqueArr,"uniq")
               
                setActivityName(uniqueArr)
            })

        })
          //Getting all the documents from the selected activity
          resultLocation.forEach((eachLocation)=>{
            let ref2=projectFirestore.collection('location').doc(eachLocation).collection("activity").doc(activityType).collection(activityType);
            const unsubscribe=ref2.onSnapshot((snapshot)=>{
                snapshot.docs.forEach((doc)=>{
                    if(user && user.uid === cartActivityUserId){
                        if(cartActivityId.includes(doc.id)){
                            resultActs.push({...doc.data(),id:doc.id,addedToCart:true})     
                        }else{
                            resultActs.push({...doc.data(),id:doc.id,addedToCart:false})     
                        }
                    }else{
                        resultActs.push({...doc.data(),id:doc.id,addedToCart:false})     
                    }
                })
                setFinalRes(resultActs);
            })

        })
    })
        
        return () => {
            unsubscribeLocation();   
        };
    }, [clickedLocation,activityType,activityDocRes]);
   

    const handleLocationChange=(doc)=>{
        setClickedLocation(doc.id)
        const result=finalRes.filter((eachLoc)=>{
            return eachLoc.activityLocation===doc.id
        })
        
        setfiltered(result)

    }
    useEffect(() => {
        if(user && activityType && clickedLocation){
            let activityRef=projectFirestore.collection('location').doc(clickedLocation).collection('activity').doc(activityType).collection(activityType);
            activityRef.onSnapshot((snapshot)=>{
                let resultsStayUser=[];
                snapshot.docs.forEach((doc)=>{
                    if(user.uid===cartActivityUserId){
                        //to push users wishlisted data in new state
                        if(cartActivityId.includes(doc.id)){
                           
                            resultsStayUser.push({...doc.data(),id:doc.id,addedToCart:true})
                        }
                        else{
                            resultsStayUser.push({...doc.data(),id:doc.id,addedToCart:false})

                        }
                     }
                     else{
                        resultsStayUser.push({...doc.data(),id:doc.id,addedToCart:false})

                     }
                    
                })
                //update State
                setfiltered(resultsStayUser);
                // //console.log(stayRes,"user")
                
            },(error)=>{
                // setStayRes('could not fetch data')
            })

        }

        
    }, [clickedLocation,activityType,activityDocRes,user,response.success,response.document])

        // add activity to cart
        const handleActivityToCart = (activityId, activityPrice, activityDesc ,activityName,totalGuests,totalPrice,featuredImg)=>{
            addDocument({
                user_id:user.uid,
                activitId:activityId,
                featuredImg,
                activityPrice:activityPrice, 
                activityName:activityName,
                addedToCart:1,
                totalGuests:totalGuests,
                totalPrice:Number(totalPrice),
                createdAt:timestamp.fromDate(new Date()),
                cartType:'activity',
              })
            //   setTimeout(() => { setLoading(false) }, 500);
            NotificationManager.success('Activity Added To Cart', 'Success');
        }

            //   to close login modal popup
            const handleClose=()=>{
                setShowLoginModal(false)
            }
            // //console.log(' final final ',finalRes)
    return ( 

        <>
        <Modal/>
        { showLoginModal && <LoginModal handleClose={handleClose}/>}
        { !showLoginModal && <div>
            <div className="exploreClass d-flex justify-content-center align-items-start">
                <div className="searchDiv heroSectionSearch">
                    <div className="sectionTitle">
                        <h1 className="text-dark text-center fw-bold">Activity</h1>
                    </div>
                    <hr className="sectionHr m-auto text-center text-dark " style={{'width':'65%'}} />
                </div>
            </div>

        {/* all the activities */}
            <div className="wrapperDiv" >
            {window.location.href.split('/').pop()==="activity" && <small>Please select an Activity</small>}
            {window.location.href.split('/').pop()==="" && <small>Please select an Activity</small>}
                <div className="slider__div pt-md-5 text-webkit-center non_slicky">
                {activityName &&
                    <Slider {...stay}>
                        {activityName.map((doc)=>(<Link className='text-dark' to={doc.id}><div className = { doc.id === activityType ? "card slider__cards active__slider__cards" : "card slider__cards" } key={doc}>
                            <img className="slider__imgs" src={doc.activityCoverImg} alt="" />
                            <h4  style={{'textTransform':'capitalize'}}>{doc.id}</h4>
                        </div></Link>))}
                    </Slider>
                }
                </div>
            </div>
        {/* all the location filters */}
            <div className="wrapperDiv">
                {documents && <div className="slider__div slider__div2 non_slicky">
                    <Slider {...filterLocations}>
                        {documents.map((doc)=>(<div onClick={()=>handleLocationChange(doc)} key={doc.id} className ={doc.id === clickedLocation ? "card slider__cards activeLocation__slider__cards" : "card slider__cards" }>
                            <h4 className='mb-0'  style={{'textTransform':'capitalize'}}>{doc.id}</h4>
                        </div>))}
                    </Slider>
                </div>}
            </div>
        {/* all the results */}
         {finalRes && finalRes.length==0 && <h2 className='text-yellow fw-bold' style={{"textTransform":"capitalize"}}>No {activityType} Available</h2>}
                {finalRes  && filtered.length===0 && <div className="container-fluid px-3 explore parentDiv" ref={activityRef}>
                    <div >
                        <div className="row pb-5 text-webkit-center">
                            {finalRes.map((doc)=>(<div className="col-lg-4 col-md-6 col-sm-12 mb-5" key={doc.id}>
                                <div className="card all_activity_cards rounded-20 border-0 shadow" >
                                    <img src={doc.featuredImg} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-dark fw-bold">{doc.activityName}</h5>
                                            {!doc.paid &&<p className="card-text">Free</p>}
                                            {doc.paid &&<p className="card-text">Paid</p>}
                                        <div className="row">
                                            <div className="col-md-6 col-6"><Link target="_blank" to={`/activityDetails/${doc.activityLocation}/${activityType}/${doc.id}`}><button className="advButton">View More</button></Link></div>
                                            {user && !doc.addedToCart && <div className="col-md-6 col-6"><button onClick={()=> handleActivityToCart(doc.id,doc.price,doc.desc,doc.activityName,doc.totalGuests,doc.totalPrice,doc.featuredImg)}  className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                            {user && doc.addedToCart && <div className="col-md-6 col-6"><Link to={`/cart`}><button  className="advButton">Go To cart</button></Link></div>}
                                            {/* {!user && <div className="col-md-6 col-6"><button onClick={()=> setShowLoginModal(true)}  className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>} */}
                                            {!user && <div className="col-md-6 col-6"> <button type='button'  className="border-0 outline-0 bg-light" data-toggle="modal" data-target="#loginSignupModal" ><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>)) }

                        </div>
                    </div>
                </div>}

                { filtered.length>0 &&<div className="container-fluid px-4 explore parentDiv">
                    <div >
                        <div className="row pb-5 text-webkit-center">
                            {filtered.map((doc)=>(<div className="col-lg-4 col-md-6 col-sm-12 mb-5" key={doc.id}>
                                <div className="card all_activity_cards rounded-20 border-0 shadow" >
                                    <img src={doc.featuredImg} className="card-img-top" alt="..."/>
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-dark fw-bold">{doc.activityName}</h5>
                                            {!doc.paid &&<p className="card-text">Free</p>}
                                            {doc.paid &&<p className="card-text">Paid</p>}
                                        <div className="row">
                                            <div className="col-md-6 col-6"><Link to={`/activityDetails/${doc.activityLocation}/${activityType}/${doc.id}`}><button className="advButton">View More</button></Link></div>
                                            {user && !doc.addedToCart && <div className="col-md-6 col-6"><button onClick={()=> handleActivityToCart(doc.id,doc.price,doc.desc,doc.activityName,doc.totalGuests,doc.totalPrice,doc.featuredImg)}  className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                            {user && doc.addedToCart && <div className="col-md-6 col-6"><Link to={`/cart`}><button  className="advButton">Go To cart</button></Link></div>}
                                            {!user && <div className="col-md-6 col-6"><button onClick={()=> setShowLoginModal(true)}  className="border-0 outline-0 bg-light"><img className="navIcons" src={cartScroll} alt="" /></button></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>)) }

                        </div>
                    </div>
                </div>}
            <Footer/>
        </div>}
        </>


     );
}
 
export default Activity;