import React,{ useRef,useEffect,useState } from 'react'
import firebase from "firebase";
import { timestamp } from "../firebase/config"
import { useCollection } from '../hooks/useCollection'
import './details.css'
import ProductPopup from './ProductPopup'
import campImg1 from '../assets/pdp1.jpg'
import campImg5 from '../assets/pdp2.jpg'
import campImg4 from '../assets/pdp4.jpg'

import campImg2 from '../assets/pdp3.jpg'
import campImg3 from '../assets/pdp5.jpg'
import moreImg from '../assets/loc5.png'
import shareicon from '../assets/shareicon.png'
import wishlistIcon from '../assets/wishlistBlack.svg'
import activity1 from '../assets/activityParachute.svg'
import paragliding1 from '../assets/paragliding1.png'
import paragliding2 from '../assets/paragliding2.png'
import boat from '../assets/boat.svg'
import trek from '../assets/trek.svg'
import cottage from '../assets/cottage.png'
import tent from '../assets/tent.png'
import leo from '../assets/leoProfileImg.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import {Link} from 'react-router-dom';
import { useParams,Link,useHistory } from "react-router-dom";
import { projectFirestore } from '../firebase/config';
import Popup from './Popup';
import Modal from '../components/Modal';
import { useAuthContext } from '../hooks/useAuthContext';
import LoginModal from './LoginModal';
import { useFirestore } from '../hooks/useFirestore';
import { useFirestoreW } from '../hooks/useWishlistAdd';
import Loading from "react-fullscreen-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong,faPersonHiking,faWifi,faPizzaSlice,faTag,faAngleDown } from '@fortawesome/free-solid-svg-icons'
import Share from '../Share';
import Footer from '../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useStayCollection } from '../hooks/useStayCollection';

import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css"; 
import { isValid , addDays} from 'date-fns';


library.add( faArrowRightLong, faPersonHiking,faWifi,faPizzaSlice,faTag,faAngleDown)


function ProductDetails() {
const {locationId,stayid,quant,id}=useParams();
const quantity_localS=sessionStorage.getItem('local-storage-quantity');
const checkIn_localS=sessionStorage.getItem('local-storage-checkIn');
const checkOut_localS=sessionStorage.getItem('local-storage-checkOut');
const totalNights_localS=sessionStorage.getItem('local-storage-nights');
const {addDoc,res}=useFirestoreW('wishlist')
const [detailsRes,setDetailsRes]=useState(null);
const [quantity,setQuantity]=useState(quantity_localS ? JSON.parse(quantity_localS):1);
const [units,setUnits]=useState(2);
const [errors,setError]=useState(null);
const [checkIn,setcheckIn]=useState( isValid(checkIn_localS) ? JSON.parse(checkIn_localS) : new Date());

const minCheckOutDate = new Date(checkIn.getTime());
minCheckOutDate.setDate(checkIn.getDate() + 1);

const [checkOut,setcheckOut]=useState(isValid(checkOut_localS) ? JSON.parse(checkOut_localS) : minCheckOutDate);
const [price,setPrice]=useState(null);
const [nights,setnights]=useState(totalNights_localS ? JSON.parse(totalNights_localS) : 1);
const [dateError,setDateError]=useState(null);
const {user}=useAuthContext()
const [modalOpen,setModalOpen]=useState(false);
const [modalShareOpen,setShareModalOpen]=useState(false);
const { addDocument, response } = useFirestore('orders')
const [orderDetails,setOrderDetails]=useState(null)
const [cartDetails , setCartDetails] = useState(null)
const [scrollPosition,setScrollPosition]=useState('');
const [addToWishlist , setAddToWishlist] = useState(false)
const [showError,setShowError]=useState(null);
const [prodActivity , setProdActivity] = useState(null)
const [activityDetails , setActivityDetails] = useState({})
const [addedActivityKey , setAddedActivityKey] = useState({})
const [cartCheckActivityKey , setcartCheckActivityKey] = useState('');
const [hideCheckout , setHideCheckout] = useState(false);
// const { addToCart, cartResponse } = useFirestore('cart');
const [crossSaleProds , setCrossSaleProds] = useState(null)
const [crossSaleActivity , setCrossSaleActivity] = useState(null)
const [loading , setLoading] = useState(false);
const orderIds=[];
const productIds=[];
const [reviews,setReviews]=useState();
const [showShare,setModal]=useState(false);
const {documents,error}=useCollection('location');
const {documentsRes}=useStayCollection('wishlist')
const [wishListed,setWishlisted]=useState(false)
const [wishlistDoc,setWishlistDoc]=useState(null)
const [containerState,setContainerState]=useState(null)
const [popupData,setPopupData]=useState(null);
const [weeksdayCount,setWeekdaysCount]=useState(0);
const [fridaysCount,setFridaysCount]=useState(0);
const [saturdaysCount,setSaturdaysCount]=useState(0);
const [sundaysCount,setSundaysCount]=useState(0);
const [containerPrice, setContainerPrice] = useState(0);
const [startDatePicker , setStartDatePicker] = useState(new Date());
let wishlistProdId=[ ];
let wishlistUserId;
const [totalDaysCount,setTotalDaysCount]=useState(0);

let weekday=0;
let friday=0;
let saturday=0;
let sunday=0;
var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
let count;


const ref1 = useRef();
const ref2 = useRef();
const campImages = {
  speed: 900,
  slidesToShow: 1,
  slidesToScroll:1,
  arrows:true,
}
const reviewCards = {
        dots: true,
        infinite: true,
        autoplay:true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll:1,
        arrows:true,
        responsive: [
          {
              breakpoint: 1366,
              settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 3
              }
            },
            {
              breakpoint: 1141,
              settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '0%',
                slidesToShow: 3
              }
            },
          {
              breakpoint: 1025,
              settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 2
              }
            },
            {
              breakpoint: 991,
              settings: {
                arrows: true,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 2,
                dots:false
              }
            },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              centerMode: false,
              centerPadding: '40px',
              slidesToShow: 2
            }
          },
          {
              breakpoint: 576,
              settings: {
                arrows: false,
                centerMode: false,
                centerPadding: '40px',
                slidesToShow: 2
              }
            },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: false,
              centerPadding: '40px',
              slidesToShow: 1
            }
          }
        ]
      }
      const addPopupData=(data)=>{
        setPopupData(data)
      }
      useEffect(() => {
        if (containerState){
          let startDate=new Date(checkIn);
          let endDate=new Date(checkOut);

          while(startDate < endDate){

            var dayName = days[new Date(startDate).getDay()];
        
            if(dayName==="Friday"){
              
                friday=friday+1;
            }
            else if(dayName ==="Saturday"){
            
            saturday=saturday+1;
            }
            else if(dayName ==="Sunday"){
                
                sunday=sunday+1;
              }
              else{
                weekday=weekday+1;
              }
        
            startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1); 
          }
          setWeekdaysCount(weekday);
          setFridaysCount(friday);
          setSaturdaysCount(saturday);
          setSundaysCount(sunday);
          setTotalDaysCount(weekday+friday+saturday+sunday)
          // //console.log(weekday,friday,saturday,sunday)
        
        }

      }, [checkIn,checkOut,popupData]);

      useEffect(() => {
        if(containerState && popupData){
          var roomPrice = (((containerState.weekDayPrice)*weeksdayCount)+(containerState.fridayPrice*fridaysCount)+(containerState.saturdayPrice*saturdaysCount)+(containerState.sundayPrice*sundaysCount))*popupData.rooms
          var mattressPrice = (containerState.matressChildrenPrice*(popupData.childrenMatress<0?0:popupData.childrenMatress))*totalDaysCount + (containerState.matressAdultPrice*(popupData.adultMatress<0?0:popupData.adultMatress)*totalDaysCount)
          setContainerPrice(roomPrice+mattressPrice)
        }
      }, [popupData,weeksdayCount,fridaysCount,saturdaysCount,sundaysCount]);
      
      //To get all wishlisted id's
      useEffect(() => {
        if(documentsRes!==null){
            documentsRes.forEach((each)=>{
                if(user && user.uid===each.user_id){
                  wishlistProdId.push(each.product_id);
                  wishlistUserId=user.uid;
                   
                    
                }   
            })
        }
        // //console.log(wishlistProdId,wishListed,id,"isme saare id hai")
      }, [documentsRes,wishlistProdId,wishlistUserId,user,res.success])

      useEffect(() => {
        handleNightsChange()  
    }, [checkIn,checkOut,nights]);
      useEffect(() => {
      
        let ref=projectFirestore.collection('location').doc(locationId).collection('stay').doc(stayid).collection(stayid);
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
          let results=[];
          snapshot.docs.forEach((doc)=>{
            if(doc.id===id){
              results.push({...doc.data(),id:doc.id})
              setContainerState({...doc.data(),id:doc.id})
            }
          })
          if(results[0]['activity']===true){
            let activityArray = []
            activityArray.push(results[0]['activities'])
            let activitiesArray= []
            activitiesArray.push(activityArray[0])
            // //console.log('length of activity',activityArray[0])
             // array to be fetched in for loop
              let activityKeys = Object.keys(activityArray[0]) // getting keys of the array
              let prodActivity = [ ]
            
              activitiesArray.forEach(each=>{
                activityKeys.forEach(key=>{
                  let activityRef = projectFirestore.collection('location').doc(locationId).collection('activity').doc(key).collection(key);
                  const getActivity = activityRef.onSnapshot((snapshot)=>{
                    snapshot.docs.forEach((doc)=>{
                      each[key].forEach(ek=>{
                        if(ek === doc.id){
                          prodActivity.push({...doc.data(),id:doc.id})
                          // //console.log('hey we are matching with our keys here',ek + ' === '+doc.id)
                        }
                      })
                    })
                    setProdActivity(prodActivity) // setting up the product activity array here
                  })
                })
              })  
          }
          setDetailsRes(results);
          
          // setProdActivity(activitiesArray)
          setError(null);
          const getCrossSale = ref.onSnapshot((snapshot)=>{ // here this ref for stays only which is used above
            let crossSaleProd = [ ]
            snapshot.docs.forEach((crossDoc)=>{
              if(crossDoc.id !== id){
                crossSaleProd.push({...crossDoc.data(),id:crossDoc.id})
                // //console.log('here is the cross sale id ',crossDoc.id)
              }
            })
            
            setCrossSaleProds(crossSaleProd.slice(0,3));
            // console.log(crossSaleProds,'this is the cross sale product array')
          })

          let cross2 = projectFirestore.collection('location').doc(locationId).collection('activity');
          const getCrossSaleAct = cross2.onSnapshot((snapshot)=>{
            let crossSaleAct = [ ]
            let crossSaleKeys = [ ]
            snapshot.docs.forEach((crossAct)=>{
                crossSaleKeys.push(crossAct.id)
                  crossSaleKeys.forEach(key=>{
                      let activityRef1 = projectFirestore.collection('location').doc(locationId).collection('activity').doc(key).collection(key);
                      const getActivity = activityRef1.onSnapshot((snapshot)=>{
                        snapshot.docs.forEach((doc)=>{
                            if(doc.data().activityLocation === locationId){
                              crossSaleAct.push({...doc.data(),id:doc.id,activityType:key})
                            }
                        })
                        setCrossSaleActivity(crossSaleAct.slice(0,3))
                        // //console.log('this ist the ',crossSaleActivity)
                      })
                  })
            })
          })

      },(error)=>{
          setError('could not fetch data')
      })
      
      // fetching the activities not included in product
      let activityRef=projectFirestore.collection('location').doc(locationId).collection('activity');
      const fetchActivityId  = activityRef.onSnapshot((snapshot)=> {
        let activityIds = [ ];
          snapshot.docs.forEach((activityDoc)=>{
              activityIds.push(activityDoc.id)
          })
          activityIds.forEach(act=>{
            let activityDetialsRef = projectFirestore.collection('location').doc(locationId).collection('activity').doc(act).collection(act);
            const fetchActivityDetails = activityDetialsRef.onSnapshot((snapshot)=>{
              let activityDetails = [ ]
              snapshot.docs.forEach((detailDoc)=>{
                activityDetails.push(detailDoc.data())
              })
            })
          })
      },(error)=>{
        setError('Error Fetching Activities')
      })
      //order details

      return()=>{unsubscribe() ; fetchActivityId(); };
      }, [ id,locationId,stayid,setOrderDetails]);

      const handleDecrement=(quantity)=>{
        let newQuantity;
        if(quantity===1){
            newQuantity=Number(quantity)-1;
        }
        else{
            newQuantity=Number(quantity)-1;
            setQuantity(newQuantity);
    
        }
        
     }
     const handleIncrement=(quantity)=>{
        let newQuantity=Number(quantity)+1;
        setQuantity(newQuantity)
    }
    const diffDays = (date, otherDate) => {
      return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
  };
  const handleNightsChange=()=>{
    setnights(diffDays(checkIn, checkOut));
     setDateError(null); 

  }
  const handleCheckIn=(date)=>{
    setcheckIn(date)
    handleNightsChange();

  }
  const handleCheckOut=(date)=>{
    setcheckOut(date)
    handleNightsChange();

  }
  useEffect(() => {

    setLoading(true)
    // to chceck if product is in cart 
    if(response.success){
      let ref=projectFirestore.collection('orders')
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
          snapshot.docs.forEach((doc)=>{
            orderIds.push(doc.id)
              productIds.push(doc.data().productId)

          })
          
          productIds.forEach((id)=>{
            // //console.log("id",id)
          })
      },(error)=>{
          setError('could not fetch data')
      })
     
      //  window.location.href = '/checkout/'+locationId+"/"+stayid+"/"+id+"/"+user.uid;
    }
    setTimeout(() => { setLoading(false) }, 500);
  }, [response.success]);
const handlePrice=()=>{
  setPrice(document.getElementById('myPrice').value)
}
      // add to cart 
      const handleAddToCart = (siteDesc,quant,siteName,cost,tentSize,featuredImg,stayType,doc) => {
        setLoading(true)
        handlePrice();
        if(stayType==='container'){
          firebase.firestore().collection('Cart').add({
            product_id:id,
            user_id:user.uid,
            featuredImg,
            location:locationId,
            stay_type:stayid, 
            checkInDate:String(checkIn),
            checkOutDate:String(checkOut),
            totalPrice:containerPrice,
            addedToCart:1,
            createdAt:timestamp.fromDate(new Date()),
            description:siteDesc,
            siteName:siteName,
            cartType:'stay',
            containerData:popupData,
            stayCategory:stayType,
            maxPeople:doc.maxPeople,
            minPeople:doc.minPeople,
            fridayPrice:doc.fridayPrice,
            saturdayPrice:doc.saturdayPrice,
            sundayPrice:doc.sundayPrice,
            weekDayPrice:doc.weekDayPrice,
            matressAdultPrice:doc.matressAdultPrice,
            matressChildrenPrice:doc.matressChildrenPrice,
            childrenAgeCriteria:doc.childrenAgeCriteria
          })

        }else{
          firebase.firestore().collection('Cart').add({
            product_id:id,
            user_id:user.uid,
            featuredImg,
            location:locationId,
            stay_type:stayid, 
            checkInDate:String(checkIn),
            checkOutDate:String(checkOut),
            totalPrice:document.getElementById('myPrice').value,
            totalNights:nights,
            totalGuests:quant,
            addedToCart:1,
            createdAt:timestamp.fromDate(new Date()),
            description:siteDesc,
            siteName:siteName,
            cost:cost,
            tentSize:tentSize,
            cartType:'stay',
          })
        }
          setTimeout(() => { setLoading(false) }, 500);
          setCartDetails(true);
      }

  const handleCheckout=()=>{
    if(!user){
      setModalOpen(true);
    }
    else{
      setModalOpen(false);
      productIds.forEach((pId)=>{
        if(id===pId){
          let data = {
            email:user.email,
          name:user.displayName,
           productId:id,
          uid:user.uid,
          numberOfNights:nights,
          checkInDate:checkIn,
          checkOutDate:checkOut,
          totalPeople:quantity,
          location:locationId,
          stayCategory:stayid,
          price:price
          };
          orderIds.forEach((oId)=>{
            projectFirestore.collection("orders").doc(oId).set(data);
          })
          

        }
      })
        addDocument({
          email:user.email,
          name:user.displayName,
           productId:id,
          uid:user.uid,
          numberOfNights:nights,
          checkInDate:checkIn,
          checkOutDate:checkOut,
          totalPeople:quantity,
          location:locationId,
          stayCategory:stayid,
          price:price,
        })
        
    }
  }
  
  const getValue=()=>{
    if(document.getElementById('myInput').value>0){
        return document.getElementById('myInput').value;
    }
  }
  const handleSubmit=async (e)=>{
    e.preventDefault();
     setnights(diffDays(new Date(checkIn), new Date(checkOut)));
     setDateError(null); 
     let val= await getValue();
     handleChange();
    //  setPrice(val)
}

  
  const handleClose=()=>{
    setModalOpen(false);

  }
  const handleShareClose=()=>{
    setShareModalOpen(false);
    // //console.log(modalShareOpen)
  }
  const handleOpenShare=()=>{
    setShareModalOpen(true);
  }
  const handlePopup=()=>{
    setModalOpen(true);
  }
  const handleChange=()=>{
    setPrice(document.getElementById('myInput').value)
    
  }
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() ).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
};


const handleScroll = (scrollPosition) => {
  const position = window.pageYOffset;
  if(position>1663.199951171875){
      setScrollPosition(1)
  }else{
    setScrollPosition(0)
  }
};

useEffect(() => {
  window.addEventListener('scroll', handleScroll);

  return () => {
      window.removeEventListener('scroll', handleScroll);
  };
}, []);
const toggleModal = () => {
  setModal({ showShare: !showShare })

  // //console.log("called")
}

//  add to wishlist functionality 

    useEffect(() => {
      // check if the product is in wishlist or not 
      let productId = id
      let ref = projectFirestore.collection('wishlist')
      const  deleteWishlist=ref.onSnapshot((snapshot)=>{
          snapshot.docs.forEach((doc)=>{
              if(user && user.uid===doc.data().user_id){
                if(doc.data().wishlisted===1){
                  setAddToWishlist(true)
                  // //console.log('yes this product is in wishlist ',)
                }else{
                  setAddToWishlist(false)
                  // //console.log('this product is not wishlist')
                }
              }
          })
      },(error)=>{
          showError('could not fetch data')
      })
      // check if the product is in cart or not 
      let activityRef = projectFirestore.collection('Cart')
      const activityCartCheck = activityRef.onSnapshot((snapshot)=>{
        snapshot.docs.forEach((activity)=>{
          if(user && user.uid === activity.data().user_id && activity.data().cartType==='activity' && activity.data().activityProductId === id){
            setActivityDetails(activity.data().activitId)
            setcartCheckActivityKey(activity.data().activitId)
            setHideCheckout(true)
            setAddedActivityKey(state => ({
              ...state, // <-- copy previous state
              [activity.data().activitId]: !state[activity.data().activitId] // <-- update value by index key
            }));
          }
        })
        // //console.log('cart check activityId ',cartCheckActivityKey)
      })

    }, [ user ])
    // add to wishlist 
    const handleAddToWishlist = (productId,locationId,stayId) => {
      setLoading(true)
      addDoc({
        product_id:productId,
        user_id:user.uid,
        location:locationId,
        createdAt:timestamp.fromDate(new Date()),
        stay_type:stayId, 
        wishlisted:1,
      })
      
      // firebase.firestore().collection('wishlist').add({
      //   product_id:productId,
      //   user_id:user.uid,
      //   location:locationId,
      //   createdAt:timestamp.fromDate(new Date()),
      //   stay_type:stayId, 
      //   wishlisted:1,
      // })
      // setWishlisted(true)
      setAddToWishlist(true);
     setTimeout(() => { setLoading(false) }, 500);
    }
    useEffect(() => {
      // //console.log(res.success,"suc")
      if (res.success) {
        setWishlisted(true)
      }else{
        
      }
      if(wishlistProdId){
        if(wishlistProdId.includes(id)){
          setWishlisted(true)
        }else{
          // //console.log('esle')
          setWishlisted(false)
  
        }

      }
      
      // //console.log(wishListed)
    }, [res.success,documentsRes])
  
    const handleRemoveFromWishlist = (productId)=>{
   
     setLoading(true)
    
    // documentsRes.forEach((doc)=>{
    //   if(user.uid===wishlistUserId){
    //     if(wishlistProdId.includes(id)){
    //       projectFirestore.collection('wishlist').doc(doc.id).delete();

    //     }
    //   }
    // })
      let ref = projectFirestore.collection('wishlist')
      documentsRes.forEach((doc)=>{
        if(user.uid===doc.user_id){
            if(wishlistProdId.includes(id)  && id===doc.product_id){
                projectFirestore.collection('wishlist').doc(doc.id).delete().then(()=>setLoading(false));

            }

        }

    })

        setTimeout(() => { setLoading(false) }, 500);
        // window.location.reload();
    }
    // add activity to cart
    const handleActivityToCart = (activityId, activityPrice, activityName,featuredImg)=>{
      setLoading(true)
      // //console.log('hey there ', activityId + ' = ' + activityPrice + ' = ' + activityName)
      firebase.firestore().collection('Cart').add({
          user_id:user.uid,
          activitId:activityId,
          totalGuests:1,
          featuredImg,
          activityPrice:activityPrice,
          totalPrice:activityPrice, 
          activityName:activityName,
          addedToCart:1,
          createdAt:timestamp.fromDate(new Date()),
          cartType:'activity',
          activityProductId:id // to verify in which product the activity is added to cart 
        })
        setAddedActivityKey(state => ({
          ...state, // <-- copy previous state
          [activityId]: !state[activityId] // <-- update value by index key
        }));
        setTimeout(() => { setLoading(false) }, 500);
        setActivityDetails(activityId);
        setHideCheckout(true);
        setLoading(false)
  }
  // useEffect(() => {
  //   let refWishlist=projectFirestore.collection('wishlist');
  //   if(user){
  //     const unsubWishlist=refWishlist.onSnapshot((snapshot)=>{
  //       let wishlistRes=[];
  //       snapshot.docs.forEach((eachDoc)=>{
  //         if(user.uid===eachDoc.data().user_id && eachDoc.data().product_id===id ){
  //           setWishlisted(true)
           
  //           wishlistRes.push({...eachDoc.data(),id:eachDoc.id});
            
  //         }

  //       })
  //       //console.log("wih",wishlistRes)
  //   setWishlistDoc(wishlistRes);
  //     })
      
  //   }
  // }, [id])
useEffect(() => {
  let reviewRef = projectFirestore.collection('reviews')
  const  reiews=reviewRef.onSnapshot((snapshot)=>{
    let reviewsArr=[]
    snapshot.docs.forEach((doc)=>{
      if(doc.data().productId===id){
        reviewsArr.push({...doc.data(),id:doc.id});
      }
      
    })
    // //console.log("reviews",reviewsArr)
    setReviews(reviewsArr);
},(error)=>{
    showError('could not fetch data')
})

}, [id])

useEffect(()=>{ 
    sessionStorage.setItem('local-storage-quantity',JSON.stringify(quantity))
    sessionStorage.setItem('local-storage-checkIn',JSON.stringify(checkIn))
    sessionStorage.setItem('local-storage-checkOut',JSON.stringify(checkOut))
    sessionStorage.setItem('local-storage-nights',JSON.stringify(nights))
 
},[quantity,checkIn,checkOut,nights])
// //console.log(quantity)
// //console.log('det',detailsRes)

  return (
    <div>
      <Modal/>  
    { loading && <Loading loading={true} background="#000000c4" loaderColor="#ffe23f" />}
    {modalShareOpen && <div> <Share isOpen={modalShareOpen}  handleClose={handleShareClose}/> </div>}
    {modalOpen &&<div> <LoginModal handleClose={handleClose}/></div>}
    {!modalOpen   && <div className="container-fluid productDetails">
        <div className='row webView' style={{ "display": "none" }}>
          <div className='imageSection d-flex col-sm-12'>
            <div className='imageSection d-flex col-sm-12'>
              <div className='images col-md-7 col-sm-12'>
                <img alt='' src={campImg1} width="100%"></img>
              </div>
              <div className='images col-md-3 col-sm-12'>
                <img style={{ "paddingBottom": "15px" }} alt='' src={campImg2} width="100%"></img>
                <img alt='' src={campImg3} width="100%"></img>
              </div>
              <div className='images img3 col-md-2'>
                <img className='viewMoreImage' alt='' src={moreImg} width="100%"></img>
                <div className='viewMoreText'> <span >View More</span></div>
              </div>
            </div>
          </div>
        </div>

        {containerState && (<div className='responsive-carousel mx-4'>
            <Carousel infiniteLoop dynamicHeight={false} showStatus={false} showArrows={true} showIndicators={false}>
              {containerState.photoGallery && containerState.photoGallery.map((eachPhoto)=>(<div className="image" key={eachPhoto}>
                <img src={eachPhoto} alt="" />
              </div>))}
             
            </Carousel>
        </div>)}

    <div className='innerProductDetails justify-content-center'>
    {detailsRes && (<div className='descriptionSection'>
      {detailsRes.map(doc=>(<div>
          <div key={doc.id}>
        <div className='row'>
            <div>
          <div className='col-xl-12 col-lg-12 col-sm-12'>
              <div className='headdingNamesArea d-flex'>
                <h1 className="productName fontbold mx-4 mx-lg-0 pt-2">{doc.siteName}</h1>
                
                
                
                <div className="buttonSection">
                    <button type='button' className='shareButton commonButton button mx-3' data-toggle="modal" data-target="#myModal"  onClick={handleOpenShare}>
                    <span className='icons'><img src={shareicon} width="20px" alt=''/></span> <span  className='btn_text'>Share</span>
                    </button>
                    { user && !wishListed && <button onClick={()=> handleAddToWishlist(doc.id,locationId, stayid)} type='button' className='wishlist commonButton button mx-3'>
                      <span className='icons'><img src={wishlistIcon} width="20px" alt=''/></span> <span  className='btn_text'>Wishlist</span>
                    </button>}
                    { !user && !addToWishlist && <button onClick={()=> setModalOpen(true)} type='button' className='wishlist commonButton button mx-3'>
                      <span className='icons'><img src={wishlistIcon} width="20px" alt=''/></span> <span  className='btn_text'>Wishlist</span>
                    </button>}
                    { user && wishListed && <button onClick={()=> handleRemoveFromWishlist(doc.id,locationId, stayid)} type='button' className='wishlist  commonButton button mx-3'>
                      <span className='icons'><img src={wishlistIcon} width="20px" alt=''/></span> <strong><span  className='btn_text'>Wishlisted</span></strong>
                    </button>}
                </div>
              </div>
              {/* Share body */}
              {/* <div className="collapse" id="collapseExample">
                  <div className="card card-body"> */}
                  
                  {/* </div>
              </div> */}
              <div className="subHeadArea">
                <h4 className="location mx-4 mx-lg-0">{doc.siteLocation}</h4>
              </div>
              
          </div>
          </div>
        </div>
        </div>
        
        <div className='description col-lg-11 col-md-12 col-sm-12'>
          {doc.siteDesc}
        </div>
         <div className='suppliesSection row mg-t-20'>
            { doc.amenities &&<div className="col-lg-11 col-md-12 col-sm-12 amenitiesSection cardBox">
                  <h2 className='text-left text-bold-6'>Amenities</h2>
                  { doc.amenities && (<div className='row'>
                    {doc.amenities.map((amenity)=>(
                    <div className='col-xl-3 col-lg-3 col-md-3 supplylists' key={amenity}>
                        <div className="d-inline-block">
                          <span className="amenititesText basicSubText"><FontAwesomeIcon icon="fa-solid fa-wifi" /> <small>{amenity}</small></span>
                        </div>
                    </div> ))}
                  </div>)}
                  {/* <div className="linkBtn mg-t-20"><a id="moreDetails" style={{"marginLeft":"1em"}}>More Details</a></div> */}
              </div>}
          { doc.foodBeverages && <div className=" col-lg-11 col-md-12 col-sm-12 foodSection cardBox mg-t-20">
              <h2 className='text-left text-bold-6'>Food and Beverages</h2>
              { doc.foodBeverages && (<div className='row'>
                {doc.foodBeverages.map((foodBeverage)=>(
                <div className='col-xl-3 col-lg-3 col-md-3 supplylists' key={foodBeverage}>
                    <div className="d-inline-block">
                      <span className="amenititesText basicSubText"><FontAwesomeIcon icon="fa-solid fa-pizza-slice" /> <small>{foodBeverage}</small></span>
                    </div>
                </div> ))}
              </div>)}
          </div>}
          {doc.adventrioSpecs && <div className=" col-lg-11 col-md-12 col-sm-12 foodSection cardBox mg-t-20">
              <h2 className='text-left text-bold-6'>Adventrio Specs</h2>
              { doc.adventrioSpecs && (<div className='row'>
                {doc.adventrioSpecs.map((adventrioSpec)=>(
                <div className='col-xl-3 col-lg-3 col-md-3 supplylists' key={adventrioSpec}>
                    <div className="d-inline-block">
                      <span className="amenititesText basicSubText"><FontAwesomeIcon icon="fa-solid fa-tag" /> <small>{adventrioSpec}</small></span>
                    </div>
                </div> ))}
              </div>)}
          </div>}
          {doc.activities && <div className=" col-lg-12 col-md-12 col-sm-12 activitiesSection mg-t-20">
              <h2 className='text-left text-bold-6 mx-md-4 mx-4 mx-lg-0
              '>Activities</h2>
              <h5 className='mx-md-4 mx-4'>Offered on the Hosts property or nearby</h5>
              {prodActivity && <div className='row activity_row'>
                {prodActivity.map((activity,index)=>(<div className='col-lg-3 position-relative col-sm-3 cardBox activity text-center'>
                    <img src={activity1}  alt=''></img>
                    <h6 className='mg-t-10'>
                      <p className='activity__name'>{activity.activityName}</p>
                    </h6>
                    {user && activity.paid && <button type='button' onClick={()=> handleActivityToCart(activity.id,activity.price,activity.activityName,activity.featuredImg)}  className="fw-bold advButton paidbtn mx-2">Add To Cart</button>}
                    {user && (addedActivityKey[activity.id] || cartCheckActivityKey === activity.id)  && <button type='button'  className="fw-bold advButton paidbtn mx-2">Added To Cart</button> }
                    {/* {!user && activity.paid && <button type='button' onClick={()=> setModalOpen(true)}  className="fw-bold advButton paidbtn mx-2">Add To Cart</button>} */}
                    {!user && activity.paid && <button type='button'  className="fw-bold advButton paidbtn mx-2" data-toggle="modal" data-target="#loginSignupModal" >Add to Cart</button>}
                    {!activity.paid && <button type='button' className="fw-bold advButton freebtn mx-2">Free</button>}

                </div>))}
              </div>}
          </div>}
          {crossSaleProds && crossSaleProds.length>0 && <div className="col-xl-11 col-lg-11 col-md-12 col-sm-12 underSection mg-t-20">
              <div className='col-lg-12 col-sm-12'>
                <h2 className='text-left text-bold-6'>Under the same hood</h2>
                <h5>More accommodations from the same property</h5>
                {crossSaleProds && 
                  <div className='row'>
                      {crossSaleProds.map(crossProd=>(
                        <div className='col-lg-3 col-md-3 col-sm-3 col-6 propertyHood under_the_hood mx-md-1'>
                          <Link className='linksFor' to = {`/details/${locationId}/${stayid}/${crossProd.id}`}>
                              <img src={doc.featuredImg} alt=''></img>
                              <p className='mg-t-10'>{crossProd.siteName}</p>
                          </Link>
                        </div>
                      ))}
                  </div>
                }
              </div>
          </div>}
          <div className=" col-lg-11 col-md-12 col-sm-12 nearbySection mg-t-20">
              <div className='col-lg-12 col-sm-12'>
                <h2 className='text-left text-bold-6'>Activities Nearby</h2>
                {crossSaleActivity && 
                  <div className='row'>
                    {crossSaleActivity.map(crossSaleAct=>(
                      <div className='col-lg-3 col-md-3 col-sm-3 col-6 propertyHood under_the_hood mx-md-1' >
                        <Link className='linksFor' to = {`/activityDetails/${locationId}/${crossSaleAct.activityType}/${crossSaleAct.id}`}>
                          <img src={doc.featuredImg} alt=''></img>
                          <p className='mg-t-10'><small>{crossSaleAct.activityName}</small></p>
                        </Link>
                      </div>
                    ))}
                  </div>
                }
              </div>
          </div>
          
         
        </div>
        </div>))}
    </div>)}
    {/* detail card */}
    {  containerState && (
        <div className={scrollPosition ? "hidee serviceCostSection col-sm-4":"serviceCostSection col-sm-4"}  >

          {/* // For desktop View */}
            <>
             <ProductPopup doc={containerState} addPopupData={addPopupData}/>
          {<div className='innerSectionServiceCost desktop_show'>
              <div className='costSection1'>
                <div className='text-left text-bold-6' >
                {!isNaN(nights) && containerState.stayType !=="container" && <h3>₹<span id="myH3">{(containerState.price*nights)*quantity}</span> </h3>}
                { containerState.stayType ==="container" && <h3>₹<span id="myH3">
                  { popupData?containerPrice:(containerState.saturdayPrice)}
                  </span>  
                  </h3>}
                  <input type="hidden" id="myPrice" value={(containerState.price*(isNaN(nights)?0:nights))*quantity} />
                </div>
                <div className='text-right'>
                    {!isNaN(nights) && <div className="">
                      { containerState.stayType !=="container" && <small>Total Nights: {nights}</small>}
                    </div>}
                    { <div className="">
                      { containerState.stayType ==="container" && <small>Minimum Nights: 1</small>}
                    </div>}
                    { containerState.stayType !=="container" && <small><strong>{(containerState.price)}/Night</strong></small> }
                    
                </div>
              </div>
            <hr className=''/>
              {containerState.stayType!=="container" && <div className="text-start d-flex align-items-center ">
                <span>Total Guest</span>
                  <div className="qty text-end text-dark">
                      <button  onClick={()=>handleDecrement(quantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                      <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={quantity} /> 
                      <button onClick={()=>handleIncrement(quantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                  </div>
              </div>}
              
            
              {/* <form onSubmit={handleSubmit}> */}
              {/* <input type="hidden" id="myPrice"  value={(doc.price*nights)*units}/> */}
              <div className='dateSection mt-3'>
                <div className='checkDiv position-relative'>
                    <h6>
                      <p className='text-left'>Check In</p>
                    </h6>
                    <DatePicker selected={checkIn} onKeyDown={e => e.preventDefault()} onChange={(date)=> handleCheckIn(date)} dateFormat="dd/MM/yyyy"  minDate={new Date()}   />  
                    {/* <input value={checkIn} onChange={handleCheckIn} min={disablePastDate()} required id='checkedInDate' name='checkedInDate' type="date" ref={ref1} onFocus={() => (ref1.current.type = "date")}
                    onBlur={() => (ref1.current.type = "date")} className='dateInput' placeholder='Select a date'></input> */}
                </div>
                <div className='checkDiv position-relative'>
                    <h6>
                      <p className='text-left'>Check Out</p>
                    </h6>
                    {/* <input  value={checkOut} onChange={handleCheckOut} min={checkIn}  required id='checkedOutDate' name='checkedOutDate' type="date" ref={ref2} onFocus={() => (ref2.current.type = "date")}
                    onBlur={() => (ref2.current.type = "date")} className='dateInput' placeholder='Select a date'></input> */}
                    <DatePicker  selected={checkOut} onFocus={(e) => e.target.readOnly = true} onChange={(date)=> handleCheckOut(date)} dateFormat="dd/MM/yyyy" minDate={minCheckOutDate}  maxDate={addDays(new Date(), 90)}   />  
                </div>
                {/* <button className='subCheckDates'><FontAwesomeIcon icon=" fa-arrow-right-long" /></button> */}
              </div>
              <hr className=''/>
              {/* Guest Section */}
              {containerState.stayType==="container" && <div className="row" data-target="#productModal" data-toggle="modal">
                <div className="col-sm-10">
                  {containerState.stayType==="container" && 
                  <p type='button'  className="fw-bold mx-2 text-start mb-0" >Guests</p>}
                  <div className='mx-2'>
                    {popupData && 
                      <small>
                        <span>{popupData.adults} Adult(s)</span>
                        <span>, {popupData.children} Children</span>
                        <span>, {popupData.rooms} Room(s), </span> 
                        <span> {popupData.totalMatress >=0?popupData.totalMatress:0} Matress(s)</span>
                      </small>
                    }
                    </div>
                </div>
                <div className="col-sm-2 text-end" >
                  <FontAwesomeIcon style={{'fontSize':'22px'}} icon="fa-angle-down fa-angle-down" />
                </div>
              </div>}

              {/* </form> */}
              <div className={hideCheckout? "onlyCartButton mg-t-10 ": "buttonSection mg-t-10 "}>
              { !hideCheckout && !nights && user && containerState.stayType!=="container" &&<button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                { !hideCheckout && !nights && !user && containerState.stayType!=="container"  &&<button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                { !hideCheckout && !nights && !user && containerState.stayType==="container"  &&<button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                { !hideCheckout && !nights && user  && containerState.stayType==="container" && !popupData && <button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}
                {!hideCheckout && (isNaN(nights)?'':nights) && containerState.stayType==="container" && !popupData && <button type='button' className="fw-bold advButtonDisabled mx-2" id="bookbtn" disabled>Checkout </button>}


                {/* {nights && !user &&<button  type='button' className="fw-bold advButton mx-3 hi" id="bookbtn">Checkout </button>} */}
                { !hideCheckout && (isNaN(nights)?'':nights) && containerState.stayType!=="container" &&<Link to={`/checkout/stay/${locationId}/${stayid}/${id}/${checkIn}/${checkOut}/${nights}/${quantity}`} ><button type='button' className="fw-bold advButton mx-3" id="bookbtn">Checkout </button></Link>}
                { !hideCheckout && (isNaN(nights)?'':nights) && containerState.stayType==="container" && popupData && <Link to={`/checkout/stay/${locationId}/${stayid}/${id}/${checkIn}/${checkOut}/${nights}/${quantity}/ch/${JSON.stringify(popupData)}`} ><button type='button' className="fw-bold advButton mx-3" id="bookbtn">Checkout</button></Link>}

                {/* { !user && <button className="fw-bold advButton mx-3"  type="button" id="addCartBtn" onClick={()=> setModalOpen(true)}>Add to Cart</button>} */}
                {!nights && user &&<button type='button' className="fw-bold advButtonDisabled s mx-3" id="bookbtn" disabled>Add To Cart </button>}
                { user && (isNaN(nights)?'':nights) && !cartDetails && containerState.stayType!=="container" && <button className="fw-bold advButton mx-3" type="button" id="addCartBtn" onClick={()=> handleAddToCart(containerState.siteDesc,quantity,containerState.siteName,containerState.price,containerState.tentSize,containerState.featuredImg,containerState.stayType,containerState)}>Add to Cart </button>}
                { user && (isNaN(nights)?'':nights) && !cartDetails && containerState.stayType==="container" && popupData &&<button className="fw-bold advButton mx-3" type="button" id="addCartBtn" onClick={()=> handleAddToCart(containerState.siteDesc,quantity,containerState.siteName,containerState.price,containerState.tentSize,containerState.featuredImg,containerState.stayType,containerState)}>Add to Cart </button>}
                {!user && <button type='button'  className="fw-bold advButton mx-3" data-toggle="modal" data-target="#loginSignupModal" >Add to Cart</button>}
                { cartDetails && user &&  <Link to={`/cart`}> <button className="fw-bold advButton mx-3" type="button" id="addCartBtn">Go to Cart</button> </Link> }
              </div>
          </div>}
          </>
           
        </div>)}
            {/* mobile and tab view down */}
        {  containerState && (
          <div>
              <>
                <ProductPopup doc={containerState} addPopupData={addPopupData}/>
                {<div className="innerSectionServiceCost mobile_show px-3 py-2">
                  <div className="row">
                    <div className="col-6">
                      <div className="costSection1">
                          <div className='text-left ' >
                            {!isNaN(nights) && containerState.stayType !=="container" && <h5 className='mb-0 fw-bold'>₹{(containerState.price*nights)*quantity} </h5>}
                            { containerState.stayType ==="container" && <h5 className='mb-0 fw-bold'>₹{popupData?containerPrice:(containerState.saturdayPrice)} </h5>}
                              <input type="hidden" id="myPrice" value={(containerState.price*(isNaN(nights)?0:nights))*quantity} />
                          </div>
                          <div className='text-right'>
                              {!isNaN(nights) && containerState.stayType !=="container" && <div className=""><small>{nights} Nights</small></div>}
                              {containerState.stayType ==="container" && <div className=""><small>Minimum Nights: 1</small></div>}
                              { containerState.stayType !=="container" && <small><strong>{(containerState.price)}/Night</strong></small>}
                          </div>
                      </div>
                          { containerState.stayType ==="container" && popupData &&
                            <div>
                                  <small>
                                    <span>{popupData.adults} Adult(s)</span>
                                    <span>, {popupData.children} Children</span>
                                  </small>
                            </div>
                          }
                    </div>
                    <div className="col-6">
                      { containerState.stayType!=="container" && <div className="">
                        <p className='mb-0 text-left d-none'>Total Guest </p>
                        <div className="qty text-end text-dark">
                            <button  onClick={()=>handleDecrement(quantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-2">-</button> 
                            <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={quantity} /> 
                            <button onClick={()=>handleIncrement(quantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-2">+</button>
                        </div>
                      </div>}
                      { containerState.stayType==="container" && <div className="row" data-target="#productModal" data-toggle="modal">
                            <div className='d-inline-flex'>
                              <p type='button'  className="fw-bold mx-2 text-start mb-0" >Guests</p>
                              <FontAwesomeIcon style={{'fontSize':'22px'}} icon="fa-angle-down fa-angle-down" />
                            </div>
                            <div className='mx-2'>
                                {popupData && 
                                  <small>
                                    <span>{popupData.rooms} Room(s), </span> 
                                    <span> {popupData.totalMatress >=0?popupData.totalMatress:0} Matress(s)</span>
                                  </small>
                                }
                            </div>
                      </div>}
                    </div>
                  </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="checkDiv position-relative">
                            <p className='mb-0 text-left'>Check In</p>
                            <DatePicker  onFocus={(e) => e.target.readOnly = true} selected={checkIn} onChange={(date)=> handleCheckIn(date)} dateFormat="dd/MM/yyyy"  minDate={new Date()}   />  
                          
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="checkDiv position-relative">
                            <p className='mb-0 text-left'>Check Out</p>
                            <DatePicker onFocus={(e) => e.target.readOnly = true} selected={checkOut} onChange={(date)=> handleCheckOut(date)} dateFormat="dd/MM/yyyy" minDate={minCheckOutDate} maxDate={addDays(new Date(), 90)}  />  
                          
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                          <div className="">
                            <div className={hideCheckout? "onlyCartButton mt-2 flex-row": "buttonSection mt-2 flex-row"}>
                              { !hideCheckout && !nights && user && containerState.stayType!=="container" &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}
                                { !hideCheckout && !nights && !user  && containerState.stayType!=="container" &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}
                                { !hideCheckout && !nights && !user  && containerState.stayType==="container" &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}
                                { !hideCheckout && !nights && user  && containerState.stayType==="container" &&<button type='button' className="fw-bold advButtonDisabled " id="bookbtn" disabled>Checkout </button>}

                                {/* {nights && !user &&<button  type='button' className="fw-bold advButton mx-3 hi" id="bookbtn">Checkout </button>} */}
                                { !hideCheckout && (isNaN(nights)?'':nights) && <Link to={`/checkout/stay/${locationId}/${stayid}/${id}/${checkIn}/${checkOut}/${nights}/${quantity}`} ><button type='button' className="fw-bold advButton " id="bookbtn">Checkout </button></Link>}

                                {/* { !user && <button className="fw-bold advButton "  type="button" id="addCartBtn" onClick={()=> setModalOpen(true)}>Add to Cart</button>} */}
                                {!user && <button type='button'  className="fw-bold advButton mx-3" data-toggle="modal" data-target="#loginSignupModal" >Add to Cart</button>} 
                                {!nights && user &&<button type='button' className="fw-bold advButtonDisabled s " id="bookbtn" disabled>Add To Cart </button>}
                                { user && (isNaN(nights)?'':nights) && !cartDetails && containerState.stayType!=="container" && <button className="fw-bold advButton " type="button" id="addCartBtn" onClick={()=> handleAddToCart(containerState.siteDesc,quantity,containerState.siteName,containerState.price,containerState.tentSize,containerState.featuredImg,containerState.stayType,containerState)}>Add to Cart</button>}
                                { user && (isNaN(nights)?'':nights) && !cartDetails && containerState.stayType==="container" && <button className="fw-bold advButton " type="button" id="addCartBtn" onClick={()=> handleAddToCart(containerState.siteDesc,quantity,containerState.siteName,containerState.price,containerState.tentSize,containerState.featuredImg,containerState.stayType,containerState)}>Add to Cart</button>}

                                { cartDetails && user &&  <Link to={`/cart`}> <button className="fw-bold advButton" type="button" id="addCartBtn">Go to Cart</button> </Link> }
                            </div>
                        </div>
                      </div>
                    </div>

                </div>}
              </> 
          </div>
        )}
    </div>
    <div className="row">
      <div className="col-sm-12 reviewSection  ">
          <h2 className='text-left text-bold-6'>Review</h2>
          {user &&<button type='button'  className="fw-bold advButton mx-3" data-toggle="modal" data-target="#exampleModalCenter">Add Review</button>}
      </div>
    </div>
   {user &&  <Popup productId={id} userId={user.uid}/>}
    {reviews && reviews.length!==0 &&  <div className='row'>
              <div className=" col-sm-12 reviewSection  mt-0">
                <div className='row'>
                   <div className="col-sm-12">
                <div className="mainDiv2 text-center">
                    <Slider {...reviewCards}>
                    {reviews.map((doc)=>(<div className = "cardcarousel1Cards">
                      <div className='reviewCard'>
                        <div className='profileReview'>
                          <div className='profileDes'>
                            <label className='mg-t-10'>{doc.name}</label>
                            <p >
                            {[...Array(doc.stars)].map((i) =>
                              <span className="reviewStar" key={i}>&#9733;</span>

                            )}
                            </p>
                          </div>
                        </div>
                        {/* <h6 className='fontbold'>{doc.desc}</h6> */}
                        <p className='justify text-left reviewDesc' style={{"fontSize":"14px"}}>{
                          doc.desc
                        }
                         </p>
                      </div>
                    </div>))}
                    
                    </Slider>
                </div>
            </div>
                </div>
              </div>
          </div>}
  </div>}
      <Footer/>
  </div>
  )
}


export default ProductDetails

