import React from 'react';
import { useParams } from 'react-router-dom';
import './searchPage.css';
import "../Home/Banner.css";
import mglass from "../assets/search.png";
function SearchPage() {
const handleKeyPress = (event) => {
  if(event.key === 'Enter'){
     window.location.href = '/search/'+event.target.value
  }
}
const {search_text}=useParams();
  return (
    <div className="container search-container">
      <div className='mg-t-30'>
      <div className="position-relative rounded  d-flex justify-content-center searchBar mg-t-20">
                    <input autoComplete='off' type="search" style={{paddingLeft:'3em'}} className="form-control" id="search" onKeyPress={handleKeyPress} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                    <img className="position-absolute" src={mglass} alt="" />
      </div>
        <h2 className='mg-t-20 text-left'>{search_text}</h2>
      </div>
    </div>
  )
}

export default SearchPage