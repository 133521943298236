import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
import Home from '../Home/Home'
import { useAuthContext } from '../hooks/useAuthContext'
import { useHistory } from 'react-router-dom'
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons'

 

export const useLogin = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()
  const history=useHistory();
  const user = useAuthContext();

  const login = async (email, password) => {
    setError(null)
    setIsPending(true)
    
    try {
      // login
      const res = await projectAuth.signInWithEmailAndPassword(email, password)
      .then(res=>{
       // dispatch login action
        dispatch({ type: 'LOGIN', payload: res.user })
        setIsPending(false);     
        if(window.location.href.split("/").pop()==="login")
        {
          alert('Logged In successfully!')
          history.push("/home");
        }
        else{
          alert('Logged In successfully!')

        }

        if (!isCancelled) {
          setIsPending(false)
          setError(null)
        }
      })
    } 
    catch(err) {
      alert('Please Sign Up!')
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { login, isPending, error }
}