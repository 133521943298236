import React from 'react'
import Banner from './Banner'
import Overview from './Overview'

export default function Home() {
  return (
    <div>
        <Banner/>
        <Overview/>
    </div>
  )
}
