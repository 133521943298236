import React,{ useRef,useEffect,useState,componentDidMount } from 'react'
import { useParams ,useHistory } from "react-router-dom";
import { projectFirestore } from '../firebase/config';
import "./cart.css";
import "./explore.css";
import { useAuthContext } from '../hooks/useAuthContext';
import { useFirestore } from '../hooks/useFirestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignupComponent from '../signup/SignupComponent';
import LoginComponent from '../Login/LoginComponent';
import Footer from '../components/Footer';
import { useCollection } from '../hooks/useCollection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown,faAngleUp } from '@fortawesome/free-solid-svg-icons'
import CouponComp from '../components/CouponComp';
import 'react-notifications/lib/notifications.css';

import DatePicker from "react-datepicker";  
import "react-datepicker/dist/react-datepicker.css"; 
import { isValid , addDays} from 'date-fns';

import {NotificationContainer, NotificationManager} from 'react-notifications';
library.add( faAngleDown,faAngleUp)


const Checkout = () => {
    
    const {locationId,stayid,productId,checkIn,checkOut,nights,guests,userId,popupdata}=useParams();
    const {documents,errors}=useCollection('coupon')
    const { addDocument, response } = useFirestore('orders')
    const {user}=useAuthContext()
    const [checkoutRes,setCheckoutRes]=useState();
    const [error,setError]=useState(null);
    const [productRes,setProductRes]=useState(null);
    const [checkInDate,setcheckInDate]=useState(new Date(checkIn));
    const minCheckOutDate = new Date(checkInDate.getTime());
    minCheckOutDate.setDate(checkInDate.getDate() + 1);
    const [checkOutDate,setcheckOutDate]=useState(new Date(checkOut));
    const [nightss,setnights]=useState(nights);
    const [checkOutQuantity,setcheckOutQuantity]=useState(guests);
    const [showLoginModal,setShowLoginModal]=useState(false)
    const [price,setPrice]=useState()
    const history=useHistory();   
    const [loginC , setLoginC] = useState(false);
    const [couponVal,setCouponVal]=useState('');
    const [changer,setChanger]=useState();
    const [couponPrice,setCouponPrice]=useState(0);
    const [counter,setCounter]=useState(0)
    const [couponError,setCouponError]=useState(false);
    const [xDay,setXday]=useState(null);
    const [containerData,setContainerData]=useState(null);
    const [allCoupons,setAllCoupons]=useState(false);
    const [weekdayCount,setWeekdayCount]=useState(0);
    const [fridayCount,setFridayCount]=useState(0);
    const [saturdayCount,setSaturdayCount]=useState(0);
    const [sundayCount,setSundayCount]=useState(0);
    let weekday=0;
    let friday=0;
    let saturday=0;
    let sunday=0;
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let count;
    const [parsedPopupdata,setParsedPopupdata]=useState(null)
    const [totalDaysCount,setTotalDaysCount]=useState(0);
    const [totalAmount,setTotalAmount]=useState(0);
    const [totalRoomsRent,setTotalRoomsRent]=useState(0);
    const [totalMattressRent,setTotalMatressRent]=useState(0);
    const [totalPayable,setTotalPayable]=useState(0);

    // //console.log("user",user)
   
if(user){
    let num=user.phoneNumber;
    let str=num.toString();
    // //console.log("n",str)
   let phoneNum=parseInt(str.slice(1));
   
   
    var options = {
        "key": "rzp_test_6gcvEmraja9tMY", // Enter the Key ID generated from the Dashboard
        "amount":(totalPayable-couponPrice)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Adventrio Escapes",
        "description": "Test Transaction",
        "image": "https://example.com/your_logo",
        // "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
            // alert(response.razorpay_payment_id);
            if(parsedPopupdata){
                addDocument({
                    email:user.email,
                    name:user.displayName,
                    paymentId:response.razorpay_payment_id,
                    productId:productId,
                    uid:user.uid,
                    numberOfNights:nights,
                    checkInDate:checkInDate,
                    checkOutDate:checkOutDate,
                    totalPeople:checkOutQuantity,
                    location:locationId,
                    stayCategory:stayid,
                    price:totalPayable-couponPrice,
                    containerDetails:parsedPopupdata,
    
                  })

            }else{
                addDocument({
                    email:user.email,
                    name:user.displayName,
                    paymentId:response.razorpay_payment_id,
                    productId:productId,
                    uid:user.uid,
                    numberOfNights:nights,
                    checkInDate:checkInDate,
                    checkOutDate:checkOutDate,
                    totalPeople:checkOutQuantity,
                    location:locationId,
                    stayCategory:stayid,
                    price:totalPayable-couponPrice,
                  })
                
            }
            
             
              const text = [
                "name: "+user.displayName,
                "paymentId: "+response.razorpay_payment_id,
               "productId: "+productId,
                "uid: "+user.uid,
                "numberOfNights "+nights,
                "checkInDate: "+checkInDate,
                "checkOutDate: "+checkOutDate,
                "totalPeople: "+checkOutQuantity,
                "location: "+locationId,
                "stayCategory: "+stayid,
                "price: "+(totalPayable-couponPrice),
              ].join('\n')
              let recipient = phoneNum;
    
              let whatsAppMsg="Thank you "+user.displayName+", your order has been placed successfully. Our executive will get back to you shortly. %0D%0AHere are your order details. "+text+". Thanks and Regards."
            fetch(`https://www.uat-calibrill.com/send-whatsapp?recipient=${recipient}&textmessage=${whatsAppMsg}`).then(res=>{
                if(!res.ok){
                  throw new Error('Oops! Unable to proccess your request.');
                }
                 NotificationManager.success('Order Placed Successfully!', 'Success');
                 history.push("/order-confirmed");
              }).catch(err=>{
                NotificationManager.error('Oops! Unable to proccess your request.', 'Error');
              });;

            
            //   window.location.href = '/';
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature)

           
        },
        "prefill": {
            "name": user.displayName  ,
            "email": "admin@adventrio.com",
            "contact": user.phoneNumber
        },
        "notes": {
            "address": "Razorpay Corporate Office"
        },
        "theme": {
            "color": "#3399cc"
        }
      };
      var rzp1 = new window.Razorpay(options);
          rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
      });
    }
    useEffect(() => {
        if(containerData){
        // //console.log(containerData,"data")
        if(containerData.stayType==='container'){
            setTotalAmount((((containerData.weekDayPrice*weekdayCount)+(containerData.fridayPrice*fridayCount)+(containerData.saturdayPrice*saturdayCount)+(containerData.sundayPrice*sundayCount))*parsedPopupdata.rooms+(parsedPopupdata.childrenMatress*containerData.matressChildrenPrice)*totalDaysCount+(parsedPopupdata.adultMatress*containerData.matressAdultPrice)*totalDaysCount));
            setTotalRoomsRent(((containerData.weekDayPrice*weekdayCount)+(containerData.fridayPrice*fridayCount)+(containerData.saturdayPrice*saturdayCount)+(containerData.sundayPrice*sundayCount))*parsedPopupdata.rooms)
            setTotalMatressRent((parsedPopupdata.childrenMatress*containerData.matressChildrenPrice)*totalDaysCount+(parsedPopupdata.adultMatress*containerData.matressAdultPrice)*totalDaysCount)
            setTotalPayable((((containerData.weekDayPrice*weekdayCount)+(containerData.fridayPrice*fridayCount)+(containerData.saturdayPrice*saturdayCount)+(containerData.sundayPrice*sundayCount))*parsedPopupdata.rooms+(parsedPopupdata.childrenMatress*containerData.matressChildrenPrice)*totalDaysCount+(parsedPopupdata.adultMatress*containerData.matressAdultPrice)*totalDaysCount)+802)

        }else{
            setTotalAmount((containerData.price*nightss)*checkOutQuantity);
            setTotalPayable((containerData.price*nightss)*checkOutQuantity+802)
        }
    }
    }, [containerData, weekdayCount, fridayCount, saturdayCount, sundayCount, totalDaysCount, parsedPopupdata ,checkOutQuantity, nightss])
   
    useEffect(() => {
        // //console.log('use 1st')
        let ref=projectFirestore.collection('orders');
        const  unsubscribe=ref.onSnapshot((snapshot)=>{
            let results=[]
        
                let ref1=projectFirestore.collection('location').doc(locationId).collection('stay').doc(stayid).collection(stayid);
                const  unsubscribe2=ref1.onSnapshot((snapshot)=>{
                    let results=[];
                    snapshot.docs.forEach((doc)=>{
                        if(productId===doc.id){
                            results.push({...doc.data(),id:doc.id})
                            setContainerData({...doc.data(),id:doc.id})
                    }
                        
                    })
                    //update State   
                    // //console.log('res',containerData)
                        setProductRes(results);
                        setError(null);
                
                })  

        },(error)=>{
            setError('could not fetch data')
        })
        
    }, [userId,stayid,locationId])


    const diffDays = (date, otherDate) => {
        return Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24))
    };

    

const handleNightsChange= ()=>{
     setnights(diffDays(checkInDate,checkOutDate));  
}
    const handleCheckIn=(date)=>{
        setcheckInDate(date)
        handleNightsChange();

    }

   const handleCheckOut=(date)=>{
    setcheckOutDate(date)
    handleNightsChange();

    }

    const handleDecrement=(checkOutQuantity)=>{
        let newQuantity;
        if(checkOutQuantity===1){
            newQuantity=Number(checkOutQuantity)-1;
        }
        else{
            newQuantity=Number(checkOutQuantity)-1;
            setcheckOutQuantity(newQuantity);
    
        }
        
     }
     const handleIncrement=(checkOutQuantity)=>{
        let newQuantity;
        // //console.log('hey checking the type of ',newQuantity)
        newQuantity=Number(checkOutQuantity)+1;
        // //console.log('hey checking the type of post',newQuantity)
        setcheckOutQuantity(newQuantity)
    }
    const handlePrice=()=>{
        setPrice(document.querySelector('.myInput').value)    
     }
     useEffect(() => {
        // //console.log('use 2nd')
        if(price>0){
        rzp1.open();
        
    }
        
     }, [price])

 const handleCheckoutProcess=(e)=>{
    e.preventDefault();
    // handlePrice();
    if(totalPayable>0){
        rzp1.open();   
    }
 }
const handleClose=()=>{
    setShowLoginModal(false)
}
const handleLoginComp = ()=>{
    setLoginC(true)
    // //console.log('set to the true ting')
}
 
const notLoggedIn=(e)=>{
    NotificationManager.warning('Please Login First!', 'Warning');
}

const handleCoupon=(e)=>{
    count=0;
    e.preventDefault();
    setCouponError(false);
    setCounter(0)
    // //console.log("initial",counter)
    documents.forEach(ele => {
        if(ele.couponName===couponVal){
            count=count+1;
            setCouponError(false);
            setCounter(1);
            setCouponPrice(ele.couponPrice)    
           
        }
   }
   );
   if(count==0){
    setCouponPrice(0)
    setCouponError(true)
    // //console.log("Invalid");
   }

}

const handleCouponOfList=(couponName)=>{
    setCouponVal(couponName);
    setChanger(couponVal)
    setAllCoupons(false) // to close the coupon list box after applying the coupon
}
useEffect(() => {
    // //console.log('use 3rd')
    count=0;
    setCouponError(false);
    setCounter(0)
    // //console.log("initial",counter)
   
   if(documents){ documents.forEach(ele => {
        if(ele.couponName===couponVal){
            count=count+1;
            setCouponError(false);
            setCounter(1);
            setCouponPrice(ele.couponPrice)    
        }
   }

   );}

   if(count==0){
    setCouponPrice(0)
    setCouponError(true)
    // //console.log("Invalid");
   }
}, [changer])
const handleApplyCoupon=(couponName)=>{
    setCouponVal(couponName);
    handleCouponOfList();
}
const handleCouponChange=(e)=>{
    setCouponVal(e.target.value)

}
const handleAllCouponToggle=()=>{
    if(allCoupons){
        setAllCoupons(false)
    }
    else{
        setAllCoupons(true)
    }
}
useEffect(() => {
        handleNightsChange()  
    }, [checkInDate,checkOutDate]);
useEffect(()=>{
   setParsedPopupdata(JSON.parse(popupdata!==undefined? popupdata: null)); 
   setXday(new Date().toLocaleString('en-US', { weekday: 'long' }));
   let startDate=new Date(checkInDate);
   let endDate=new Date(checkOutDate);
   while(startDate < endDate){

    var dayName = days[new Date(startDate).getDay()];

    if(dayName==="Friday"){
      
        friday=friday+1;
    }
    else if(dayName ==="Saturday"){
    
    saturday=saturday+1;
    }
    else if(dayName ==="Sunday"){
        
        sunday=sunday+1;
      }
      else{
        weekday=weekday+1;
      }

    startDate = new Date(startDate).setDate(new Date(startDate).getDate() + 1); 
  }
    setWeekdayCount(weekday);
    setFridayCount(friday);
    setSaturdayCount(saturday);
    setSundayCount(sunday);
    setTotalDaysCount(weekday+friday+saturday+sunday)
    // //console.log(weekday)
   },[checkInDate,checkOutDate])
    return ( 
    <>
    <NotificationContainer/>
    {!showLoginModal && <div className="checkOutParent container">
        <div className="checkOutPg pb-2 justify-content-center align-items-start">
            <div className="searchDiv">
                <div className="">
                    <h1 className="text-dark text-center fw-bold display-6">Checkout</h1>
                </div>
                <hr className="sectionHr m-auto text-center text-dark "  />
                {/* <h5 className="text-dark text-center fw-bold py-2">Item List</h5> */}
            </div>
        </div>
        
        
         <div className="row p-md-5 checkOutRow ">
             {productRes && <>
             {productRes.map(doc=>(
            <div className="col-xxl-7 col-xl-7 col-lg-7 col-md-12 checkoutImg" key={doc.id} >
                <div className="card mb-3 stayFeaturedImg" style={{'height':'auto'}}>
                    <div className="row">
                        <div className="col-md-4 h-300px">
                            <img src={doc.featuredImg} className="stayFeaturedImg img-fluid rounded-start" alt="..."/>
                        </div>
                        <div className="col-md-8 col-sm-10 align-self-center">
                            <div className="card-body">
                                <h5 className="card-title"><strong>{doc.siteName}</strong></h5>
                                {<p className="card-text text-start">{doc.siteDesc.substr(0, 250)}....</p>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* login / phone otp form */}

                {/* {!user && <LoginModal handleClose={handleClose}/>} */}
                {!user && !loginC && 
                    <p className='text-center pt-3 fw-bold' onClick={()=>setLoginC(true)} style={{'cursor':'pointer'}}>
                        <div>
                            <small className='fw-normal'>Have An Account</small>
                            <p>Login</p>
                        </div>
                    </p>
                }
                {!user && loginC && 
                    <p className='text-center pt-3 fw-bold' onClick={()=>setLoginC(false)} style={{'cursor':'pointer'}}>
                        <div>
                            <small className='fw-normal'>Don't Have An Account</small>
                            <p>Sign Up</p>
                        </div>
                    </p>
                }
                {!user && !loginC && <SignupComponent className="checkoutLoginSignup"/>}
                {!user && loginC &&  <LoginComponent className="checkoutLoginSignup"/>}
                {/* login / phone otp form ends */}
            </div>))}  
            </>}  
            
            {productRes && <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 mb-md-5">
                {productRes.map(doc=>(<div className="row">
                    <div className="card" key={doc.id}>
                        <div className="card-body">
                            <div className="row pb-3">
                                <div className="col-sm-12 ">
                                    {containerData && containerData.stayType!=="container"  &&
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <strong>Total Guest</strong>
                                            </div>
                                            <div className="col-sm-9 qty text-end total_guest">
                                                <button  onClick={()=>handleDecrement(checkOutQuantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">-</button> 
                                                <input className="w-25 rounded-20 outline-0 text-center" type="number" name="" id="" value={checkOutQuantity} /> 
                                                <button onClick={()=>handleIncrement(checkOutQuantity)} className="rounded-circle border-0 bg-yellow w-10 h-10 mx-3">+</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="selectedDate pb-3 d-flex flex-row justify-content-between">
                                <div className="checkInDates position-relative">
                                    <small><strong>Check In</strong></small>
                                    <div>
                                        {/* <input className='dateInput' type="date" name="" id="checkedInDate" title="2022" onChange={handleCheckIn} value={checkInDate} /> */}
                                        <DatePicker selected={checkInDate} onFocus={e => e.preventDefault()} onChange={(date)=> handleCheckIn(date)} dateFormat="dd/MM/yyyy"  minDate={new Date()}    />  
                                    </div>
                                </div>
                                <div className="checkOutDates position-relative">
                                    <small><strong>Check Out</strong></small>
                                    <div>
                                        {/* <input className='dateInput' type="date" name="" id="checkedOutDate" title="2022" onChange={handleCheckOut}  value={checkOutDate} /> */}
                                        <DatePicker  selected={checkOutDate} onFocus={(e) => e.target.readOnly = true} onChange={(date)=> handleCheckOut(date)} dateFormat="dd/MM/yyyy"  minDate={minCheckOutDate}  maxDate={addDays(new Date(), 90)}     />  
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="priceSection">
                                    <div className="costDetails  ">
                                        {containerData && containerData.stayType !== "container" &&<div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Total Amount</div>
                                            
                                            <div>{totalAmount}</div>
                                            {/* <div>{(doc.price*nightss)*quantity}</div> */}
                                            
                                          
                                        </div>}
                                        {containerData && containerData.stayType === "container" &&<div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Total Amount</div>
                                            {/* <div>{(doc.price*nightss)*checkOutQuantity}</div> */}
                                            <div>{totalAmount}</div>
                                            {/* <div>{(doc.price*nightss)*quantity}</div> */}
                                            
                                          
                                        </div>}
                                        {containerData && containerData.stayType !== "container" &&<div className="pb-2 inDetailed ">
                                            <small className="d-flex flex-row justify-content-between">
                                                <div>{doc.price} x {checkOutQuantity} unit(s) x {nightss} night(s)</div>
                                                <div>{totalAmount}</div>
                                            </small>
                                        </div>}
                                        {containerData && containerData.stayType === "container" &&parsedPopupdata &&<div className="pb-2 inDetailed ">
                                            <small className="d-flex flex-row justify-content-between">
                                                <div>{parsedPopupdata.rooms} room(s) x {nightss} night(s)</div>
                                                <div> {totalRoomsRent}</div>
                                            </small>
                                        </div>}
                                        {containerData && containerData.stayType === "container" &&parsedPopupdata &&<div className="pb-2 inDetailed ">
                                            <small className="d-flex flex-row justify-content-between">
                                                <div>{parsedPopupdata.adultMatress} Adult Mattress x {parsedPopupdata.childrenMatress} Children Mattress</div>
                                                <div>{totalMattressRent}</div>
                                            </small>
                                        </div>}
                                        <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Other Taxes</div>
                                            <div>802</div>
                                        </div>
                                        {couponPrice>0 && <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div>Coupon</div>
                                            <div className='text-success'>-{couponPrice}</div>
                                        </div>}
                                        {containerData && containerData.stayType !== "container" && <div className="pb-2 d-flex flex-row justify-content-between">
                                            <div><strong>Total Payable</strong></div>
                                            <div><strong>{totalPayable-couponPrice}</strong></div>
                                        </div>}
                                        {containerData && containerData.stayType === "container" && parsedPopupdata &&<div className="pb-2 d-flex flex-row justify-content-between">
                                            <div><strong>Total Payable</strong></div>
                                            <div><strong>{totalPayable-couponPrice}</strong></div>
                                        </div>}
                                    </div>
                                </div>
                                <hr />
                                {/* <div className="promocode pb-3">
                                    <div>Have a <strong>Promo Code</strong>/ <strong>Referral Code</strong>?</div>
                                    <div className="position-relative">
                                        <form onSubmit={handleCoupon}>
                                            <input type="text" value={couponVal} className="w-100 rounded p-2" onChange={(e)=>handleCouponChange(e)}/>
                                            <button className="position-absolute  submitPromo" >Submit</button>
                                        </form>
                                        {couponError && couponVal.length>0 && <small className="text-danger text-left">Oops,Invalid Coupon!</small>}
                                        {!couponError && couponPrice>0 && <small className="text-success text-left">Coupon Applied!</small>}
                                        <div className="row mt-2 justify-content-center">
                                            <div className="col-sm-12">
                                                {!allCoupons && <button className="text-dark text-right w-100 bg-transparent border-0" onClick={handleAllCouponToggle} >View Coupons <FontAwesomeIcon icon="angle-down" /></button>}
                                                {allCoupons && <button className="text-dark text-right w-100 bg-transparent border-0" onClick={handleAllCouponToggle}>Hide Coupons <FontAwesomeIcon icon="angle-up" /></button>}
                                            </div>

                                            {allCoupons  && (
                                            <div className='row position-relative justify-content-center'>
                                                <div className="col-sm-12 p-1 all_promocodes_list shadow rounded-20 position-absolute">
                                                    {documents.map(doc=>(
                                                            <div key={doc.id} className="">
                                                                {!doc.promotional ?(
                                                                <div className='d-flex justify-content-between align-items-center border-1 py-2  single_promoCode rounded-20'>
                                                                    <p className="text-left mb-0 w-50">{doc.couponName}</p>
                                                                    <button className='submitPromo mt-0' onClick={()=>handleCouponOfList(doc.couponName)}>Apply Coupon</button>
                                                                </div>):null}
                                                            </div>
                                                    ))}
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                   
                                </div> */}
                                <CouponComp handleCouponOfList={handleCouponOfList} handleCoupon={handleCoupon}  handleCouponChange={handleCouponChange} couponVal={couponVal} couponError={couponError} couponPrice={couponPrice} handleAllCouponToggle={handleAllCouponToggle} allCoupons={allCoupons}/>
                               
                                
                                <div className="row pb-3">
                                    <div className="col-md-12 text-center">
                                        {!user && <button className="fw-bold w-100 advButton" onClick={()=>notLoggedIn()}>Book Now</button>}
                                        
                                        {user && <form onSubmit={handleCheckoutProcess}>
                                        {/* <input type="hidden" className="myInput"  value={containerData.stayType==="container"? (((doc.weekDayPrice*weekdayCount)+(doc.fridayPrice*fridayCount)+(doc.saturdayPrice*saturdayCount)+(doc.sundayPrice*sundayCount))*parsedPopupdata.rooms+(parsedPopupdata.childrenMatress*doc.matressChildrenPrice)*totalDaysCount+(parsedPopupdata.adultMatress*doc.matressAdultPrice)*totalDaysCount)+802-couponPrice:((doc.price*nightss)*checkOutQuantity)+802-couponPrice}/> */}
                                        <button  className="fw-bold w-100 advButton">Book Now</button>
                                        </form>}
                                    </div>

                                </div>

                                <div className="promocode pb-3">
                                    <div><strong>Cancellation policy</strong></div>
                                    <p className="text-start">
                                        <small>If you cancel at least 1 week before your check in day and time, 
                                    you’ll receive a full refund (minus service fees). If you cancel within the 1 week before check in, there will be no refund.</small>
                                    </p>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>))}
            </div>}
            
        </div>
        </div>}
        <Footer/>
    </>

     );
}

 
export default Checkout;